import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import animated from './util/animated'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueScrollTo from 'vue-scrollto'
import vSelect from 'vue-select'
import VueClipboard from 'vue-clipboard2'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/style.scss'
import Socket from '@/ws/socket.js'

import message from '@/mixins/message'
import i18n from './lang'
import "vue-select/src/scss/vue-select.scss";
import './plugins/axios'
import './assets/css/animate.css'
import './permission'
import {getAgentIdentify, getConfig} from './api'
import utils from '@/utils/misc.js'
// import 'font-awesome/css/font-awesome.min.css';

Vue.component('v-select', vSelect)
Vue.use(VueScrollTo, { debounce: 600 });
Vue.use(animated,VueAwesomeSwiper)
Vue.use(ElementUI)
Vue.use(VueClipboard)
Vue.prototype.$ws = new Socket();
// swiper.use([Navigation, Pagination, Autoplay])

// axios.defaults.baseURL=process.env.VUE_APP_URL;
Vue.use('axios')
Vue.config.productionTip = false
Vue.mixin(message)
var app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
})
const initConfig = () => {
  getConfig().then(res => {
    window.configObj = res
    store.state.configs = res
    window.configObj.officialModel = '1' // 樣式 測試用
    document.title = res.appName
    var link = document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = `${window.configObj.logoUri}`
    document.getElementsByTagName('head')[0].appendChild(link)
    const lang = utils.getLs("locale") || res.defaultLang
    i18n.locale = lang
    app.$mount('#app')
    router.push({query: {...router.app.$route.query, id: undefined}})
  })

}
const id = utils.getLs('agentIdentify')
if (id) {
  initConfig()
} else {
  const queryObj = utils.getQuery()
  if (queryObj.id) {
    utils.setLs('agentIdentify', queryObj.id)
    initConfig()
  } else {
    getAgentIdentify().then(res => {
      utils.setLs('agentIdentify', res.agentIdentify)
      initConfig()
    })
  }
}
