 <template>
  <div class="table">
    <div v-if="tableDatas.tableHead.length > 0" class="table-head">
      <div
        v-for="(h, hi) in tableDatas.tableHead"
        :key="hi"
        class="table-head-item"
      >
        <span>{{ h }}</span>
      </div>
    </div>
    <div class="table-body">
      <div class="table-row" v-for="(t, i) in tableDatas.tableData" :key="i">
        <div class="table-col text-left">
          <img
            v-if="t.icon"
            :src="t.icon==='usdt'?'':require('../assets/img/icons/'+t.icon+'.png')"
            alt="icon"
            width="25"
            height="25"
            class="coin-icon"
          />
          <img
            v-if="t.logo"
            :src="t.logo"
            alt="icon"
            width="25"
            height="25"
            class="coin-icon"
          />
          {{ t.name }}
          <img
            v-if="t.fire"
            src="../assets/img/icons/fire.png"
            alt=""
            class="fire-icon"
          />
        </div>
        <div class="table-col gr">{{ t.price }}</div>
        <template>
          <div
            v-if="!isNaN(t.time)"
            class="table-col"
            :class="[t.time > 0 ? 'gr' : 'red']"
          >
            {{ t.time }}
          </div>
          <div v-else class="table-col">
            {{ t.time }}
          </div>
        </template>
        <div v-if="t.switch" class="table-col">
          <img
            v-if="t.switch == 1"
            src="../assets/img/icons/sw_1.png"
            alt="icon"
            width="30"
            height="30"
            class="swich-icon"
          />
          <img
            v-if="t.switch == 2"
            src="../assets/img/icons/sw_2.png"
            alt="icon"
            width="30"
            height="30"
            class="swich-icon"
          />
        </div>
        <div v-if="t.timeOk" class="table-col gr">{{ t.timeOk }}</div>
        <div v-if="t.btnText" class="table-col">
          <div v-if="t.btnType === 'financialBuy'" class="btn" @click="financialBuy(t)">{{ t.btnText }}</div>
          <div v-else class="btn" @click="goDownload">{{ t.btnText }}</div>
        </div>
        <div v-if="t.datImg" class="table-col">
          <img
            v-if="t.datImg == 1"
            src="../assets/img/risedir.png"
            alt="icon"
            width="30"
            height="30"
            class="icon"
          />
          <img
            v-else
            src="../assets/img/downdir.png"
            alt="icon"
            width="30"
            height="30"
            class="icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Deal",
  props: ["tableDatas"],
  data() {
    return {};
  },
  methods: {
    goDownload() {
      return window.location.href = '/h5/#/pages/normal/login/login'
    },
    financialBuy(payload){
        this.$emit('showDialog', payload)
    }
  },
};
</script>

<style lang="scss" scoped>
 .table {
    font-size: 14px;
    padding: 30px 0;
    margin: 20px 0 60px;
    .table-head {
      display: flex;
      justify-content: space-between;
      color: #909090;
      padding: 10px;
      .table-head-item {
        text-align: center;
        flex: 1 1 20%;
      }
    }
    .table-body {
      // display: inline-flex;
      width: 100%;
      text-align: center;
      min-height: 400px;
      .table-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        // flex-wrap: wrap;
        .table-col {
          flex: 1 1 20%;
          padding: 16px 10px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          .icon {
            margin-right: 10px;
          }
          .swich-icon {
              width: 40px;
              height: 20px;
          }
          .coin-icon {
              width: 40px;
              height: 40px;
              margin-right: 5px;
          }
          .fire-icon {
              width: 20px;
              height: 20px;
              margin-left: 5px;
          }
          .btn {
              cursor: pointer;
              background: $primaryColor;
              color: #fff;
              border-radius: 50px;
              padding: 10px 20px;
          }
          .gr {
              color: $primaryColor;
          }
          &.text-left {
            justify-content: left;
          }
        }
      }
    }
  }
  @media (max-width: 1200px) {
  .table {
      font-size: 14px;
      overflow-x: scroll;
      .table-head {
          padding: 10px 0;
        .table-head-item {
            // flex: 2 0 30%;
            // white-space:nowrap;
            // width: 100%;
            padding: 0 10px;
            min-width: 113px;
        }
      }
      .table-body {
          min-height: unset;
        .table-row {
          .table-col {
                // white-space:nowrap;
                width: 100%;
                min-width: 122px;
                padding: 10px 5px;
            .coin-icon {
                width: 22px;
                height: 22px;
            }
            &.text-left {
                text-align: left;
            }
            .btn {
                min-width: 80px;
                font-size: 14px;
                padding: 5px;
            }
          }
        }
      }
    }
  }
</style>