export default {
	20001: 'REC_SPOT_TICKERS', // 20001-服务端推送现货行情数据
	20002: 'REC_SPOT_KLINE', // 20002-服务端推送现货K线数据
	20003: 'REC_FUTURE_KLINE', // 20003-服务端推送期货K线数据
	20006: 'REC_FUTURE_TICKERS', // 20001-服务端推送期货行情数据
	20010: 'REC_DEPTH_INFO', // 20009-服务端推送20档挂单数据(期货)
	20007: 'REC_DEPTH_INFO', // 20007-服务端推送20档挂单数据
	10002: 'REC_SPOT_KLINE', // 20002-服务端推送现货K线数据
	10003: 'REC_FUTURE_KLINE', // 20003-服务端推送期货K线数据
	10004: 'REC_SPOT_REALTIME', // 20004-服务端推送现货分时数据
	10008: 'REC_FUTURE_REALTIME', // 20008-服务端推送期货分时数据
	20004: 'REC_SPOT_REALTIME', // 20004-服务端推送现货分时数据
	20008: 'REC_FUTURE_REALTIME', // 20008-服务端推送期货分时数据
	10013: 'REC_FOREX_TICKERS', // 订阅外汇行情
	10014: 'REC_FOREX_TICKERS', // 订阅贵金属行情
	10015: 'REC_FOREX_TICKERS', // 订阅大宗商品行情
	10016: 'REC_FOREX_KLINE', // 订阅外汇Kline
	10017: 'REC_FOREX_KLINE', // 订阅贵金属Kline
	10018: 'REC_FOREX_KLINE', // 订阅大宗商品Kline
	20011: 'REC_FOREX_TICKERS', // 推送外汇行情
	20012: 'REC_FOREX_KLINE', // 推送外汇KLINE
}