<template>
  <div class="header-container">
    <div class="header-top">
      <div class="v-container">
        <div class="flex align-center">
          <div class="service flex flex1">
            <div class="logo-box">
              <router-link to="/" exact class="navbar-brand">
              <img :src="logoUrl" :alt="appName" style="height:70px; width:auto;">
              <span class="logn-name"></span>
            </router-link>
              <ul class="service-list" v-if="!showMenu">
                <li>
                  <a :href="serviceUrl" class="item flex align-center" target="_blank">
                    <img src="~@/assets/img/home8/header-icon1.png" alt="" />
                  </a>
                </li>
                <li>
                  <a v-if="!!emailUrl&&emailUrl!=='0'" :href="`mailto:${emailUrl}`" class="item flex align-center">
                  <img src="~@/assets/img/home8/header-icon2.png" alt="" />
                  </a>
                </li>
                <li>
                  <a v-if="showLine" :href="lineAppUrl.link" class="item flex align-center" target="_blank">
                  <img src="~@/assets/img/home8/header-icon3.png" alt="" />
                </a>
                </li>
                <li>
                  <a v-if="showWhatsapp" :href="whatsAppUrl.link" class="item flex align-center" target="_blank">
                  <img src="~@/assets/img/home8/header-icon4.png" alt="" />
                </a>
                </li>
                <li>
                  <a v-if="showTg" :href="tgUrl" class="item flex align-center" target="_blank">
                  <img src="~@/assets/img/home8/header-icon5.png" alt="" />
                </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="lang flex align-center">
            <div class="menu" @click="onMenu"><img src="@/assets/img/home2/down.png"
                style="width: 30px; height: 30px; opacity: 0.5; margin-right: 20px;"></div>
            <div class="service flex flex1" v-if="showMenu">
              <a :href="serviceUrl" class="item flex align-center" target="_blank">
                <img src="@/assets/img/home2/whats.png" alt="">
                <span>{{$t('service')}}</span>
              </a>
              <a v-if="showWhatsapp" :href="whatsAppUrl.link" class="item flex align-center" target="_blank">
                <img src="@/assets/img/home2/service.png" alt="">
                <span>WhatsApp</span>
              </a>
              <a v-if="showLine" :href="lineAppUrl.link" class="item flex align-center" target="_blank">
                <img src="@/assets/img/home2/service-line.png" alt="">
                <span>LINE</span>
              </a>
              <a v-if="showTg" :href="tgUrl" class="item flex align-center" target="_blank">
                <img src="@/assets/img/home2/tg.png" alt="">
                <span>Telegram</span>
              </a>
              <a v-if="!!emailUrl&&emailUrl!=='0'" :href="`mailto:${emailUrl}`" class="item flex align-center"
                target="_blank">
                <img src="@/assets/img/home2/email.png" alt="">
                <span>Email</span>
              </a>
            </div>
            <template v-if="userLogin">
              <div class="login-prop curso mr20" style="font-size: 16px;" @click="$router.push({name: 'Me'})"><span
                  class="el-icon-user" style="vertical-align:middle;"></span></div>
              <span class="login-prop cursor  gradient fs12 mr20" @click="$router.push({name: 'Assets'})">{{ $t(305)
              }}</span>
            </template>
            <template v-else>
              <span class="login-prop cursor  mr20" @click="$router.push({name: 'Login'})">{{ $t(94) }}</span>
              <span class="login-prop cursor gradient mr20" @click="$router.push({name: 'Register'})">{{ $t(95)
              }}</span>
            </template>
            <el-dropdown trigger="click" class="lang-switcher" @command="handleCommand">
              <span class="lang-link flex">
                <!-- <img :src="`${flagImg}`" /> -->
                {{ curLangName }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in langOptions" :key="item.lang" :command="item.lang"
                  class="flex align-center">
                  <img :src="item.flag" alt="" style="width: 24px;height:24px; margin-right: 6px;">
                  {{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <!-- 隐藏标题栏 -->
    <div class="v-container vc">
      <header class="header">
        <nav class="navbar flex align-center navbar-default" :class="{ scrolled: homeHeader }">
          <div class="container p0 flex1" :class="{ 'collapse-pc-scroll': homeHeader }">
            <div class="modle-1">
              <router-link to="/" exact class="navbar-brand">
                <h1 class="logo">
                  <img :src="logoUrl" :alt="appName">
                </h1>
              </router-link>

              <div @click="flag = !flag" class="menu-wrapper visible-xs">
                <div class="hamburger-menu" :class="{ animate: flag }" />
              </div>
            </div>
            <!-- mobile -->
            <div class="collapse navbar-collapse" v-show="flag">
              <ul class="nav" :class="{ 'navbar-nav': homeHeader }">
                <router-link @click.native="flag = !flag" to="/" exact>
                  <li>{{ $t("nav0") }}</li>
                </router-link>
                <router-link @click.native="flag = !flag" to="/spot">
                  <li>{{ $t("nav2") }}</li>
                </router-link>
                <router-link v-if="cofigObj.moduleIsOpenFuture==='1'" @click.native="flag = !flag" to="/futures">
                  <li>{{ $t("nav3") }}</li>
                </router-link>
                <router-link v-if="cofigObj.moduleIsOpenC2c==='1'" @click.native="flag = !flag" to="/c2c-sale">
                  <li>{{ $t("304") }}</li>
                </router-link>
                <router-link v-if="cofigObj.moduleIsOpenPledge==='1'" @click.native="flag = !flag" to="/financial">
                  <li>{{ $t("nav4") }}</li>
                </router-link>
                <a v-if="isBitpie" style="text-align: center;width: 100%; display: block;    color: #fff;"
                  href="https://drive.google.com/file/d/1dFOzOSCDSuZh6UxYGOLcXMJv_yisVjLu/view?usp=sharing"
                  target="_blank">{{ $t(1102) }}</a>
              </ul>
            </div>

          </div>
          <!-- pc -->
          <div class="collapse collapse-pc" :class="{ 'collapse-pc-scroll': homeHeader }">
            <div class="nav items">
              <router-link to="/" exact>
                <div>
                  <p>{{ $t("nav0") }}</p>
                </div>
              </router-link>
              <router-link to="/spot">
                <div>
                  <p>{{ $t("nav2") }}</p>
                </div>
              </router-link>
              <router-link v-if="cofigObj.moduleIsOpenFuture==='1'" to="/futures">
                <div>
                  <p>{{ $t("nav3") }}</p>
                </div>
              </router-link>
              <router-link v-if="cofigObj.moduleIsOpenC2c==='1'" to="/c2c-sale">
                <div>
                  <p>{{ $t("304") }}</p>
                </div>
              </router-link>
              <router-link v-if="cofigObj.moduleIsOpenPledge==='1'" to="/financial">
                <div>
                  <p>{{ $t("nav4") }}</p>
                </div>
              </router-link>
              <a v-if="isBitpie" style="padding: 0 10px;"
                href="https://drive.google.com/file/d/1dFOzOSCDSuZh6UxYGOLcXMJv_yisVjLu/view?usp=sharing"
                target="_blank">{{ $t(1102) }}</a>
            </div>
          </div>
        </nav>
      </header>
    </div>
  </div>
</template>

<script>
import userControl from "@/components/UserControl.vue";
import utils from "@/utils/misc"
import { LANG_OPTIONS } from '@/constants/const'
import i18n from "@/lang";

export default {
  name: "Header",
  props: {
    msg: String,
  },
  data() {
    return {
      flag: false,
      showMenu: false,
      homeHeader: false,
      navloto: false,
      product: false,
      langOptions: LANG_OPTIONS,
      curLang: localStorage.official_locale || window.configObj.defaultLang || 'en-US',
      flagImg: ''

    };
  },
  // components: { userControl },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll);
    // if (window.innerWidth >= 640) {
    //   this.showMenu = false
    // }
    // window.onresize = () => {
    //   if (window.innerWidth >= 640) {
    //     this.showMenu = true
    //   }
    // }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    userLogin() {
      return !!utils.getLs('token')
    },
    curLangName() {
      let result = ''
      for (const item of this.langOptions) {
        if (item.lang === this.curLang) {
          result = item.name
        }
      }
      return result
    },
    showWhatsapp() {
      return window.configObj.isShowWhatsapp === '1'
    },
    showLine() {
      return window.configObj.isShowLine === '1'
    },
    showTg() {
      return window.configObj.telegram && window.configObj.telegram.trim() !== '0'
    },
    whatsAppUrl() {
      return window.configObj.whatsapp.split('\n').map(item => ({
        name: item,
        link: `https://wa.me/${item}`
      }))[0]
    },
    lineAppUrl() {
      return window.configObj.line.split('\n').map(item => ({
        name: item,
        link: item
      }))[0]
    },
    serviceUrl() {
      let arr = []
      try {
        arr = JSON.parse(window.configObj.customerUrl)
      } catch (e) {
        console.log(e)
      }
      let _arr = arr.map(item => {
        if (this.userInfo.uuid) {
          let params = item.params || ''
          params = params.replace(/\{USER_ID\}/g, this.userInfo.uuid)
          params = params.replace(/\{ACCOUNT\}/g, this.userInfo.mobile || this.userInfo.email)
          params = params.replace(/\{NICKNAME\}/g, this.userInfo.nickname)
          item.url += `&${params}`
        }
        return item
      })
      return _arr.length ? _arr[0].url : ''
    },
    tgUrl() {
      const arr = window.configObj.telegram.split('\n').map(item => ({
        name: item,
        link: item
      }))
      return arr.length ? arr[0].link : ''
    },
    emailUrl() {
      return window.configObj.customerEmail
    },
    isTimeX() {
      return utils.getLs('agentIdentify') === 'e4ee504c-670f-4c82-a610-5b8aa2351ad7'
    },
    isBitpie() {
      return utils.getLs('agentIdentify') === '747f7c0f-9549-4538-8c1d-78b06eb15f6d'
    },
    logoUrl() {
      return window.configObj.logoUri
    },
    appName() {
      return window.configObj.appName
    },
    modle() {
      return window.configObj.officialModel
    },
    cofigObj() {
      return window.configObj
    }
  },
  methods: {
    onMenu() {
      this.showMenu = !this.showMenu
    },
    handleCommand(lang) {
      let langOptionsInfo = this.langOptions.find(item => item.lang == lang)
      this.flagImg = langOptionsInfo.flag;
      i18n.locale = lang
      this.curLang = lang
      utils.setLs("locale", lang)
      this.$store.dispatch('getAccountNames')
    },
    showHideNav() {
      this.product = true;
    },
    closeHideNav() {
      this.product = false;
    },

    handleScroll() {
      if (
        !!document.documentElement.scrollTop &&
        document.documentElement.scrollTop > 50
      ) {
        this.homeHeader = true;
      } else {
        this.homeHeader = false;
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/menuBtn.scss";

.service {
  a {
    color: #fff;
  }
}

.menu {
  display: none;
}

.header-container {
  position: absolute;
  top: 0;
  width: 100%;
  color: #fff;
}

.header-top {
  padding: 15px 0;
  color: #fff;
  position: relative;
  z-index: 10;

  .item {
    margin-right: 20px;
    font-size: 12px;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 5px 10px;
    color: #fff;

    img {
      margin-right: 6px;
      height: 20px;
    }
  }
}

.header {
  &.timex {
    .collapse-pc {
      // background: #4c007d;
      background: #fff !important;
    }

    .container {
      background: #fff !important;
      // background: #4c007d;
    }

    .navbar /deep/ .login {
      color: #333;
    }

    .navbar /deep/ .nav a {
      color: #333;
    }
  }

  .navbar {
    .modle-1 {
      display: flex;
      justify-content: space-between;
      display: none;

      .logo img {
        height: 45px;
      }
    }

    .navbar-brand {
      color: #fff;
      display: inline-flex;
      align-items: center;
      margin-right: 20px;

      h1 {
        width: 130px;
        height: 100%;
        margin: auto;
        // line-height: 50px;
        display: contents;
        font-size: 40px;
      }
    }

    .collapse-pc {
      background: transparent;
      width: 100%;
      display: inline-flex;
      margin: 0 100px;
      // justify-content: right;
    }

    .collapse-pc-scroll {
      transition: all 0.4s ease-in-out;
      background: #4c007d;
    }
  }

  .navbar2 {
    .collapse-pc-scroll {
      background: #222;
    }
  }

  .nav {
    height: 100%;
    display: flex;
    font-weight: 500;
    font-size: 16px;
    width: 100%;

    a {
      color: #B9C5CA;
      background: none;
      display: flex;
      align-items: center;
      padding: 40px 0;
      flex: 1;

      // margin-left: 35px;
      &:hover {
        transition: all 0.25s ease-in-out;
        color: #fff;
        // color: #fff;
      }

      div {
        text-align: center;
        margin: auto;
        position: relative;
        word-break: break-word;
      }

      .drop-icon {
        color: #999;
        position: relative;
        right: 28px;
        top: -8px;
        transform: translate(50%, 50%);
      }
    }
  }

  .navbar2 {
    a div {
      width: 100px;
    }

    .items {
      color: #fff;

      p {
        color: #fff
      }

      .router-link-exact-active::before,
      .router-link-active::before {
        content: unset;
      }
    }

    .logo img {
      height: 45px;
    }
  }

  .items {

    .router-link-exact-active,
    .router-link-active {
      // color: #0088ff;
      font-size: 16px;
      font-weight: 800;
    }

    .router-link-exact-active.no-act {
      background: none;
    }
  }

  .hide-nav-box {
    width: 123px;

    .hide-nav {
      background: #0f0f0f96;
      position: absolute;
      width: 123px;

      a {
        &:hover {
          background: #111;
          color: #ffb738;
        }

        .router-link-exact-active {
          background: #222;
          color: #ffb738;

          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.scroll {
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.navbar-default.scrolled .navbar-header {
  button {
    display: none;
  }

  a {
    display: inline-block;
    padding: 15px 20px;

    img {
      width: auto;
      max-height: 40px;
    }
  }
}

.container {
  display: flex;
  padding: 0;
  justify-content: space-between;
  background: transparent;
  width: 100%;
  padding: 0;
}

.navbar.navbar-default {
  //   background: #0f0f0f;
  border: none;
  padding: 0;
  // padding-bottom: 20px;
  transition: all 0.25s ease-in-out;

  .logo {
    color: #00b5ac;
    max-width: 180px;
  }
}

.header .navbar-default.scrolled {
  // background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  padding: 0;

  .nav a {

    // color: #333;
    div {
      &:hover {
        &:after {
          background: #333;
        }
      }
    }
  }
}

.lang-link {
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;

  i {
    transform: translateY(3px);
  }

  img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
}

.login-prop {
  border: 1px solid #fff;
  padding: 5px 20px;
  border-radius: 5px;
  color: #91929E;
}


@media screen and (max-width: 1200px) {
  .logo-box {
    display: none !important;
  }

  .login-prop {
    // display: none;
  }

  .menu {
    display: block !important;
  }

  .vc {}

  .navbar.navbar-default,
  .navbar-default.scrolled {
    .logo {
      width: auto;
      max-height: 40px;
    }

    .container {
      padding: 5px 5px 5px 10px;
      display: flex;
      flex-direction: column;
    }

    .collapse {
      display: none;
    }

    .navbar-collapse {
      display: block;

      .nav {
        display: flex;
        flex-direction: column;
        background: #fff;

        .router-link-exact-active {
          li {
            color: $primaryColor ;
          }
        }

        .router-link-exact-active.no-act {
          background: none;
        }

        a {
          height: 50px;
          margin-left: 0;

          div {
            font-size: 14px;

            //   line-height: 54px;
            &:hover {
              &:after {
                content: "";
                width: 32px;
                background: none;
                position: absolute;
                bottom: -5px;
                z-index: 99999;
                left: 10px;
                border-radius: 5em;
                color: #333;
                transition: all 0.25s ease-in-out;
              }
            }
          }

          li {
            text-align: center;
            color: #291029;
          }
        }
      }
    }

    .navbar-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        padding: 15px 20px;
      }
    }
  }

  .nav a li {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .menu {
    display: block;
  }

  // .logo-box{
  //   display: none !important;
  // }
  .service {
    flex-direction: column;
    position: absolute;
    top: 72px;
    // padding: 10px 10px 0 10px;
    background: #fff;
    z-index: 20;

    // display: none;
    a {
      width: 100%;
      margin-bottom: 10px;

      span {
        color: #000;
      }
    }
  }

  // .service {
  //   // display: none;
  // }
  .navbar-header {
    height: 60px;
  }

  .header .navbar-default.scrolled .nav a li:hover,
  .header .navbar-default .navbar-collapse .nav li:hover {
    &:after {
      background: none;
    }
  }

  .navbar.navbar-default {
    .collapse {
      border: none;

      ul {
        // background: rgba(255, 255, 255, 0.8);
        margin-top: 10px;
      }

      .nav li {
        color: #222;
        margin: auto;
      }
    }
  }

  .adv {
    font-size: 11px;

    .logo {
      width: 143px;
      top: 6px !important;
    }

    span {
      margin: 0 !important;
      padding-right: 12px;
    }
  }

  .header .navbar-default.scrolled {
    // background: #fff;
  }

  .navbar-collapse {
    text-align: center;
  }

  .header .navbar-default.scrolled .navloto {
    background-color: #fff;
  }
}

@media (max-width: 1200px) {
  .header {
    .container {
      padding: 0 15px;

      .hide-nav-box {
        width: 100%;

        .hide-nav {
          width: 100%;
          position: relative;
        }

        .drop-icon {
          right: 35%;
        }
      }
    }

    .modle-1 {
      width: 100%;
      display: flex !important;
    }
  }
}

.gradient {
  background: #347A7B;
  border: none;
  color: #fff;
}

.logo-box {
  display: flex;
  align-items: center;

  .service-list {
    display: flex;
    padding-left: 150px;

    li {
      margin: 0 20px;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
      }
      a{
        border: none;
      }
    }
  }

  img {
    width: 134px;
    height: 51px;
    margin-right: 20px;
  }

  .logn-name {
    color: #291029;
    font-weight: bold;
    font-size: 20px;

  }
}

.v-container {
  // position: relative;
  // z-index: 10;
}
</style>
