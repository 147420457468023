<template>
  <div class="user-control-box">
    <div class="btn" v-if="showWhatsapp" @mouseover="showWhatsOptions = true" @mouseleave="showWhatsOptions = false">
        <sBtn buttonText="WhatsApp" :color="'black'" :styleModel="modle" :pic="require('../assets/img/whats.png')" />
        <div v-show="showWhatsOptions" class="hideOptions bottom">
            <div
            v-for="(item, i) in whatsOptions"
            :key="i"
            class="hideOptions-item"
            @click="selectWhatsapp(item)"
            >
            <!-- <img :src="item.flag" class="flag" /> -->
            <p>{{ item.name }}</p>
            </div>
        </div>
    </div>
    <div class="btn" @mouseover="showService = true" @mouseleave="showService = false">
      <sBtn :buttonText="$t('service')" :color="'black'" :styleModel="modle" :pic="require('../assets/img/service.png')"/>
      <div v-show="showService" class="hideOptions bottom">
        <div
          v-for="(item, i) in serviceOptions"
          :key="i"
          class="hideOptions-item"
          @click="selectService(item)"
        >
          <p>{{ item[curLang]||item.name }}</p>
        </div>
      </div>
    </div>
    <div v-if="showTg" class="btn" @mouseover="showTgOptions = true" @mouseleave="showTgOptions = false">
      <sBtn :buttonText="'Telegram'" :color="'black'" :styleModel="modle" :pic="require('../assets/img/tg.png')"/>
      <div v-show="showTgOptions" class="hideOptions bottom">
        <div
          v-for="(item, i) in tgOptions"
          :key="i"
          class="hideOptions-item"
          @click="selectTg(item)"
        >
          <p>{{ item[curLang]||item.name }}</p>
        </div>
      </div>
    </div>
    <div class="login" :class="{'no-wrap': modle == 1}">
        <template v-if="userLogin">
          <div class="cursor" style="font-size: 16px;" @click="$router.push({name: 'Me'})"><span class="el-icon-user" style="vertical-align:text-bottom;"></span></div>
          <span class="cursor" @click="$router.push({name: 'Assets'})">{{ $t(305) }}</span>
        </template>
        <template v-else>
          <span class="cursor mr10" @click="$router.push({name: 'Login'})">{{ $t(94) }}</span>
          <span class="cursor" @click="$router.push({name: 'Register'})">{{ $t(95) }}</span>
        </template>
    </div>
    <div v-if="openLang" class="close-bg" @click="openLang = !openLang;" />
    <div @click="openLang = !openLang" class="btn lang-btn">
      <div class="selected-status">
        <div class="status-leble">
          <img :src="langSelected.flag" class="flag" style="width: 20px; height: 20px; vertical-align:meddle;"/>
          <p>{{ langSelected.name }}</p>
        </div>
        <img
          v-if="!openLang"
          src="../assets/img/icons/chevron-down.svg"
          alt="icon"
          width="16"
          height="16"
          class="drop-icon"
        />
        <img
          v-else
          src="../assets/img/icons/chevron-up.svg"
          alt="icon"
          width="16"
          height="16"
          class="drop-icon"
        />
      </div>
      <div v-show="openLang" class="hideOptions">
        <div
          v-for="(item, i) in options"
          :key="i"
          class="hideOptions-item"
          @click="changeLang(item)"
        >
          <img :src="item.flag" class="flag" style="width: 20px; height: 20px;"/>
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sBtn from "./Button.vue";
import i18n from "../lang";
import utils from '@/utils/misc'
import {LANG_OPTIONS} from'@/constants/const'
export default {
  name: "UserControl",
  data() {
    return {
      openLang: false,
      showWhatsOptions: false,
      showService: false,
      showTgOptions: false,
      curLang: localStorage.official_locale||window.configObj.defaultLang||'en-US',
      options: LANG_OPTIONS,
    };
  },
  components: { sBtn },
  computed:{
    userInfo() {
      return this.$store.state.userInfo
    },
    userLogin() {
      return !!utils.getLs('token')
    },
    modle(){
        return window.configObj.officialModel
    },  
    langSelected() {
      let obj = {}
      for (const item of this.options) {
        if (this.curLang === item.lang) {
          obj = item
          break
        }
      }
      return obj
    },
    showWhatsapp() {
      return window.configObj.isShowWhatsapp==='1'
    },
    showTg() {
      return window.configObj.telegram && window.configObj.telegram.trim() !== '0'
    },
    whatsOptions() {
      return window.configObj.whatsapp.split('\n').map(item => ({
        name: item,
        link: `https://wa.me/${item}`
      }))
    },
    serviceOptions() {
      let arr = []
      try{
        arr = JSON.parse(window.configObj.customerUrl)
      } catch (e) {
        console.log(e)
      }
      return arr.map(item => {
        if (this.userInfo.uuid) {
          let params = item.params || ''
          params = params.replace(/\{USER_ID\}/g, this.userInfo.uuid)
          params = params.replace(/\{ACCOUNT\}/g, this.userInfo.mobile||this.userInfo.email)
          params = params.replace(/\{NICKNAME\}/g, this.userInfo.nickname)
          item.url += `&${params}`
        }
        return item
      })
    },
    
    
  },
  mounted() {
    document.addEventListener('click',() => {
      this.showService=false;this.showWhatsOptions=false;
    })
  },
  methods: {
    whatsAppClick() {
      this.showWhatsOptions =!this.showWhatsOptions
    },
    changeLang(obj) {
      i18n.locale = obj.lang
      this.curLang = obj.lang
      utils.setLs("locale", obj.lang)
      this.$store.dispatch('getAccountNames')
    },
    selectWhatsapp(obj) {
      this.showWhatsOptions = false
      window.open(obj.link)
    },
    selectService(obj) {
      this.showService = false
      window.open(obj.url)
    },
    selectTg(obj) {
      this.showTgOptions = false
      window.open(obj.link)
    },
  },
};
</script>

<style lang="scss">
.login{
  color: #fff;  
  margin-left: 0;
  width: 100px;
  height: 72px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  &.no-wrap {
    width: 185px;  
  }
}
.hideOptions {
  &.bottom{
    top: 70px;
    left:0;
    position: absolute;
    border-radius: 6px;
  }
  position: relative;
  z-index: 2000;
  top: 0;
  right: 0;
  background: #0f0f0f;
  line-height: 0px;
  // min-width: 123px;
  display: inline-table;
  .hideOptions-item {
    display: inline-flex;
    justify-content: left;
    align-items: center;
    color: #fff;
    text-align: left;
    line-height: 50px;
    width: 100%;
    padding: 0 15px;
    &:hover {
      background: #222;
      color: #999;
    }
    white-space: nowrap;
    overflow: hidden;
  }
}
.user-control-box {
  display: inline-flex;
  flex-wrap: wrap;
  .lang-btn {
    width: 130px;
    flex-wrap: wrap;
    line-height: 73px;
    justify-content: center;
    color: #fff;
    .selected-status {
      display: inline-flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      width: 130px;
      .status-leble {
        display: flex;
        align-items: center;
        justify-content: right;
        flex-wrap: nowrap;
      }
    }
    
    .drop-icon {
      color: #999;
      position: relative;
      right: -5px;
      top: 0;
      // transform: translate(50%, 50%);
    }
  }
  .btn {
    margin: 0 15px;
    height: 73px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position:relative;
  }
  .flag {
    width: 20px;
    margin-right: 5px;
  }
  .close-bg {
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: transparent;
    position: absolute;
    z-index: 8;
  }
}
@media (max-width: 1200px) {
  .user-control-box {
    justify-content: center;
    .lang-btn {
      .hideOptions {
        background: #fff;
        min-width: unset;
        left: 0;
        width: 100%; 
        .hideOptions-item {
          position: relative;
          right: 0;
          width: 100vw;
          padding: 0 15px;
          color: #222;
        }
      }
    }
  }
}
</style>