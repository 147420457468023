<template>
    <div class="data24">
      <div class="v-container">
        <h2 class="title">{{$t('1108')}}</h2>
        <p class="sub-title">{{$t('11081')}}</p>
        <el-carousel class="carousel" @change="change">
          <el-carousel-item v-for="(item, i) in banners" :key="i">
                <div class="work-item" @click="bannerClick(item)">
                  <!-- <div> -->
                    <img :src="item.bannerUri" />
                  <!-- </div> -->
                  <h2 v-if="item.title">{{ item.title }}</h2>
                  <span v-if="item.summary">{{ item.summary }}</span>
                </div>
          </el-carousel-item>
        </el-carousel>
        <p v-if="banners.length!=0" class="carousel-title" v-html="banners[changeIndex].extra"></p>
      </div>
    </div>
</template>

<script>
import { getCarousels } from "@/api";    
export default {
    data() {
        return {
            banners: [],
            showBannerContent: false,
            curContent: {},
            changeIndex : 0
        }
    },
    beforeMount() {
        this.getBanners();
    },
    methods: {
        bannerClick(item) {
            if (!item.extra) return
            this.curContent = item
            this.showBannerContent = true
            this.$emit('banner', this.curContent)
        },
        getBanners() {
            getCarousels().then((res) => {
                this.banners = res.list || [];
                console.log(this.banners)
            });
        },
        change(e){
          console.log(e)
          this.changeIndex = e
        }
    }
}    
</script>

<style lang="scss" scoped>
  /deep/ .carousel .el-carousel__container{
    height:600px;
  }
  /deep/ .carousel .el-carousel__indicator--horizontal .el-carousel__button{
    width:20px;
    height:20px;
    border-radius:50%;
    padding : 0;
    margin-right:20px;
  }
.work-item{
  width:100%;
  height:600px;
  img{
    width:100%;
    height:100%;
    border-radius:50px
  }
}
.carousel-title{
  font-weight: 400;
  font-size: 20px;
  color: #91929E;
  width:93%;
  margin:26px auto 0;
  text-align:center;
}
.data24{
//   background: url("~@/assets/img/coinin.jpeg") no-repeat center;
//   background-size: cover;
  overflow: hidden;
  position: relative;
  // background: #0E0E22;
  z-index: 2;
  color: #fff;
  &:after {
    // position: absolute;
    // content: '';
    // left: 0;
    // top: 0;
    // width: 100%;
    // height: 100%;
    // z-index: -1;
    // background: #000050;
    // opacity: .85;
  }
  .part-icon{
    visibility: hidden;
    // font-size: 50px;
    // font-weight: 700;
    // margin-bottom: 30px;
    width: 150px;
    height: 150px;
    // border: 4px solid #0088ff;
    // color: #fff;
    // line-height: 150px;
    // border-radius: 50%;
    // position: relative;
    // left: 50%;
    // transform: translateX(-50%);
    // margin-bottom: 24px;
  }
  .data-item{
    padding: 40px 0;
    text-align: center;
    background: url('~@/assets/img/home2/volume.png') no-repeat center center;
    background-size: 100%;
    box-shadow: 10px 10px 5px 5px #0E0E22;
    &.data-item-2 {
        background: url('~@/assets/img/home2/users.png') no-repeat center center;
        background-size: 100%;
    }
    &.data-item-3 {
        background: url('~@/assets/img/home2/fee.png') no-repeat center center;
        background-size: 100%;
    }
    h3{
      font-size: 36px;
      margin-bottom: 20px;
    }
    p{
      font-size: 24px;
      color: #91929E;
    }

  }
  padding: 80px 0;
}
@media screen and (max-width: 1200px) {
  .data24{
    padding: 0;
  }
  .v-container{
    /deep/ .carousel .el-carousel__container{
     height:250px;
     }
    /deep/ .carousel .el-carousel__indicator--horizontal .el-carousel__button{
      width:10px;
      height:10px;
      margin-right:10px;
    }
    .carousel{
      // height:250px;
      .work-item{
        height:250px;
      }
    }
    .title{
      font-size:36px
    }
  }
  .col-2 {
    display: none!important
  }
    .data24 .data-item {
        // border: 1px solid blue;
        background-size: contain!important;
        margin-top: 20px;
    }
}    

.title {
    text-align: center;
    font-weight: 600;
    font-size: 72px;
    color: #5D2146;
} 
.sub-title {
    font-size: 24px;
    color: #91929E;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 60px;
}   

</style>