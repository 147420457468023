import enLocale from 'element-ui/lib/locale/lang/en'
export default {
    ...enLocale,
    1127: 'download',
    1128: 'Login',
    1130: 'Currency',
    1131: 'price',
    1132: 'Change',
    1133: 'Graph',
    1134: 'Details',
    1135: 'Create your cryptocurrency portfolio now',
    1136: '{appName} has multiple features that make it the best place to start trading',
    1137: 'Manage your portfolio',
    1138: 'Buy and sell popular digital currencies and track them all in one place',
    1139: 'Recurring purchases',
    1140: 'Invest in cryptocurrencies slowly by arranging daily, weekly or monthly purchases',
    1141: 'Vault Protection',
    1142: 'For added security, keep your funds in a vault and delay withdrawals',
    1143: 'Mobile app',
    1144: 'Stay on top of the market with the {appName} app for Android or iOS',
    1145: 'While leveraged trading on exotic pairs is great, it would be even better to have a Discord server full of memes and alpha',
    1146: 'Contact us',

    11081: 'Elite Trading Platform: Keep Trading Fast, Stable and Efficient',
    11031: 'Supports multiple PC and mobile operating systems',
    1126: 'Trading Platform',
    11261: 'Most Popular Forex Trading Platform',
    11191: 'Open transactions anytime, anywhere',
    nav0: 'Home',
    nav1: 'Quotes',
    nav2: 'Spot',
    nav3: 'Futures',
    nav4: 'Trust',
    nav5: 'Terms Description',
    nav6: 'User Agreement',
    nav7: 'Privacy Policy',
    nav8: 'Legal Statement',
    nav9: 'Trading Specification',
    nav10: 'Novice Help',
    nav11: 'FAQ',
    nav12: 'Transaction fee rate',
    nav13: 'About us',
    nav14: 'Service',
    operate: 'Operation',
    homeTitle_1_1: 'The world\'s leading digital asset trading platform',
    homeTitle_1_2: 'The most popular digital asset trading platform, access to the most powerful and convenient technical analysis and trading tools on the market. ',
    hftTitle: 'Activate HTF funds.',
    hftTitle2: 'Use the AI Super Algorithmic Calculator to create value in the medium of cryptocurrency!',
    nowtrans: 'Transaction immediately',
    logIn: 'Login',
    whats: 'Whats',
    service: 'Service',
    footer1: `Risk warning: The financial products provided by the company include contracts for difference ('CFDs') and other complex financial products. Trading CFDs carries a high level of risk, because leverage can be both good and bad for you at the same time. Therefore, CFDs may not be suitable for all investors, because it may lose all your investment principal. You should never invest money that you cannot afford to lose. Before trading the complex financial products provided, please make sure to understand the risks involved. You are granted a limited non-exclusive and non-transferable right to use the IP provided on this website only for personal and non-commercial purposes related to the services provided on the website. `,
    footer2: 'Please note that the information on this website is not aimed at residents of any country or jurisdiction that differs from the laws and regulations described on the website. Carrod Securities Co Ltd is not a financial adviser and only general advice is provided to you. Will not issue any opinions or suggestions on the acquisition, holding or selling of margin trading.',
    1: 'Currency Name',
    2: 'Daily rate',
    3: 'Product deadline',
    4: 'Starting investment amount',
    5: 'Operation',
    6: 'Purchase',
    7: 'Remaining currency management',
    8: 'Flexible Earn, profit every day',
    9: 'About us',
    10: 'User Agreement',
    11: 'Privacy Policy',
    12: 'Legal Statement',
    13: 'Trading Rules',
    14: 'Terms Description',
    15: 'Service',
    16: 'Novice Help',
    17: 'FAQ',
    18: 'Transaction fee rate',
    19: 'Digital currency second contract trading system',
    20: 'Fast, convenient, no apportionment, risk controllable',
    21: 'System introduction',
    22: 'With the rapid development of digital currency futures, the majority of users hold more and more assets, and the trading methods of virtual currency have also become diverse. Users are increasingly pursuing fast and efficient transaction settlement methods. For this reason, the second contract has also been highly praised. The second contract launched by Mutual Cloud is a trading system for settlement of virtual currency contracts in units of cents. The system supports market price trading and limit price trading; users can buy call and put options, with small capital investment, low threshold, and short settlement cycle, divided into 1 minute, 2 minutes, 3 minutes, etc., and the system can be configured independently. The system has a rigorous risk control system, with the highest and lowest fluctuations set, if the limit is exceeded, the odds will be automatically reset to zero. ',
    23: 'Kline source',
    24: 'Trading mainly depends on the market quotations of external exchanges for trading',
    25: 'Support currency',
    251: 'Deposit Method',
    252: 'Deposit',
    26: 'Recharge via wallet address',
    27: 'Purchase through C2C transactions',
    28: 'Meet the needs of the platform to issue coins',
    281: 'Purchase through OTC transaction,',
    282: 'The platform does not need to bear the risk of currency floating',
    29: 'Contract model design',
    30: 'Trading Method',
    31: 'Settlement time setting',
    32: 'Trade Direction',
    33: 'Transaction Control',
    34: 'Risk control',
    35: 'Buy limit',
    36: 'Prevent large-scale losses from customers',
    38: 'Set the deadline for placing orders',
    39: 'Ensure enough liquidation time for the draw',
    40: 'Set up and down lines',
    41: 'Trigger the odds to zero to prevent unilateral transactions',
    42: 'Avoid large-scale losses',
    43: 'One-click close button',
    44: 'Customers can click the button to close a position in extreme situations',
    46: 'Service Monitoring',
    47: 'Comprehensive monitoring of services to ensure timely response to service failures and initiate failure email notifications in time',
    48: 'Service Support',
    49: 'We approach customers with our heart, we are enthusiastic, attentive, patient, and careful. We have a professional software R&D team, rigorous platform monitoring and management, and a professional one-stop service team. ',
    50: 'One, select trading pair',
    51: 'Two, check price',
    52: 'Look at the market transaction price and choose the right price to place an order. ',
    53: 'Three, buy after filling in the order',
    54: 'Choose the transaction pair that needs to be purchased (currency-to-currency transaction refers to using one digital asset to exchange for another digital asset)',
    55: 'Fill in the purchase price and purchase volume, and wait for the market transaction. ',
    57: 'Understand currency transactions in seconds',
    58: 'Safe and reliable, fast transaction',
    59: 'Trading Quotes',
    60: 'In stock',
    601: 'Provide advanced digital assets, CFDs and commodity trading platforms to global investors',
    61: 'What is the spot? ',
    62: 'Spot is for futures. Spot and futures are similar, the main difference lies in the difference in leverage and margin, transaction delivery time, etc.',
    63: 'Futures',
    64: 'What are futures?',
    65: 'Futures contract, referred to as futures, is a trading method that spans time. By signing a contract, the buyer and seller agree to deliver a specified amount of spot according to the specified time, price and other trading conditions. ',
    66: 'Trust Earn',
    67: 'What is trust financing?',
    68: 'Decentralized finance is a kind of blockchain-based finance. It does not rely on financial institutions such as brokers, exchanges or banks to provide financial tools, but uses smart contracts on the blockchain to conduct financial activities',
    69: 'C2C',
    70: 'What is C2C',
    71: 'C2C, also known as "private-to-private" is a form of transaction, it refers to the form of individual to individual transaction',
    72: 'One-stop trading service',
    73: 'Name',
    74: 'Entry Price',
    75: '24H Change',
    76: '24H Volume',
    77: 'Quotes',
    78: 'Stable and safe aggregation',
    79: 'Financial-grade security protection',
    80: 'Unbiased zero-knowledge proof privacy and confidentiality technology to protect user privacy, multiple signatures, layered architecture and other security designs. ',
    81: 'High-performance matching engine',
    82: 'Adopt advanced distributed cluster architecture and microservice development methods. Support more than 3 million transactions per second concurrent. ',
    83: '24 hours uninterrupted service',
    84: '24/7 CUSTOMER SUPPORT We strive to answer your queries as quickly as humanly possible. Please allow us up to 24 hours to response.',
    85: 'Open transactions anytime, anywhere',
    86: 'Brand new revision to meet the trading needs of various scenarios at any time',
    87: '24*7 hours all-weather, full-time operation mode, customer service will answer your related inquiries as soon as possible. ',
    88: 'Trading pair',
    89: 'Latest price',
    90: 'Change',
    91: 'Highest price',
    92: 'Lowest price',
    93: 'Volume',
    94: 'Log in',
    95: 'Sign Up',
    fee: {
        1: 'Transaction fee rate',
        2: 'Currency transaction fee',
        3: 'Contract transaction fee'
    },
    law: {
        1: 'Legal Statement',
        2: 'First Article',
        3: 'This platform is committed to creating a compliant, safe, and reliable digital asset trading environment for users, and prohibits users from using this website to engage in money laundering, smuggling, commercial bribery, malicious market manipulation and other illegal trading activities or illegal activities. To this end, we do our utmost to formulate strict anti-money laundering and anti-terrorism programs and take effective security risk control measures to maximize the protection of the legitimate rights and interests of users and enable the platform itself to maintain a good brand reputation',
        4: 'Second Article',
        5: 'When the competent authorities produce corresponding investigation documents and request this website to cooperate in the investigation of designated users, or take measures such as sealing, freezing or transferring user accounts, this website will verify the validity and authenticity of judicial documents After sex, in accordance with the requirements of the competent authority determined by the platform to assist in providing corresponding user data, or performing corresponding operations, the user’s privacy leakage, account inoperability, and other losses caused to users, etc., are caused by this website. We do not assume any responsibility. ',
        6: 'Article 3',
        7: 'All users who use the services of this website should know before trading: digital assets are not backed by any government or central bank, and the price of digital assets fluctuates greatly, holding or using digital assets has a higher risk. Before making a trading decision, you should be rational and prudential with your own financial situation to determine whether it is suitable for you',
        8: 'Article 4',
        9: 'Anyone who logs on to this website in any way or directly or indirectly uses the services of this website shall be deemed to voluntarily accept the constraints of this website statement. '
    },
    rules: {
        1: 'Trading rules',
        2: 'After creating an order, the buyer should:',
        3: '1, complete the payment within the payment time specified in the order, and then click "I have paid";',
        4: '2, use my real-name payment method, order payment according to the order amount and the payment account designated by the order to complete the order payment in real time;',
        5: '3. If you choose a non-real-time payment method to pay for the order, you must obtain the seller\'s consent in advance',
        6: '4. The payment order shall not note "digital currency, BTC, Bitcoin" and other information;',
        7: 'Principles that the seller should follow',
        8: 'After the order is generated, the seller should',
        9: '1. Provide a receiving account certified by the platform;',
        10: '2, after receiving the buyer\'s full payment, complete the release within the time specified in the order;',
        11: '3. Without the buyer\'s consent, the buyer/customer service shall not be required to cancel the order;',
        12: '4. Without the consent of the buyer, no objection to the order price that has been created is allowed;'
    },
    help: {
        1: 'Novice Help',
        2: 'Limit Order',
        3: 'An order to buy/sell at a specified price or better. ',
        4: 'Market order',

        5: 'An order to buy or sell immediately at the current market price. ',
        6: 'Limit Stop Loss Order',
        7: 'An order to sell or close a position at a set price after the market reaches a certain price to control losses. ',
        8: 'Limit Price Take Profit Order',
        9: 'Similar to a stop-limit order, it is also an order to sell or close a position at a set price after the market reaches a certain price, which is used to control profit. ',
        10: 'Market stop loss order',
        11: 'An order to sell or close a position at the market price after the market reaches a certain price to control losses. ',
        12: 'Market Price Take Profit Order',
        13: 'Similar to a market stop loss order, it is also an order to sell or close a position at the market price after the market reaches a certain price, which is used to control profit. ',
        14: 'What is the margin for opening a position? ',
        15: 'Margin trading means that you only need to pay a certain percentage of the margin to buy and sell the contract. According to the selected leverage, the margin ratio is a smaller multiple of the contract value. It can magnify your profits, but it may also expose you to additional risks. ',
        16: 'What is a liquidation and what is a puncture? ',
        17: 'When the remaining funds in your account are less than the floating profit and loss required by the trading contract, your position will be forcibly closed by the exchange, also known as liquidation. Since the price when closing a position is determined based on the market price, when the transaction price is extremely unfavorable, you may not have enough funds to pay for the loss incurred in the transaction, resulting in a negative value in your account. This situation is called shorting. '
    },
    96: 'Select trading pair',
    97: 'Check price',
    98: 'Buy after filling in the order',
    99: 'Account login',
    100: 'Welcome back! Log in with your email or phone number',
    101: 'Mailbox',
    102: 'Mobile',
    103: 'Forgot your password? ',
    104: 'Register now',
    105: 'Password',
    106: 'Please enter your phone number',
    107: 'Mobile phone number format error',
    108: 'Please enter email',
    109: 'Mailbox format error',
    110: 'Please enter a password',
    111: 'Password is at least 6 characters',
    112: 'Password is up to 32 bits',
    113: 'Language and Region',
    114: 'Create {appName} account',
    115: 'Use your email or mobile phone number to register',
    116: 'Invitation code',
    '116x': 'Area Identification Code',
    117: 'Agree',
    118: 'and',
    119: 'User Agreement',
    120: 'Privacy Policy',
    121: 'Already have an account? ',
    122: 'Verification Code',
    123: 'Send',
    124: 'Please enter the verification code',
    125: 'Verification code must be 6 digits',
    126: 'Reset password',
    127: 'OK',
    128: 'Use your email or mobile phone to reset the login password',
    129: 'KYC Verification',
    130: 'Completing real-name authentication helps protect account security, increase withdrawal limits and transaction permissions',
    131: 'Go to Verification',
    132: 'Authentication',
    133: 'Used to log in, withdraw coins, retrieve passwords, modify security settings',
    134: 'Verified',
    135: 'Password Management',
    136: 'Login password',
    137: 'Funds Password',
    138: 'Used to protect account security',
    139: 'Used to protect the safety of funds',
    140: 'Personal Settings',
    141: 'Color preference',
    142: 'Settings',
    143: 'Modify',
    144: 'Set the candlestick chart, buy up and buy down colors',
    145: 'Pricing Method',
    146: 'Account asset valuation unit,',
    147: 'default',
    148: 'Green rises and reds fall',
    149: 'Red up and green down',
    150: 'Modify login password',
    151: 'Modify Funds Password',
    152: 'Old password',
    153: 'New password',
    154: 'Please enter the old password',
    155: 'New phone number',
    156: 'New mailbox',
    157: 'Bind mobile phone number',
    158: 'bind mailbox',
    159: 'Modify mobile phone number',
    160: 'Modify mailbox',
    161: 'Verified',
    162: 'Set a nickname',
    163: 'Please enter your nickname',
    164: 'Cancel',
    165: 'Country',
    166: 'First name',
    167: 'Last name',
    168: 'Certificate Type',
    169: 'ID/Passport No',
    170: 'Please select a country',
    171: 'Please enter first name',
    172: 'Please enter the last name',
    173: 'Please enter the ID number',
    174: 'Please upload the front photo of the ID',
    175: 'Please upload a photo of the reverse side of your ID',
    176: 'Please upload a photo of your hand-held ID',
    177: 'Confirm',
    178: 'Certifying',
    179: 'ID',
    180: 'Passport',
    181: 'Next',
    182: 'Upload ID photos and complete authentication',
    183: 'Upload failed',
    184: 'Image format error',
    185: 'Picture is too large',
    186: 'Loading',
    187: 'Total Balance',
    188: 'Transfer',
    189: 'Withdrawal',
    190: 'Deposit',
    191: 'Financial records',
    192: 'Freeze',
    193: 'Available',
    194: 'Total',
    195: 'Hide small value currency',
    196: 'Coin',
    197: 'USDT valuation',
    198: 'Search',
    199: 'Recharge Network',
    200: 'Deposit address',
    201: 'Coin out address',
    202: 'Recharge amount',
    203: 'Screenshot of depositing coins',
    204: 'Remarks',
    205: 'Copy address',
    206: 'Copy successful',
    207: 'Return',
    208: 'Optional',
    209: 'Please enter the number of coins to be recharged',
    210: 'Please a valid number',
    211: 'Please enter the coin address',
    212: 'Please upload a screenshot of the coin deposit',
    213: 'Withdrawal address',
    214: 'Withdrawal amount',
    215: 'Available balance',
    216: 'All',
    217: 'Handling fee',
    218: 'Please enter the amount of coins withdrawn',
    219: 'Please enter the funds password',
    220: 'Funds password must be 6 digits',
    221: 'Default password',
    222: 'You have not bound the corresponding wallet yet! ',
    223: 'Go to add',
    224: 'Submission successful',
    225: 'Add collection wallet',
    226: 'Address',
    227: 'Please enter the network',
    228: 'Please enter the address',
    229: 'Network',
    230: 'Withdrawal network',
    231: 'Address length is the lowest 32 bits',
    232: 'Funds transfer',
    233: 'Transfer amount',
    234: 'Please enter the transfer amount',
    235: 'Insufficient balance',
    236: 'Available Balance',
    237: 'Transfer Direction',
    238: 'Confirm transfer',
    239: 'From',
    240: 'to',
    241: 'Financial records',
    242: 'Coin deposit records',
    243: 'Withdrawal records',
    244: 'Transfer records',
    245: 'Quantity',
    246: 'Status',
    247: 'Created time',
    248: 'Confirm time',
    249: 'No more',
    250: 'Price',
    253: 'Name',
    254: 'Change',
    255: 'No data yet',
    256: 'Sum',
    257: 'High',
    258: 'Low',
    259: 'Vol',
    260: 'Open',
    261: 'Close',
    262: 'Amount',
    263: 'Futures trading',
    264: 'Buy up',
    265: 'Buy down',
    2641: 'Buy/Long',
    2651: 'Sell/Short',
    266: 'Currency Name',
    267: 'View contract',
    268: 'Buy amount',
    269: 'Please enter the purchase amount',
    270: 'Transaction fee',
    271: 'Minimum amount: ',
    272: 'Status',
    273: 'Pending delivery',
    274: 'Delivered',
    275: 'Order successfully placed',
    276: 'Currency Name',
    277: 'Profit and loss ',
    278: 'Buy Price',
    279: 'Delivery Price',
    280: 'Continue trading',
    283: 'Profit',
    284: 'Confirm order',
    285: 'History orders',
    286: 'Orders in progress',
    287: 'Created time',
    288: 'Buy',
    289: 'Sell',
    290: 'Amount',
    291: 'Total',
    292: 'Contract',
    293: 'Delivery Time',
    294: 'Delivery price',
    295: 'seconds',
    296: 'Minutes',
    297: 'Hour',
    298: 'Day',
    299: 'Remaining time',
    300: 'View more',
    301: 'Currency transaction',
    302: 'Sell amount',
    303: 'Please enter the selling amount',
    304: 'Fiat currency transaction',
    305: 'Assets',
    306: 'Logout',
    307: 'Registered successfully',
    308: 'Please enter a recommendation',
    '308x': 'Please enter the area identification code',
    309: 'Success',
    310: 'Failed',
    311: 'Confirming',
    312: 'Payment method',
    313: 'Withdrawal address',
    314: 'Delete',
    315: 'Operation',
    316: 'Your wallet',
    317: 'Are you sure you want to delete? ',
    318: 'Delete successfully',
    319: 'Authentication failed',
    320: 'Recertification',
    330: 'Digital asset withdrawal wallet',
    331: 'Bind bank card, withdraw cash to fiat currency',
    333: 'Currencies',
    334: 'Forex',
    335: 'Metals',
    1000: 'day',
    1001: 'In progress',
    1002: 'Closed',
    1003: 'Rules',
    1004: 'Product advantage',
    1005: 'Save as you take',
    1006: 'Dividend expired',
    1007: 'Daily bonus',
    1008: 'Live deposit interest',
    1009: '100% capital guarantee',
    1010: 'Normal income during holidays',
    1011: 'Rest next day',
    1012: 'Product advantage',
    1013: 'Members deposit 200,000 USDT on the platform, choose a wealth management product with a period of 14 days and a daily income of 0.5%, and the daily income is as follows: 200,000*0.5%=1,000USDT, that is: 14 days later, you can get 14,000 USDT in income, and the income is issued daily. Deposit the principal and it will be automatically returned to your account upon maturity.',
    1014: 'Custody Order',
    1015: 'Hosting',
    1016: 'Completed',
    1017: 'Redeemed',
    1018: 'No data yet',
    1019: 'Purchase amount',
    1020: 'Daily rate of return',
    1021: 'TRUST.6',
    1022: 'Payment time',
    1023: 'Redemption Date',
    1024: 'Expired',
    1025: 'Recently (daily income)',
    1026: 'Dividend time',
    1027: 'Every day',
    1028: 'Custody amount',
    1029: 'Return on expiration',
    1030: 'Available balance',
    1031: 'Estimated total income',
    1032: 'Funds transfer',
    1033: 'All',
    1034: 'Single limit',
    1035: 'Handling fee',
    1036: 'I have read and agreed',
    1037: 'Platform financial management service agreement',
    1038: 'OK',
    1039: 'Apply now',
    1040: 'Buy',
    1041: 'Sell',
    1042: 'Wire transfer',
    1043: 'Please enter the amount',
    1044: 'Fiat currency category',
    1045: 'Quantity sold',
    1046: 'Maximum available for sale',
    1047: 'All',
    1048: 'Reference unit price',
    1049: 'Your payment method',
    1050: 'Bank Name',
    1051: 'Bank account number',
    1052: 'Name',
    1053: 'You have not bound the corresponding payment method and cannot be sold. ',
    1054: 'Order record',
    1055: 'Filter',
    1056: 'Type',
    1057: 'Status',
    1058: 'Processing',
    1059: 'Passed',
    1060: 'Failed',
    1061: 'Quantity',
    1062: 'Success',
    1063: 'Performing task',
    1064: `Calculate today's results`,
    1065: 'Cumulative income',
    1066: 'Order in custody',
    1067: 'Trust account is not opened',
    1068: 'Open a communication trust account, enjoy stable passive income, and make asset appreciation easier. ',
    1069: 'Apply for activation means that you have read and agreed to it',
    1070: '"Platform Financial Management Service Agreement"',
    1071: 'Apply for activation',
    1072: 'Cancel',
    1073: 'Executing...',
    1074: 'Redemption',
    1075: 'Are you sure you want to redeem it? ',
    1076: 'Early redemption requires payment of liquidated damages, which will be deducted this time according to your financial management type',
    1077: ', Is the redemption confirmed?',
    1078: 'Sent',
    1079: 'Please enter the verification code',
    1080: 'Prompt',
    1081: 'Profit',
    1082: 'Time',
    1083: 'Add legal currency payment method',
    1084: 'Order Status',
    1085: 'Currency',
    1086: 'Number of legal currency',
    1087: 'Bank account number',
    1088: 'Fat money collection method',
    1089: 'Add collection method',
    1090: 'Existing payment method',
    1091: 'Fiat currency',
    1092: 'Bank Code',
    1093: 'Are you sure you want to delete the payment method? ',
    1094: 'Please enter the bank account number',
    1095: 'Please enter the bank name',
    1096: 'Please enter the legal currency',
    1097: 'Please select',
    1098: 'Please enter your name',
    1099: 'Branch',
    1100: 'Unlimited',
    1101: 'You have not yet verified your real name',
    1102: 'White Paper',
    1103: 'Our Features',
    1104: 'We\'re Secure',
    1105: 'We\'re powerful',
    1106: 'Best Support',
    1107: 'We\'re Global',
    1108: 'How it Works',
    1109: 'Users in more than 20 countries and regions are using our platform and are widely recognized',
    1110: 'Advanced Data Encryption',
    1111: 'Rich variety of cryptocurrencies',
    1112: '90+ Cryptocurrencies listed',
    1113: 'Your transaction data is secured via end-to-end encryption, ensuring that only you have access to your personal information.',
    1114: '43 billion',
    1115: '24h trading volume',
    1116: '30 million',
    1117: 'Registered users who trust us',
    1118: 'Lowest transaction fees',
    1119: 'Download',
    1120: 'Financial institution name',
    1121: 'Branch name',
    1122: 'Branch office number',
    1123: 'Account number',
    1124: 'Recipient name',
    1125: 'Kinjo',


    1148: 'The Most Trusted Cryptocurrency Platform',
    1149: 'Here are a reasons why you should choose us',
    1150: 'We`re Secure',
    1151: 'Unbiased zero-knowledge proof privacy and confidentiality technology to protect user privacy, multiple signatures, layered architecture and other security designs ',
    1152: 'Best Support',
    1153: '24*7 hours all-weather, full-time operation mode, customer service will answer your related inquiries as soon as possible ',
    1154: 'We`re Global',
    1155: 'Users in more than 20 countries and regions are using our platform and are widely recognized ',
    1156: 'We`re powerful',
    1157: 'The most popular digital asset trading platform, access to the most powerful and convenient technical analysis and trading tools on the market ',
    1158: 'Rich variety of cryptocurrency',
    1159: '90+ Cryptocurrencies listed',
    1160: 'Advanced Data Encryption',
    1161: 'Your transaction data is secured via end-to-end encryption, ensuring that only you have access to your personal information. ',
    1162: 'We support a variety of the most popular digital currencies',
    1163: 'Many countries have established localized transaction',
    1164: 'Use legal currency to buy digital assets and quickly enter the world of digital assets',
    1165: 'Trade on to go anywhere anytime',
    1166: 'Scan me to Download',
    1167: 'Exchange Rates',
    1168: 'Buy and sell 250+ cryptocurrencies with exchange rates upload recently',
    1169: 'Stay in the know with our app and desktop client',
    1170: 'Name',
    1171: 'Price',
    1172: 'Change',
    1173: 'Chart',
    1174: 'Trade',
    1175: 'Detail',
    1176: 'We’re',
    1177: 'Secure',
    1178: 'We have',
    1179: 'Global',
    1180: 'Powerful',
    1181: 'Rich Variety of',
    1182: 'Cryptocurrency',
    1183: 'Advanced Data',
    1184: 'Encryption',
    1185: 'ready to get started',
    1186: 'We are the world s leading digital asset trading platform. ',
    1187: 'We are the world`s leading digital asset trading platform. We constantly strive to improve security features to ensure the security of our customers digital assets. Our model is to bring customers projects with sustainable value.',
    
    1188: 'Total Supply',
    1189: '24*7 all-weather, full-time operation mode, customer service will answer relevant inquiries for you as soon as possible',
    1190: 'We are global',
    1191: 'We are strong',
    1192: 'The most popular digital asset trading platform, get the most powerful and convenient technical analysis and trading tools on the market.',
    1193: 'Wide variety of cryptocurrencies',
    1194: 'Your transaction data is protected with end-to-end encryption, ensuring that only you can access your personal information.',
    1195: 'Minimum transaction fee',
    1196: 'Trust our registered users',
    1197: '24 hour trading volume',
    1198: '+42 billion',
    1199: '+30 million',
    1200: 'Several countries have set up localized transaction service centers to create a blockchain ecosystem that integrates multiple business forms ',
    1201: 'Use fiat currency to buy digital assets and quickly enter the world of digital assets',
    1202: 'APP download',
    1203: 'Our strengths',
    1204: 'Rich currency and currency information, providing currency, contracts, legal currency transactions and other trading methods, professional market and depth.'
}