<template>
    <footer :style="{background: $route.path == '/' ? '' : '#1d1f20'}">
        <div class="footer-navItem v-container">
            <div class="navs-box">
                <div class="logo">
                    <div class="logo-box">
                        <img :src="logoUrl" :alt="appName" style="height:70px; width:auto;">
                        <span class="logn-name">{{ appName }}</span>
                    </div>
                    <div class="footer-introduce">
                        {{ $t('1187') }}
                    </div>
                    <div class="language-down">
                        <img src="@/assets/img/home6/Shape.png" />
                        <el-dropdown trigger="click" class="lang-switcher" @command="handleCommand">
                            <span class="lang-link flex">
                                {{ curLangName }}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="item in langOptions" :key="item.lang" :command="item.lang"
                                    class="flex align-center">
                                    <img :src="item.flag" alt="" style="width: 24px;height:24px; margin-right: 6px;">
                                    {{ item.name }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="app-name">
                        © 2022, {{ appName }}
                    </div>
                </div>
                <div class="right-box">
                    <div class="navs-box">
                        <div v-for="(navItem, i) in navs" :key="i" class="navs-box-item">
                            <div>
                                <h3 class="navs-title">{{ navItem.title }}</h3>
                                <div class="navs-item-box">
                                    <div v-for="(pathRouter, i) in navItem.routes" :key="`${i}_${pathRouter}`"
                                        @click="handleRouter(pathRouter.path)" class="navs-item">
                                        <!-- <i class="fa fa-chevron-right"></i> --><span>{{ pathRouter.pathName
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-box">
                        <ul>
                            <li>
                                <a :href="serviceUrl" class="item flex align-center" target="_blank">
                                    <div>
                                        <img src="@/assets/img/home6/service.png" />
                                    </div>
                                    <p>{{ $t('15') }}</p>
                                </a>
                            </li>
                            <li v-if="showWhatsapp">
                                <a :href="whatsAppUrl.link" class="item flex align-center" target="_blank">
                                    <div>
                                        <img src="@/assets/img/home6/whatapps.png" />
                                    </div>
                                    <p>WhatsApp</p>
                                </a>
                            </li>
                            <li v-if="showLine">
                                <a :href="lineAppUrl.link" class="item flex align-center" target="_blank">
                                    <div>
                                        <img src="@/assets/img/home6/line.png" />
                                    </div>
                                    <p>Line</p>
                                </a>
                            </li>
                            <li v-if="showTg">
                                <a v-if="showTg" :href="tgUrl" class="item flex align-center" target="_blank">
                                    <div>
                                        <img src="@/assets/img/home6/telegram.png" />
                                    </div>
                                    <p>Telegram</p>
                                </a>
                            </li>
                            <li v-if="!!emailUrl && emailUrl !== '0'">
                                <a :href="`mailto:${emailUrl}`" class="item flex align-center" target="_blank">
                                    <div>
                                        <img src="@/assets/img/home6/mail.png" />
                                    </div>
                                    <p>Email</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
  
<script>
import utils from "@/utils/misc"
import { LANG_OPTIONS } from '@/constants/const'
import i18n from "@/lang";
export default {
    name: "Footer",
    data() {
        return {
            langOptions: LANG_OPTIONS,
            curLang: localStorage.official_locale || window.configObj.defaultLang || 'en-US',
            showMenu: false,
            navs: [
                {
                    title: this.$t("nav5"),
                    routes: [
                        {
                            path: "about",
                            pathName: this.$t("nav6"),
                        },
                        {
                            path: "about",
                            pathName: this.$t("nav7"),
                        },
                        {
                            path: "about",
                            pathName: this.$t("nav8"),
                        },
                        {
                            path: "about",
                            pathName: this.$t("nav9"),
                        },
                    ],
                },
                {
                    title: this.$t("nav14"),
                    routes: [
                        {
                            path: "about",
                            pathName: this.$t("nav10"),
                        },
                        {
                            path: "about",
                            pathName: this.$t("nav11"),
                        },
                        {
                            path: "about",
                            pathName: this.$t("nav12"),
                        },
                        {
                            path: "about",
                            pathName: this.$t("nav13"),
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        if (window.innerWidth >= 640) {
            this.showMenu = true
        }
        window.onresize = () => {
            if (window.innerWidth >= 640) {
                this.showMenu = true
            }
        }
    },
    methods: {
        onMenu() {
            this.showMenu = !this.showMenu
        },
        handleRouter(path) {
            this.$router.push(path);
        },
        handleCommand(lang) {
            i18n.locale = lang
            this.curLang = lang
            utils.setLs("locale", lang)
            this.$store.dispatch('getAccountNames')
        },
    },
    computed: {
        logoUrl() {
            return window.configObj.logoUri
        },
        appName() {
            return window.configObj.appName
        },
        userInfo() {
            return this.$store.state.userInfo
        },
        curLangName() {
            let result = ''
            for (const item of this.langOptions) {
                if (item.lang === this.curLang) {
                    result = item.name
                }
            }
            return result
        },
        showWhatsapp() {
            return window.configObj.isShowWhatsapp === '1'
        },
        showLine() {
            return window.configObj.isShowLine === '1'
        },
        showTg() {
            return window.configObj.telegram && window.configObj.telegram.trim() !== '0'
        },
        whatsAppUrl() {
            return window.configObj.whatsapp.split('\n').map(item => ({
                name: item,
                link: `https://wa.me/${item}`
            }))[0]
        },
        lineAppUrl() {
            return window.configObj.line.split('\n').map(item => ({
                name: item,
                link: item
            }))[0]
        },
        serviceUrl() {
            let arr = []
            try {
                arr = JSON.parse(window.configObj.customerUrl)
            } catch (e) {
                console.log(e)
            }
            let _arr = arr.map(item => {
                if (this.userInfo.uuid) {
                    let params = item.params || ''
                    params = params.replace(/\{USER_ID\}/g, this.userInfo.uuid)
                    params = params.replace(/\{ACCOUNT\}/g, this.userInfo.mobile || this.userInfo.email)
                    params = params.replace(/\{NICKNAME\}/g, this.userInfo.nickname)
                    item.url += `&${params}`
                }
                return item
            })
            return _arr.length ? _arr[0].url : ''
        },
        tgUrl() {
            const arr = window.configObj.telegram.split('\n').map(item => ({
                name: item,
                link: item
            }))
            return arr.length ? arr[0].link : ''
        },
        emailUrl() {
            return window.configObj.customerEmail
        },
    },
};
</script>
  
<style lang="scss" scoped>
footer {
    position: relative;
    bottom: 0;
    //  height: 182px;
    color: #fff;
    overflow: hidden;
    padding-bottom: 20px;

    //  padding: 10px;
    .footer-navItem {
        margin-top: 200px;

        .navs-box {
            display: flex;

            .logo {
                position: relative;
                font-size: 25px;
                color: #00b5ac;
                flex: 1;

                .logo-box {
                    display: flex;
                    align-items: center;

                }

                img {
                    width: 134px;
                    height: 51px;
                    margin-right: 20px;
                }

                .logn-name {
                    background: linear-gradient(207.67deg, #FDAE8F 3.43%, #FD1C68 104.7%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    font-weight: bold;

                }

                .lang-link {
                    color: #fff;
                    font-size: 22px;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                }

                .app-name {
                    font-size: 18px;
                    margin-top: 15px;
                    color: #8393AF;
                }

                .language-down {
                    display: flex;
                    align-items: center;
                    margin-top: 60px;

                    img {
                        width: 35px;
                        height: 35px;
                    }
                }
            }

            .left-col {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                color: #B6B3CD;
                font-size: 16px;

                .lang-switcher {
                    color: #fff;
                    font-size: 22px;
                    margin: 20px 0;

                    .lang-link {
                        display: flex;
                        align-items: center;
                    }
                }

                .service {
                    margin-top: 10px;

                    img {
                        width: 36px;
                        height: 36px;
                    }

                    .item {
                        display: flex;
                        flex-direction: column;
                        margin-right: 20px;

                        span {
                            font-size: 12px;
                            margin-top: 10px;
                        }
                    }
                }
            }

            .navs-box {
                display: flex;
            }

            .navs-box-item {
                text-align: left;
                display: flex;
                margin-right: 100px;

                .navs-title {
                    margin: 20px auto;

                    &::before {
                        // content: "";
                        // height: 2px;
                        // width: 33px;
                        // background: #0088ff;
                        // position: absolute;
                        // transform: translate(0, 33px);
                    }
                }

                .navs-item-box {
                    padding-top: 0px;
                    // display: flex;

                    .navs-item {
                        margin: 15px 20px 10px 0px;
                        cursor: pointer;

                        span {
                            color: #8393AF;
                        }

                        i {
                            margin-right: 8px;
                            color: #0088ff;
                        }
                    }
                }
            }
        }

        .right-box {
            flex: 1;
            padding-left: 100px;
        }

        .des-long {
            font-size: 8px;
            margin-bottom: 50px;
            color: #999;

            p {
                text-align: left;
            }
        }

        .copyright {
            margin-bottom: 50px;
            height: 80px;
            color: #999;
        }
    }

}

@media screen and (max-width: 1000px) {
    .navs-box {
        display: block !important;

        .right-box {
            padding-left: 0 !important;
        }

        .contact-box {
            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 33% !important;
                    padding: 20px 0;
                    text-align: center;
                }
            }
        }
    }
}

.footer-introduce {
    text-align: left;
    color: #8393AF;
    font-size: 14px;
    margin-top: 10px;
}

.contact-box {
    ul {
        display: flex;

        li {
            a {
                display: block;
            }

            width: 100px;
            padding: 20px 0;
            text-align: center;
        }
    }
}
</style>
  