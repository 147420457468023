<template>
    <footer>
      <div class="footer-navItem v-container">
        <div class="navs-box">
            <el-row :gutter="20" style="width: 100%; display: flex; flex-direction: column;">
              <el-col :xl="8" :lg="8" :md="12">
                <div class="logo">
                  <h1>
                    <img :src="logoUrl" :alt="appName" style="height:70px; width:auto;">
                    {{appName}}
                  </h1>
                </div>
              </el-col>
              <div class="navs-box-right">
                <el-col :xl="24" :lg="24" :md="24" v-for="(navItem, i) in navs" :key="i" class="navs-box-item">
                  <h3 class="navs-title">{{ navItem.title }}</h3>
                  <div class="navs-item-box">
                      <div
                      v-for="(pathRouter, i) in navItem.routes"
                      :key="`${i}_${pathRouter}`"
                      @click="handleRouter(pathRouter.path)"
                      class="navs-item"
                      >
                      <!-- <i class="fa fa-chevron-right"></i> --><span>{{ pathRouter.pathName }}</span>
                      </div>
                  </div>
                </el-col>
            </div>
            </el-row>
        </div>
      </div>
      <div class="footer-copyright">
        <p class="col-12 copyright">
          COPYRIGHT © 2016-2023 <span>{{appName}}</span>. ALL RIGHTS RESERVED.
        </p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: "Footer",
    data() {
      return {
        navs: [
          {
            title: this.$t("nav5"),
            routes: [
              {
                path: "about",
                pathName: this.$t("nav6"),
              },
              {
                path: "about",
                pathName: this.$t("nav7"),
              },
              {
                path: "about",
                pathName: this.$t("nav8"),
              },
              {
                path: "about",
                pathName: this.$t("nav9"),
              },
            ],
          },
          {
            title: this.$t("nav14"),
            routes: [
              {
                path: "about",
                pathName: this.$t("nav10"),
              },
              {
                path: "about",
                pathName: this.$t("nav11"),
              },
              {
                path: "about",
                pathName: this.$t("nav12"),
              },
              {
                path: "about",
                pathName: this.$t("nav13"),
              },
            ],
          },
        ],
      };
    },
    methods: {
      handleRouter(path) {
        this.$router.push(path);
      },
    },
    computed:{
      logoUrl() {
        return window.configObj.logoUri
      },
      appName() {
        return window.configObj.appName
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  footer {
    position: relative;
    bottom: 0;
    text-align: center;
    background: #061D2C;
    //  height: 182px;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    //  padding: 10px;
    .footer-navItem {
      width: 100%;
      .navs-box {
        display: inline-flex;
        // margin: 60px 0;
      //   flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        // border-bottom: 1px solid rgba(0, 136, 255, 0.3);
        padding-bottom: 40px;
        .logo {
          //  width: 100%;
          position: relative;
          font-size: 25px;
          text-align: left;
          color: #00b5ac;
          margin: 30px 0 0px 0;
          display: flex;
          align-items: center;
          img {
            width: 134px;
            height: 51px;
          }
          h1{
            font-size: 40px;
            color: #fff;
          }
        }
        .navs-box-right {
          // display: flex;
        }
        .navs-box-item {
          text-align: left;
  
          .navs-title {
            margin: 20px auto;
            &::before {
              content: "";
              height: 2px;
              width: 33px;
              background: #fff;
              position: absolute;
              transform: translate(0, 33px);
            }
          }
          .navs-item-box {
            padding-top: 0px;
            display: flex;
            .navs-item {
              margin: 10px 0px 10px 0px;
              width: 20%;
              cursor: pointer;
              span {
                color: #fff;
              }
              i{margin-right: 8px; color: #0088ff;}
            }
          }
        }
      }
      .des-long {
        font-size: 8px;
        margin-bottom: 50px;
        color: #999;
        p {
          text-align: left;
        }
      }
      .copyright {
        margin-bottom: 50px;
        height: 80px;
        color: #999;
      }
    }
    .footer-copyright {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      background: #02141F;
      .des-long-bg {
        width: 100%;
        background: #222;
        display: flex;
        justify-content: center;
        padding: 50px 20px;
        .des-long {
          color: #999;
          max-width: 1100px;
          text-align: left;
        }
      }
      .copyright {
        line-height: 80px;
        color: #fff;
        font-size: 12px;
      }
    }
  }
  @media (max-width: 1200px) {
  footer .footer-navItem {
      padding: 0 10px; 
  .navs-box{    
      flex-wrap: wrap;
      justify-content: center;
      .navs-box-right {
        flex-direction: column;
      }
      .navs-box-item {
          margin: 10px 0;
          text-align: center;
          .navs-title {
          margin: 0 auto;
          width: 38vw;
          }
          .navs-title::before {
              display: none;
          }
          .navs-item-box {
              //  justify-content: space-between;
               justify-content: center;
              .navs-item{
                  margin: 10px 10px;
              }
          }
        }
      }
  }
    footer .footer-navItem .logo {
      margin-top: 100px;
    }
    footer .footer-copyright .copyright {
      line-height: 18px;
      padding: 30px 10px;
    }
  }
  </style>
  