import utils from '@/utils/misc'
export default {
  data() {
    return {
      riseColor: utils.getLs('colorMode') === 'colorMode2' ? '#fa4d56' : '#12b886',
      fallColor: utils.getLs('colorMode') === 'colorMode2' ? '#12b886' : '#fa4d56',
      colorType:  utils.getLs('colorMode') || 'colorMode2'
    }
  },
  computed: {
    isHft() {
      return utils.getLs('agentIdentify') === 'd42fc5f8-92c9-472f-91a0-bf5fe7c38b61'
    },
    curMediumName() {
      return this.$store.state.curCategory === 1 ? 'USDT' : 'USD'
    },
    spotList() {
      const arr = []
      for (const key in this.$store.state.spotList) {
        arr.push(this.$store.state.spotList[key])
      }
      return arr
    },
    futureList() {
      const arr = []
      for (const key in this.$store.state.futureList) {
        arr.push(this.$store.state.futureList[key])
      }
      return arr
    },
    futureMap() {
      return this.$store.state.futureList
    },
    coinPriceMap() {
      return this.$route.name==='Contract' ? this.$store.state.futureList : this.$store.state.spotList
    },
    coinMsg() {
      return this.$store.state.coins
    },
    userLogined() {
      return !!utils.getLs('token')
    },
    curCoinName() {
      return this.$store.state.curCoinName
    },
    assets() {
      return this.$store.getters.assetsMap
    },
    curAssets() {
      return this.assets.assets[this.curCate] || {}
    },
    currencies() {
      return this.$store.state.currencies
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    userCurrency() {
      return this.userInfo.valuationMethod || window.configObj.defaultCurrency 
    },
    curRate() {
      return this.currencies[this.userCurrency] ? this.currencies[this.userCurrency].rate : 1
    },
  },
  watch: {
    '$store.state.common.userChangeColor'(val) {
      this.setColorByUser()
    }
  },
  beforeMount() {
    if (localStorage.userChangeColor) {
      this.setColorByUser()
    }
  },
  methods: {
    setColorByUser() {
      this.riseColor = localStorage.userChangeColor === '1' ? '#e93f4f' : '#12b887'
      this.fallColor = localStorage.userChangeColor === '1' ? '#12b887' : '#e93f4f'
    },
    formatLeftTime(val) {
      const dateNum = (val / (24 * 60 * 60)) | 0
      const dateMod = val % (24 * 60 * 60)
      const hourNum = (dateMod / (60 * 60)) | 0
      const hourMod = dateMod % (60 * 60)
      const minuteNum = (hourMod / 60) | 0
      const minuteMod = hourMod % 60
      return `${('0'+dateNum).slice(-2)}: ${('0'+hourNum).slice(-2)}: ${('0'+minuteNum).slice(-2)}: ${('0'+minuteMod).slice(-2)}`
    },
    firstColClassMaker({ columnIndex }) {
      if (columnIndex === 0) {
        return 'text-left'
      }
    }
  }
}
