<template>
  <div>
    <Dialog @onClosed="onClosed" :size="'xlarge'">
      <div slot="head">{{ $t(1014) }}</div>
      <div slot="body">
        <div class="tabs">
          <div class="tabs-select">
            <div
              @click="handleTabs(1)"
              :class="['tabs-item', { active: tabSelect === 1 }]"
            >
              {{ $t(1015) }}
            </div>
            <div
              @click="handleTabs(2)"
              :class="['tabs-item', { active: tabSelect === 2 }]"
            >
              {{ $t(1016) }}
            </div>
            <div
              @click="handleTabs(3)"
              :class="['tabs-item', { active: tabSelect === 3 }]"
            >
              {{ $t(1017) }}
            </div>
          </div>
          <div v-loading="isLoading" class="content-nobg">
            <div class="tabs-content">
              <div class="w100 flex">
                <el-table
                  :data="tabData"
                  :key="tabSelect"
                  class="table"
                  height="400px"
                  v-el-table-infinite-scroll="load"
                >
                  <el-table-column prop="productTitle" :label="$t(73)">
                  </el-table-column>
                  <el-table-column prop="amount" :label="$t(1019)">
                  </el-table-column>
                  <el-table-column prop="ratio" :label="$t(1020)">
                  </el-table-column>
                  <el-table-column
                    prop="lastAmount"
                    :label="$t(1081)"
                    v-if="tabSelect !== 1"
                  >
                  </el-table-column>
                  <el-table-column
                    width="150"
                    prop="createTime"
                    :label="$t(1022)"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="deliveryDate"
                    :label="$t(1023)"
                    v-if="tabSelect === 2"
                  >
                    <template slot-scope="scope">
                      {{ calcDelivery(scope.row) }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="redeemTime"
                    :label="$t(1023)"
                    v-if="tabSelect === 3"
                  >
                  </el-table-column>
                  <el-table-column
                    :label="$t('operate')"
                    align="center"
                    v-if="tabSelect === 1"
                  >
                    <template slot-scope="scope">
                      <el-button
                        v-if="scope.row.canRedeem"
                        type="text"
                        class="color-primary"
                        :loading="isLoading"
                        @click="getBack(scope.row)"
                        >{{ $t(1074) }}</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!-- <div v-else class="no-data">
              <img src="../assets/img/nodata.png" alt="nodata" />
              <p>{{ $t(1018) }}</p>
            </div> -->
            </div>
          </div>
        </div>
      </div>
      <div slot="foot"></div>
    </Dialog>
    <FinancialMobileCodeDialog
      v-model="financialMobileCodeDialog"
      :orderId="orderId"
      @reload="reload"
    />
  </div>
</template>

<script>
import elTableInfiniteScroll from "el-table-infinite-scroll";
import { getOrder, financialRecords } from "@/api";
import { formatDateTime } from "@/utils";
import utils from '@/utils/misc';
import Dialog from "../components/Dialog";
import FinancialMobileCodeDialog from "./FinancialMobileCodeDialog";

export default {
  name: "Financial",
  props: ["dialogData"],
  directives: {
    "el-table-infinite-scroll": elTableInfiniteScroll,
  },
  components: { Dialog, FinancialMobileCodeDialog },
  data() {
    return {
      isLoading: false,
      noMore: {},
      financialMobileCodeDialog: false,
      tabSelect: 1,
      orderId: 0,
      tabData: [],
      queryData: {
        1: { pageSize: 10, lastId: 0 },
        2: { pageSize: 10, lastId: 0 },
        3: { pageSize: 10, lastId: 0 },
      },
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    configObj() {
      return window.configObj;
    },
  },
  methods: {
    calcDelivery(data) {
      const stamp = +new Date(data.createTime) + (data.productCircleDate + 1) * 24 * 3600 * 1000
      return utils.formatDate(stamp)
    },
    load() {
      if (this.tabData && this.tabData.length < 1) return;
      this.getData();
    },
    onClosed() {
      this.$emit("onClosed", this.isShown);
    },
    clearData(){
      // make sure fetch new data  
      this.tabData = [];
      this.noMore = {};
      this.queryData = {
        1: { pageSize: 10, lastId: 0 },
        2: { pageSize: 10, lastId: 0 },
        3: { pageSize: 10, lastId: 0 },
      };  
    },
    reload() {
      this.clearData();
      this.getData();
    },
    handleTabs(val) {
      if (val == this.tabSelect) return;
      this.tabSelect = val;
      this.clearData()
      this.getData();
    },
    getData(update) {
      if (this.tabData.length > 0 && this.noMore[this.tabSelect]) return;
      this.isLoading = true;
      getOrder(
        this.dialogData.id,
        this.tabSelect,
        this.queryData[this.tabSelect]
      )
        .then((res) => {
          if (res.list && res.list.length > 0) {
            // 少於10則
            if (res.list.length < 10) {
              this.noMore[this.tabSelect] = true;
            } else {
              // 多於10則
              this.queryData[this.tabSelect].lastId = res.list[res.list.length - 1].id;
            }
            const arr = [];
            res.list.map((item) => {
              arr.push({
                ...item,
                id: item.id,
                coin: item.coin,
                productTitle: item.productTitle,
                amount: `${item.amount}${item.coin}`,
                ratio: `${item.ratio / 10}%`,
                lastAmount: `${item.lastAmount} ${item.coin}`,
                createTime: formatDateTime(item.createTime),
                deliveryDate: item.deliveryDate,
                redeemTime: formatDateTime(item.redeemTime),
                redeemCostAmount: item.redeemCostAmount,
                canRedeem: item.canRedeem,
              });
            });
            const merge = this.tabData && this.tabData.length > 9 && arr.length > 0;
            this.tabData = merge ? this.tabData.concat(arr) : arr;
          } else {
            this.tabData = res.list;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getBack(data) {
      this.confirmWarning({
        customClass: "financial-confirmWarning-2",
        title: this.$t(1075),
        content: `${this.$t(1076)} ${data.redeemCostAmount} ${data.coin} ${this.$t(1077)}`,
      }).then(() => {
        const code = "123456"
        if (this.configObj.isOpenGlobalVerifyCode === "1" && this.configObj.isOpenRedeemPledgeVerifyCode === "1") {
          this.orderId = data.id;
          this.financialMobileCodeDialog = true;
        } else {
          financialRecords(data.id, code).then((res) => {
            this.$message.success(this.$t(1062));
          })
          .finally(() => {
              this.reload();
          })
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  .tabs-select {
    display: inline-flex;
    padding: 15px 20px 0;
    justify-content: flex-start;
    .tabs-item {
      border: 1px solid $borderColor;
      border-bottom: none;
      padding: 10px;
      border-radius: 10px 10px 0 0;
      margin-right: 10px;
      cursor: pointer;
      &.active {
        background: $primaryColor;
        color: #fff;
      }
    }
  }
  .tabs-content {
    display: flex;
    height: 450px;
    border-top: 1px solid $borderColor;
    padding: 15px 20px;
    overflow-y: auto;
    .tabs-row {
      font-size: 16px;
      padding: 20px;
      line-height: 25px;
      .tabs-row-2 {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>