<template>
    <section class="tp-banner-container" v-if="modle === '1'">
        <div class="tp-banner"  :class="{'timex': isTimeX}">
            <div class="tp-caption active animated " v-animated="{ className: 'fadeInDown' }">
            <div class="logoBox">
                <h1>{{ $t(!isHft?"homeTitle_1_1": "hftTitle") }}</h1>
                <div class="text-box">
                <h3 class="text-grey">{{ $t(!isHft?"homeTitle_1_2": "hftTitle2") }}</h3>
                </div>
                <div class="btn flex-nw">
                <a href="#" class="d-btn" @click="goToDownload">
                    <!-- <img src="@/assets/img/down_ios_active.png" alt="" /> -->
                    <span>IOS {{$t('1127')}}</span>
                </a>
                <a href="#" class="d-btn" @click="goToDownload">
                  <span>Android {{$t('1127')}}</span>
                    <!-- <img src="@/assets/img/down_android.png" alt="" /> -->
                </a>
                <a href="#" class="d-btn" @click="goToH5">
                  <span class="active">H5 {{$t('1128')}}</span>
                    <!-- <img src="@/assets/img/down_h5.png" alt="" /> -->
                </a>
                </div>
            </div>
            </div>
            <div v-if="isTimeX" class="timexBanner">
            <img src="@/assets/img/timex/banner.png" alt="">
            </div>
        </div>
    </section>
    <section class="tp-banner-container tp-banner-container-2" v-else>
        <div class="tp-banner tp-banner-2">
          <div class="tp-caption active animated " v-animated="{ className: 'fadeInDown' }">
            <div class="logoBox-2">
              <div class="left">
                <h1>Trading</h1>
                <br />
                <h1>platform</h1>
                <div class="text-box">
                  <h3>The world's leading</h3>
                </div>
              </div>
              <div class="btn">
                <img
                  src="@/assets/img/iosd.png"
                  @click="goToDownload"
                  alt="ios"
                />
                <img
                  src="@/assets/img/androidd.png"
                  @click="goToDownload"
                  alt="an"
                />
                <img src="@/assets/img/h5d.png" @click="goToH5" alt="h5" />
              </div>
            </div>
          </div>
        </div>
      </section>
</template>

<script>
import utils from "@/utils/misc"

 export default {
    props: ['modle', 'isTimeX'],
    methods: {
        goToDownload() {
            window.location.href = window.configObj.downloadUrl;
        },
        goToH5() {
            window.location.href = location.origin + "/h5/";
        }
    },
    computed: {
      isHft() {
          return utils.getLs('agentIdentify') === 'd42fc5f8-92c9-472f-91a0-bf5fe7c38b61'
      },
    },
 }   
</script>

<style lang="scss" scoped>
@media screen  and (max-width: 800px) { 
  .flex-nw {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    a {
      margin-top: 10px;
    }
  }
}  
.tp-banner-container {
  max-height: none;
//   margin: 0 auto;
  // background: url("~@/assets/img/vc3ww.png") no-repeat center center;
  background-size: 100%;
  height: auto;
//   padding-top: 100px;
//   padding-bottom: 40px;
//   background: #fff;
  .tp-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: none;
    max-width: 1140px;
    margin: 0 auto;
    // background: url("~@/assets/img/vc3ww.png") no-repeat center center;
    background-size: 100%;
    overflow: hidden;
    // position: relative;
    // border: 1px solid blue;
    // z-index: 2;
    max-height: 824px;
    &:after {
      // position: absolute;
      // content: '';
      // left: 0;
      // top: 0;
      // width: 100%;
      // height: 100%;
      // z-index: -1;
      // background: #000050;
      // opacity: .85;
    }
    &.timex{
      background: none;
        display: flex;
      .tp-caption{
          flex:3;
          padding:0 10%;
          color: #fff;
          .logoBox .text-box h3{
            color: #fff;
          }
      }
        .timexBanner{
          flex:3;
          background-size: cover;
        }
    }
    .tp-caption {
      text-align: center;
      color: #fff;
      width: 100%;
      max-width: 90vw;
      padding: 180px 0;
      .logoBox {
        text-align: left;
        max-width: 650px;
        h1 {
          font-size: 40px;
        }
        .text-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 110px;
          margin-bottom: 50px;
          h3 {
            font-size: 20px;
            font-weight: 300;
            color: #fff;
          }
        }
        .btn {
          // width: 150px;
          display: flex;
          justify-content: space-between;
          .d-btn {
            display: inline-block;
            width: 200px;
            height: 54px;
            background: linear-gradient(to right, #9B51E0, #3081ED);
            border-radius: 10px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              display: inline-block;
              width: 198px;
              height: 52px;
              background: #19123A;
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              &.active,&:hover {
                background: linear-gradient(93.51deg, #5151F1 2.84%, #3081ED 99.18%);
              }
            }
            // margin-right: 15px;
          }
          
          // cursor: pointer;
          // border: 1px solid blue;
          // margin-right: 30px;
          // display: block;
          // img {
          //   margin-right: 30px;
          // }
        }
      }
    }
  }
}
.tp-banner-container-2 {
  max-height: none;
  height: 110vh;
  .tp-banner-2 {
    background: url("~@/assets/img/banner.png") no-repeat;
    background-size: 100% 116%;
    .logoBox-2 {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .left {
        text-align: left;
        width: 50%;
        h1 {
          font-size: 85px;
        }
        .text-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 110px;
          margin-bottom: 50px;

          h3 {
            font-size: 45px;
            font-weight: 100;
            color: #fff;
          }
          &::before {
            position: absolute;
            content: "";
            transform: translate(-50px, -50px);
            width: 200px;
            height: 2px;
            border-top: 5px solid #fff;
          }
        }
        margin-right: 130px;
      }
      .btn {
        width: 20%;
        cursor: pointer;
        margin-right: 30px;
        img {
          margin: 0 30px 30px 0;
        }
      }
    }
  }
}

</style>
