<template>
  <div id="app" :class="[isv6bg ? 'v6bg' : '']" :key="selectedLang">
    <component :is="header" v-if="!$route.meta||!$route.meta.hideHeader"/>
    <router-view :lang="selectedLang"/>
    <component :is="footer" v-if="!$route.meta||!$route.meta.hideHeader"/>
    <CusDialog
      :visible="showUserNotice"
      @onClosed="doClose"
      size="xsmall"> 
      <span slot="head">{{userNotice.title}}</span>
      <div slot="body" v-html="userNotice.content"></div>
    </CusDialog>
  </div>
</template>

<script>
import Header1 from "@/components/header/Header";
import Header2 from "@/components/header/Header2";
import Header3 from "@/components/header/v3";
import Header4 from "@/components/header/v4";
import Header5 from "@/components/header/v5";
import Header6 from '@/components/header/v6';
import Header7 from '@/components/header/v7';
import Header8 from '@/components/header/v8';

import Footer1 from "@/components/footer/Footer";
import Footer2 from '@/components/footer/v2'
import Footer3 from '@/components/footer/v3'
import Footer4 from '@/components/footer/v4'
import Footer5 from '@/components/footer/v5'
import Footer6 from '@/components/footer/v6'
import Footer7 from '@/components/footer/v7'
import Footer8 from '@/components/footer/v8'
import CusDialog from '@/components/Modal.vue'
import i18n from "@/lang";
import utils from '@/utils/misc.js'
import {getConfig} from '@/api'
export default {
  name: "App",
  props: ['langselected'],
  data() {
    return {
      isv6bg: false,
      showUserNotice: false
    };
  },
  components: {
    Header1,
    Header2,
    Header3,
    Header4,
    Header5,
    Header6,
    Header7,
    Header8,
    Footer1,
    Footer2,
    Footer3,
    Footer4,
    Footer5,
    Footer6,
    Footer7,
    Footer8,
    CusDialog
  },
  computed: {
    selectedLang() {
      return i18n.locale;
    },
    modle() {
      return window.configObj.officialModel
    },
    header() {
      return `Header${this.modle}`
    },
    footer() {
      return `Footer${this.modle}`
    },
    userNotice() {
      return this.$store.state.userNotice
    }
  },
  created(){
    if (this.modle == 6) {
        if (this.$route.path == '/') {
          this.isv6bg = true;
        } else {
          this.isv6bg = false
        }
      } else {
        this.isv6bg = false;
      }
  },
  mounted(){
    document.addEventListener('visibilitychange', () => {
      if (this.$ws.ws.m_state !== 1 && this.$ws.timeConnect === 5) {
        this.$ws.timeConnect = 0
        this.$ws.limitConnect = 5
        this.$ws.connectSocket(window.configObj.wsUrl)
      }
      if(utils.getLs('token')) {
        this.$store.dispatch('getUserInfo')
        this.$store.dispatch('getUserAssets')
        getConfig().then(res => {
          window.configObj = res
        })
      }
    })
  },
  methods: {
    doClose() {
      this.showUserNotice = false
    }
  },
  beforeMount() {
    if(utils.getLs('token')) {
      this.$store.dispatch('getUserInfo')
      this.$store.dispatch('getUserAssets')
      this.$store.dispatch('getUserDialog')
    }
    this.$store.dispatch('getCoins')
    this.$store.dispatch('getAccountNames')
    this.$store.dispatch('getCurrencies')
    this.$store.dispatch('getSpotList')
    // 链接ws
    this.$ws.connectSocket(window.configObj.wsUrl)
  },
  watch: {
    $route (val) {
      if (this.modle == 6) {
        if (val.path == '/') {
          this.isv6bg = true;
        } else {
          this.isv6bg = false
        }
      } else {
        this.isv6bg = false;
      }
    },
    userNotice(val) {
      if (val && val.content) {
        this.showUserNotice = true
      } else {
        this.showUserNotice = false
      }
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/fonts/font.css";
@import "~@/assets/fonts/iconfont.css";
@import "~@/assets/css/res.scss";
</style>
