<template>
 <div class="download-area">
      <div class="v-container">
        <!-- <h2>{{ $t(1119) }}</h2>
        <p class="sub-title">{{ $t(11191) }}</p> -->
        <div class="flex box">
          <div class="flex1 flex flex-col flex-box">
            <!-- <div class="flex-box-w"> -->
            <div class="left-side">
              <h2>{{appName}}</h2>
              <p class="text">{{$t('1145')}}...</p>
            
            <div class="left-box">
              <a href="#" class="d-btn" @click="goToDownload">
                    <!-- <img src="@/assets/img/down_ios_active.png" alt="" /> -->
                    <span>IOS {{$t('1127')}}</span>
                </a>
                <a href="#" class="d-btn" @click="goToDownload">
                  <span>Android {{$t('1127')}}</span>
                    <!-- <img src="@/assets/img/down_android.png" alt="" /> -->
                </a>
                <a href="#" class="d-btn" @click="goToH5">
                  <span class="active">H5 {{$t('1128')}}</span>
                    <!-- <img src="@/assets/img/down_h5.png" alt="" /> -->
                </a>
              <!-- <a href="#" class="d-btn" @click="goToDownload">IOS Download</a>
              <a href="#" class="d-btn" @click="goToDownload">Android Download</a>
              <a href="#" class="d-btn" @click="goToH5">H5 Login</a> -->
            </div>
            <div>
                <!-- <p>{{ $t(85) }}</p> -->
                <vue-qr :size="180" :text="downloadUrl" style="width: 100px; height: 100px;"></vue-qr>
            </div>
            <img class="phone" src="./phone.png" alt="" width="261" height="545"/>
          </div>
          </div>
          <div class="flex1 imgarea">
            <ul>
              <li>
                <h3>+{{$t(1114)}}</h3>
                <p>{{$t(1115)}}</p>
              </li>
              <li>
                <h3>{{$t(1116)}}</h3>
                <p>{{$t(1117)}}</p>
              </li>
              <li>
                <h3>&lt;0.01</h3>
                <p>{{$t(1118)}}</p>
              </li>
            </ul>
          </div>
        </div>
        </div>
      <!-- </div> -->
    </div>   
</template>

<script>
import vueQr from 'vue-qr'
export default {
    components: {
        vueQr 
    },
    computed: {
        downloadUrl() {
            return window.configObj.downloadUrl
        },
        appName() {
            return window.configObj.appName
        },
    },
    methods: {
        goToDownload() {
            window.location.href = window.configObj.downloadUrl;
        },
        goToH5() {
            window.location.href = location.origin + "/h5/";
        },
    }
}    
</script>

<style lang="scss" scoped>
@media screen  and (max-width: 1000px) {
  .box {
    flex-direction: column;
    .flex-box {
      width: 100%!important;
      margin-bottom: 20px;
      padding-bottom: 20px;
      background-size: 100% 100%!important;
      .left-side {
        width: 100%!important;
      }
      .phone {
        display: none!important;
      }
    }
    .imgarea {
      ul {
        width: 100%;
        li {
          width: 100%!important;
          flex: 1;
        }
        
      }
    }
  }
    // .imgarea {
    //     display: none;
    // }
    // .flex-box {
    //     flex-direction: column;
    // }
}    
.items-center {
    align-items: center;
}    
.phone {
  position: absolute;
  right: -130px;
  top: 50%;
  margin-top: -272px;

}
.imgarea {
  display: flex;
  height: 687px;
  // justify-content: center;
  align-items: center;
  ul {
    li {
      width: 507px;
      height: 112px;
      // border: 1px solid blue;
      // border: 1px solid;
      // border-image: linear-gradient(to right, #9B51E0, #3081ED) 1;
      background: url('./btn-bg.png') no-repeat center center;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      p {
        font-size: 20px;
        color: #B6B3CD;
      }
    }
  }
}
    
.download-area{
  background: #19123A;
  padding: 60px 0;
  text-align: center;
  h2{
    margin-bottom: 40px;
    color: #fff;
    font-size: 36px;
    font-weight: 700;
  }
  .sub-title {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .part-icon{
    font-size: 45px;
    margin-bottom: 20px;
    color: #0088ff;
    .fa{
      font-weight: 900;
    }
  }
  .flex-box {
    width: 606px;
    height: 687px;
    // border: 1px solid;
    // border-image: linear-gradient(to right, #9B51E0, #3081ED) 1;
    background: url('./left-bg.png') no-repeat center center;
    background-size: contain;
    padding-top: 40px;
    // border-radius: 10px;
    // background: linear-gradient(to right, #9B51E0, #3081ED);
    position: relative;
    z-index: 2;
    border-radius: 15px;
    // clip-path: inset(0 round 15px);
    // overflow: hidden;
    // &::after {
    //   content: '';
    //   display: block;
    //   box-sizing: border-box;
    //   width: 608px;
    //   height: 689px;
    //   // border: 1px solid;
    //   // background: #19123A;
    //   background: linear-gradient(to right, #9B51E0, #3081ED);
    //   // border-image: linear-gradient(to right, #9B51E0, #3081ED) 1;
    //   position: absolute;
    //   z-index: 0;
    //   left: -1px;
    //   top: -1px;
    //   z-index: 0;
    //   border-radius: 10px;
    //   overflow: hidden;
    // }
    .left-side {
      width: 50%;
      h2 {
        font-size: 30px;
        text-align: center;
        padding-top: 30px;
        margin-bottom: 10px;
      }
      .text {
        padding: 10px;
        font-size: 18px;
        color: #B6B3CD;
      }
    }
    .left-box {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .d-btn {
            display: inline-block;
            width: 200px;
            height: 54px;
            background: linear-gradient(to right, #9B51E0, #3081ED);
            border-radius: 10px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              display: inline-block;
              width: 198px;
              height: 52px;
              background: #19123A;
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              &.active,&:hover {
                background: linear-gradient(93.51deg, #5151F1 2.84%, #3081ED 99.18%);
              }
            }
            // margin-right: 15px;
          }
    }
  }
  .single {
    text-align: center;
    padding: 40px 30px;
    margin-bottom: 30px;
    transition: all 0.3s ease-in;
    box-shadow: 0px 0px 9px 2px #f5f5f5;
    color: #666;
    font-size: 16px;
    min-height: 300px;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    h3{
      color: #000050;
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 600;
    }
    p{
      line-height: 24px;
    }
    &:hover{
      box-shadow: 0px 0px 19px 12px #f5f5f5;
    }
  }
    p{
        font-size: 16px;
        // margin-bottom: 40px;
    }
    .d-btn{
        display: block;
        // width: 214px;
        // height: 58px;
        margin: 20px auto !important;
    }
}
</style>