import jaLocale from 'element-ui/lib/locale/lang/ja'
export default {
  ...jaLocale,
  1127: 'ダウンロード',
  1128: 'ログイン',
  1130: '通貨',
  1131: '価格',
  1132: 'チェンジ',
  1133: 'グラフ',
  1134: '詳細',
  1135: '仮想通貨ポートフォリオを今すぐ作成',
  1136: '{appName} には、取引を開始するのに最適な場所となる複数の機能があります',
  1137: 'ポートフォリオの管理',
  1138: '人気のあるデジタル通貨を売買し、それらすべてを 1 か所で追跡する',
  1139: '定期購入',
  1140: '毎日、毎週、または毎月の購入を手配して、暗号通貨にゆっくりと投資する',
  1141: 'ヴォールト保護',
  1142: 'セキュリティを強化するために、資金を金庫に保管し、出金を遅らせてください',
  1143: 'モバイルアプリ',
  1144: 'Android または iOS 用の {appName} アプリで市場のトップを維持',
  1145: 'エキゾチックなペアでのレバレッジ取引は素晴らしいですが、ミームとアルファでいっぱいの Discord サーバーがあればさらに良いでしょう',
  1146: 'お問い合わせ',
  11081: 'Elite Trading Platform: 取引を高速、安定、効率的に維持',

    11031: '複数の PC およびモバイル オペレーティング システムをサポート',
    1126: '取引プラットフォーム',
    11261: '最も人気のある外国為替取引プラットフォーム',
    11191: 'いつでもどこでもトランザクションを開く',
    nav0: 'ホームページ',
    nav1: '引用符',
    nav2: 'コイン取引',
    nav3: '契約トランザクション',
    nav4: '財務管理',
    nav5: '用語の説明',
    nav6: 'ユーザー契約',
    nav7: 'プライバシーポリシー',
    nav8: '法的声明',
    nav9: '取引仕様',
    nav10: '初心者ヘルプ',
    nav11: 'FAQ',
    nav12: '取引手数料率',
    nav13: '私たちについて',
    nav14: 'サービス',
    operate: '操作',
    homeTitle_1_1: '世界をリードするデジタル資産取引プラットフォーム',
    homeTitle_1_2: '最も人気のあるデジタル資産取引プラットフォーム,市場で最も強力で便利なテクニカル分析および取引ツールへのアクセス。 ',
    register: '今すぐ登録',
    nowtrans: 'すぐに取引',
    logIn: 'ログイン',
    whats: 'WhatsApp',
    service: 'サービス',
    home_1_2: 'キャロッド証券はあなたの信頼できる外国為替です',
    home_1_3: '貴金属,CFDプラットフォーム',
    home_2_1: '顧客の資金はグローバルAAレベルの銀行分離信託基金に安全に保管されています',
    home_2_2: 'スプレッドが低く,サーバーがより安定している',
    footer1: 'リスク警告:会社が提供する金融商品には,差金決済取引（\'CFD`）やその他の複雑な金融商品が含まれます。レバレッジはあなたにとって良い面と悪い面の両方を同時に持つ可能性があるため,CFDの取引には高レベルのリスクが伴います。したがって,CFDはすべての投資元本を失う可能性があるため,すべての投資家に適しているとは限りません。失うわけにはいかないお金を投資してはいけません。提供されている複雑な金融商品を取引する前に,関連するリスクを必ず理解してください。お客様には,このWebサイトで提供されるサービスに関連する個人的および非営利目的でのみ,このWebサイトで提供されるIPを使用する限定的で非独占的かつ譲渡不可能な権利が付与されます。',
    footer2: 'このウェブサイトの情報は,ウェブサイトに記載されている法律や規制とは異なる国や法域の居住者を対象としていないことに注意してください。CarrodSecuritiesCo Ltdはファイナンシャルアドバイザーではなく,一般的なアドバイスのみを提供します。 。マージン取引の取得,保有,または売却に関して意見や提案を発行することはありません',
    1: '製品名',
    2: '予想される1日の収益率',
    3: '製品の締め切り',
    4: '開始投資額',
    5: '操作',
    6: '購入',
    7: '残りの通貨管理',
    8: '柔軟な財務管理,毎日の利益',
    9: '私たちについて',
    10: 'ユーザー契約',
    11: 'プライバシーポリシー',
    12: '法的声明',
    13: '取引ルール',
    14: '用語の説明',
    15: 'サービス',
    16: '初心者ヘルプ',
    17: 'FAQ',
    18: '取引手数料率',
    19: 'デジタル通貨の第2契約取引システム',
    20: '高速,便利,配分なし,リスク制御可能',
    21: 'システム紹介',
    22: 'デジタル通貨先物の急速な発展に伴い,ユーザーの大多数はますます多くの資産を保有し,仮想通貨の取引方法も多様化しています。ユーザーはますます迅速かつ効率的な取引決済方法を追求しており,このため,2番目の契約も高く評価されています。ミューチュアルクラウドが立ち上げた2つ目の契約は,仮想通貨契約をセント単位で決済するための取引システムです。このシステムは,市場価格取引と制限価格取引をサポートしています。ユーザーは,1分,2分,3分などに分割された,少ない設備投資,低いしきい値,短い決済サイクルで,コールアンドプットオプションを購入できます。個別に構成できます。システムには厳格なリスク管理システムがあり,変動の最大値と最小値が設定されています。制限を超えると,オッズは自動的にゼロにリセットされます。 ',
    23: 'Kラインソース',
    24: '取引は主に取引のための外部取引所の市場相場に依存します',
    25: 'サポート通貨',
    251: '入金方法',
    252: 'コインを預ける',
    26: 'ウォレットアドレス経由で再充電',
    27: 'C2Cトランザクションによる購入',
    28: 'コインを発行するためのプラットフォームのニーズを満たす',
    281: 'OTC取引による購入',
    282: 'プラットフォームは通貨変動のリスクを負う必要はありません',
    29: '契約モデル設計',
    30: '取引方法',
    31: '決済時間設定',
    32: '貿易の方向性',
    33: 'トランザクション制御',
    34: 'リスク管理',
    35: '購入制限',
    36: '顧客からの大規模な損失を防ぐ',
    38: '注文の期限を設定する',
    39: 'ドローのための十分な清算時間を確保する',
    40: 'アップラインとダウンラインを設定する',
    41: '一方的な取引を防ぐためにオッズをゼロにトリガーする',
    42: '大規模な損失を回避する',
    43: 'ワンクリック閉じるボタン',
    44: '顧客はボタンをクリックして極端な状況でポジションを閉じることができます',
    46: 'サービス監視',
    47: 'サービスの障害へのタイムリーな応答を保証し,時間内に障害の電子メール通知を開始するためのサービスの包括的な監視',
    48: 'サービスサポート',
    49: '私たちは心を込めてお客様にアプローチします。熱心で,気配りがあり,忍耐強く,注意深く取り組んでいます。専門のソフトウェアR＆Dチーム,厳格なプラットフォームの監視と管理,専門のワンストップサービスチームがあります。 ',
    50: '1つ,取引ペアを選択',
    51: '2つ,価格を確認してください',
    52: '市場取引価格を見て,注文するのに適切な価格を選択します。 ',
    53: '3つ,注文を記入してから購入する',
    54: '購入する必要のあるトランザクションペアを選択します（通貨間トランザクションとは,あるデジタル資産を使用して別のデジタル資産と交換することを指します）',
    55: '購入価格と購入量を入力し,市場取引を待ちます。 ',
    57: '数秒で通貨取引を理解する',
    58: '安全で信頼性の高い,高速なトランザクション',
    59: '取引相場',
    60: '在庫あり',
    601: '高度なデジタル資産,CFD,商品取引プラットフォームを世界の投資家に提供する',
    61: 'スポットは何ですか？ ',
    62: 'スポットは先物用です。スポットと先物は似ていますが,主な違いはレバレッジとマージン,取引の納期などの違いにあります',
    63: '先物',
    64: '先物とは何ですか？',
    65: '先物と呼ばれる先物契約は,時間にまたがる取引方法です。契約に署名することにより,買い手と売り手は,指定された時間,価格,およびその他の取引条件に従って,指定された量のスポットを提供することに同意します。 ',
    66: '信頼できる財務管理',
    67: '信託融資とは',
    68: '分散型金融は一種のブロックチェーンベースの金融です。金融ツールを提供するためにブローカー,取引所,銀行などの金融機関に依存しませんが,金融活動を行うためにブロックチェーン上のスマートコントラクトを使用します',
    69: 'C2C',
    70: 'C2Cとは',
    71: 'C2Cは\'プライベートからプライベート`とも呼ばれ,トランザクションの形式であり,個人から個人へのトランザクションの形式を指します',
    72: 'ワンストップトレーディングサービス',
    73: '名前',
    74: '価格',
    75: '24Hの浮き沈み',
    76: '24Hボリューム',
    77: '引用',
    78: '安​​定した安全な集約',
    79: '金融グレードのセキュリティ保護',
    80: 'ユーザーのプライバシー,複数の署名,階層化されたアーキテクチャ,およびその他のセキュリティ設計を保護するための偏りのないゼロ知識証明プライバシーおよび機密性テクノロジー。 ',
    81: '高性能マッチングエンジン',
    82: '高度な分散クラスターアーキテクチャとマイクロサービス開発手法を採用します。 1秒あたり300万を超えるトランザクションを同時にサポートします。 ',
    83: '24時間中断のないサービス',
    84: 'カスタマーサービスは24時間年中無休でオンラインで,さまざまな質問に答えます。 ',
    85: 'いつでもどこでもトランザクションを開く',
    86: 'いつでもさまざまなシナリオの取引ニーズを満たすための真新しい改訂',
    87: '24 * 7時間の全天候型,フルタイムの操作モード。カスタマーサービスが関連するお問い合わせにできるだけ早く回答します。 ',
    88: 'トレーディングペア',
    89: '最新価格',
    90: '変化する',
    91: '最高価格',
    92: '最低価格',
    93: 'ボリューム',
    94: 'ログイン',
    95: '登録',
    fee: {
        1: '取引手数料率',
        2: '通貨取引手数料',
        3: '契約手数料'
    },
    law: {
        1: '法的声明',
        2: '最初の記事',
        3: 'このプラットフォームは,ユーザー向けの準拠した安全で信頼性の高いデジタル資産取引環境の構築に取り組んでおり,ユーザーがこのWebサイトを使用してマネーロンダリング,密輸,商業賄賂,悪意のある相場操縦,その他の違法な取引活動または違法な取引を行うことを禁止しています。活動。この目的のために,私たちは厳格なマネーロンダリング防止およびテロ対策プログラムを策定し,効果的なセキュリティリスク管理措置を講じて,ユーザーの正当な権利と利益の保護を最大化し,プラットフォームでのブランドの評判を維持するために最善を尽くします自体',
        4: '2番目の記事',
        5: '管轄当局が対応する調査文書を作成し,指定されたユーザーの調査に協力するようにこのWebサイトに要求する場合,またはユーザーアカウントの封印,凍結,転送などの措置を講じる場合,このWebサイトは,性別後の司法文書の有効性と信頼性を検証します。 ,対応するユーザーデータの提供または対応する操作の実行を支援するためにプラットフォームによって決定された所管官庁の要件に従って,アカウントの操作不能によって引き起こされるユーザープライバシーの漏えいおよびユーザーに引き起こされるその他の損失など。このウェブサイト私たちは一切の責任を負いません。 ',
        6: '第3条',
        7: 'このウェブサイトのサービスを使用するすべてのユーザーは,取引する前に知っておく必要があります。デジタル資産は政府や中央銀行の支援を受けておらず,デジタル資産の価格は大きく変動します。デジタル資産を保有または使用するリスクは高くなります。取引の決定を行う前に,あなたはそれがあなたに適しているかどうかを判断するためにあなた自身の財政状況について合理的かつ慎重でなければなりません',
        8: '第4条',
        9: 'このウェブサイトに何らかの方法で,または直接的または間接的にこのウェブサイトのサービスを使用する人は誰でも,このウェブサイトの声明の制約を自発的に受け入れるものとみなされます。 '
    },
    rules: {
        1: '取引ルール',
        2: '注文を作成した後,購入者は次のことを行う必要があります:',
        3: '1,注文で指定された支払い時間内に支払いを完了し,[支払い済み]をクリックします;',
        4: '2,私の本名の支払い方法を使用して,注文金額と注文で指定された支払いアカウントに従って,リアルタイムで注文の支払いを完了します;',
        5: '3,注文の支払いに非リアルタイムの支払い方法を選択した場合は,事前に販売者の同意を得る必要があります',
        6: '4, "デジタル通貨,BTC,ビットコイン"およびその他の情報を支払い注文に記載してはなりません;',
        7: '売り手が従うべき原則',
        8: '注文が生成された後,売り手はすべきです',
        9: '1,プラットフォームによって認定された受信アカウントを提供します;',
        10: '2,購入者の全額の支払いを受け取った後,注文で指定された時間内にリリースを完了します;',
        11: '3,購入者の同意なしに,購入者/カスタマーサービスは注文をキャンセルする必要はありません;',
        12: '4,購入者の同意なしに,作成された注文価格に異議はありません。'
    },
    help: {
        1: '初心者ヘルプ',
        2: '指値注文',
        3: '指定された価格以上で売買する注文。 ',
        4: '成行注文',

        5: '現在の市場価格ですぐに売買する注文。 ',
        6: '指値ストップロス注文',
        7: '市場が損失を管理するために特定の価格に達した後,設定された価格でポジションを売却またはクローズする注文。 ',
        8: '制限価格テイクプロフィットオーダー',
        9: 'ストップリミット注文と同様に,市場が特定の価格に達した後,設定された価格でポジションを売却またはクローズする注文でもあり,これは利益を管理するために使用されます。 ',
        10: 'マーケットストップロス注文',
        11: '損失を管理するために,市場が特定の価格に達した後,市場価格でポジションを売却またはクローズする注文。 ',
        12: '市場価格は利益を注文する',
        13: '市場ストップロス注文と同様に,市場が特定の価格に達した後,市場価格でポジションを売却またはクローズする注文でもあり,これは利益を管理するために使用されます。 ',
        14: 'ポジションを開くためのマージンはどれくらいですか？ ',
        15: '証拠金取引とは,契約を売買するために一定の割合の証拠金を支払うだけでよいことを意味します。選択したレバレッジによると,証拠金比率は契約額の小さい倍数です。それはあなたの利益を拡大することができますが,それはまたあなたを追加のリスクにさらすかもしれません。 ',
        16: '清算とは何ですか？パンクとは何ですか？ ',
        17: 'あなたの口座の残りの資金が取引契約によって要求される変動損益より少ないとき,あなたのポジションは清算としても知られている取引所によって強制的に閉じられます。ポジションがクローズされたときの価格は市場価格に基づいて決定されるため,取引価格が非常に不利な場合,取引の損失を補うのに十分な資金がなく,アカウントにマイナスの値が生じる可能性があります。この状況はポジションショートと呼ばれます。 '
    },
    96: 'トレーディングペアを選択',
    97: '価格を確認',
    98: '注文を記入してから購入',
    99: 'アカウントログイン',
    100: 'おかえりなさい！メールアドレスまたは電話番号でログインしてください',
    101: 'メールボックス',
    102: 'モバイル',
    103: 'パスワードを忘れましたか？ ',
    104: '今すぐ登録',
    105: 'パスワード',
    106: '電話番号を入力してください',
    107: '携帯電話番号のフォーマットエラー',
    108: 'メールアドレスを入力してください',
    109: 'メールボックス形式エラー',
    110: 'パスワードを入力してください',
    111: 'パスワードは6文字以上です',
    112: 'パスワードは最大32ビットです',
    113: '言語と地域',
    114: '{appName}アカウントを作成',
    115: '登録するにはメールアドレスまたは携帯電話番号を使用してください',
    116: '推奨コード',
    '116x': 'エリア識別子',
    117: '同意する',
    118: 'and',
    119: 'ユーザー契約',
    120: 'プライバシーポリシー',
    121: '登録しましたか？ ',
    122: '確認コード',
    123: '送信',
    124: '確認コードを入力してください',
    125: '確認コードは6桁である必要があります',
    126: 'パスワードをリセット',
    127: 'OK',
    128: '電子メールまたは携帯電話を使用してログインパスワードをリセットしてください',
    129: '実名認証',
    130: '実名認証を完了すると,アカウントのセキュリティを保護し,引き出し制限とトランザクション権限を増やすことができます',
    131: '認証に移動',
    132: '認証',
    133: 'ログイン,コインの引き出し,パスワードの取得,セキュリティ設定の変更に使用',
    134: '確認済み',
    135: 'パスワード管理',
    136: 'ログインパスワード',
    137: '資金パスワード',
    138: 'アカウントのセキュリティを保護するために使用',
    139: '資金の安全を保護するために使用される',
    140: '個人設定',
    141: '色の好み',
    142: '設定',
    143: '変更',
    144: 'ローソク足チャートを設定し,色を買い取り,買い取り',
    145: '価格設定方法',
    146: '勘定資産評価単位',
    147: 'デフォルト',
    148: '緑が上がり,赤が下がる',
    149: '赤を上に,緑を下に',
    150: 'ログインパスワードの変更',
    151: 'ファンドパスワードの変更',
    152: '古いパスワード',
    153: '新しいパスワード',
    154: '古いパスワードを入力してください',
    155: '新しい電話番号',
    156: '新しいメールボックス',
    157: '携帯電話番号をバインドする',
    158: 'メールボックスをバインド',
    159: '携帯電話番号を変更する',
    160: 'メールボックスの変更',
    161: '認証済み',
    162: 'ニックネームを設定する',
    163: 'ニックネームを入力してください',
    164: 'キャンセル',
    165: '国',
    166: '名',
    167: '姓',
    168: '証明書タイプ',
    169: '証明書番号',
    170: '国を選択してください',
    171: '名を入力してください',
    172: '名前を入力してください',
    173: 'ID番号を入力してください',
    174: '身分証明書の正面写真をアップロードしてください',
    175: 'IDの裏側の写真をアップロードしてください',
    176: '携帯IDの写真をアップロードしてください',
    177: '送信',
    178: '認証',
    179: 'ID',
    180: 'パスポート',
    181: '次へ',
    182: 'ID写真のアップロードと完全な認証',
    183: 'アップロードに失敗しました',
    184: '画像フォーマットエラー',
    185: '画像が大きすぎます',
    186: '読み込み中',
    187: '資産の概要',
    188: '転送',
    189: '撤退',
    190: 'コインを預ける',
    191: '財務記録',
    192: 'フリーズ',
    193: '利用可能',
    194: '合計',
    195: '小さな通貨を隠す',
    196: '通貨',
    197: 'USDT評価',
    198: '検索',
    199: 'リチャージネットワーク',
    200: 'デポジットアドレス',
    201: 'コインアウトアドレス',
    202: 'リチャージ量',
    203: 'コインの預け入れのスクリーンショット',
    204: '備考',
    205: 'アドレスのコピー',
    206: 'コピー成功',
    207: '戻る',
    208: 'オプション',
    209: 'リチャージするコインの数を入力してください',
    210: '有効な番号をお願いします',
    211: '通貨アドレスを入力してください',
    212: 'コインデポジットのスクリーンショットをアップロードしてください',
    213: '引き出しアドレス',
    214: '引き出し額',
    215: '利用可能な残高',
    216: 'すべて',
    217: '手数料',
    218: '引き出したコインの量を入力してください',
    219: 'ファンドのパスワードを入力してください',
    220: 'ファンドのパスワードは6桁である必要があります',
    221: 'デフォルトのパスワード',
    222: '対応するウォレットをまだバインドしていません！ ',
    223: '追加する',
    224: '送信に成功しました',
    225: 'コレクションウォレットを追加',
    226: 'アドレス',
    227: 'ネットワークに入ってください',
    228: '住所を入力してください',
    229: 'ネットワーク',
    230: '撤退ネットワーク',
    231: 'アドレス長は下位32ビットです',
    232: '送金',
    233: '送金金額',
    234: '振込金額を入力してください',
    235: 'バランスが不十分',
    236: '転送可能',
    237: 'スワイプ方向',
    238: '転送',
    239: 'From',
    240: 'to',
    241: '財務記録',
    242: 'コイン預金記録',
    243: '撤退記録',
    244: 'レコードの転送',
    245: '数量',
    246: 'ステータス',
    247: '作成された時間',
    248: '時間を確認する',
    249: 'これ以上',
    250: '価格',
    253: 'トレーディングペア',
    254: '変化する',
    255: 'まだデー​​タがありません',
    256: '累積',
    257: '高',
    258: '低',
    259: '出来高',
    260: 'オープン',
    261: '受け取る',
    262: '金額',
    263: '先物取引',
    264: '強気',
    265: '弱気',
    266: '製品名',
    267: '契約を表示',
    268: '購入金額',
    269: '購入金額を入力してください',
    270: '取引手数料',
    271: '最小購入',
    272: 'ステータス',
    273: '保留中の配達',
    274: '配信済み',
    275: '注文は正常に行われました',
    276: '製品名',
    277: '損益',
    278: '購入価格',
    279: '配達価格',
    280: '取引を続ける',
    283: '利益',
    284: '注文の確認',
    285: '履歴注文',
    286: '保留中の注文',
    287: '作成された時間',
    288: '購入',
    289: '売る',
    290: 'トランザクション数',
    291: '取引金額',
    292: '契約',
    293: '配達時間',
    294: '配達価格',
    295: '秒',
    296: '分',
    297: '時間',
    298: '日',
    299: '残り時間',
    300: 'もっと見る',
    301: '通貨取引',
    302: '販売額',
    303: '販売額を入力してください',
    304: '法定通貨取引',
    305: 'アセット',
    306: 'ログアウト',
    307: '正常に登録されました',
    308: '推奨事項を入力してください',
    '308x': '地域識別コードを入力してください',
    309: '成功',
    310: '失敗',
    311: '確認中',
    312: '支払い方法',
    313: '引き出しアドレス',
    314: '削除',
    315: '操作',
    316: 'あなたの財布',
    317: '本当に削除しますか？ ',
    318: '正常に削除',
    319: '認証に失敗しました',
    320: '再認証',
    330: 'デジタル資産引き出しウォレット',
    331: '銀行カードをバインドし,現金を法定通貨に引き出す',
    332: '有効なアドレスを入力してください',
    333: '仮想通貨',
    334: '外国為替',
    335: '貴金属',
    1000: `日`,
    1001: `進行中`,
    1002: 'クローズ',
    1003: `ルール`,
    1004: `製品の利点`,
    1005: 'あなたが取るように保存',
    1006: '配当の期限が切れました',
    1007: `デイリーボーナス`,
    1008: `ライブ預金利息`,
    1009: `100％資本保証`,
    1010: `休暇中の通常の収入`,
    1011: `翌日休む`,
    1012: `製品の利点`,
    1013: '会員はプラットフォームに200000 USDTを保存し、選択周期は14日、日利潤は0.5%の財テク製品を保存し、毎日の収益は以下の通り：200000*0.5%=1000 USDTすなわち：14日後に14000 USDTの収益を得ることができ、収益は毎日発行され、元金を保存し、満期になると、自動的にあなたの口座に返却されます。',
    1014: '監護命令',
    1015: `ホスティング`,
    1016: `完了`,
    1017: `償還`,
    1018: "まだデー​​タがありません",
    1019: "購入金額",
    1020: `1日の収益率`,
    1021: 'TRUST.6',
    1022: `支払い時間`,
    1023: `償還日`,
    1024: '期限切れ',
    1025: '最近（日収）',
    1026: '配当時間',
    1027: `毎日`,
    1028: '保管額',
    1029: '有効期限に戻る',
    1030: `利用可能な残高`,
    1031: `推定総所得`,
    1032: '送金',
    1033: `すべて`,
    1034: 'シングルリミット',
    1035: `手数料`,
    1036: `私は読んで同意しました`,
    1037: `プラットフォーム財務管理サービス契約`,
    1038: `OK`,
    1039: `購入`,
    1040: `購入`,
    1041: `販売`,
    1042: `電信送金`,
    1043: '金額を入力してください',
    1044: '法定通貨カテゴリ',
    1045: `販売数量`,
    1046: `販売可能な最大数`,
    1047: `すべて`,
    1048: '参照単価',
    1049: `お支払い方法`,
    1050: '銀行名',
    1051: `銀行口座番号`,
    1052: '名前',
    1053: '対応する支払い方法をバインドしていないため,販売できません。 ',
    1054: '注文記録',
    1055: 'フィルター',
    1056: 'タイプ',
    1057: 'ステータス',
    1058: `処理中`,
    1059: `合格`,
    1060: `失敗`,
    1061: `数量`,
    1062: `成功`,
    1063: 'タスクの実行',
    1064: `今日の結果を計算する`,
    1065: `累積所得`,
    1066: '拘留中の注文',
    1067: `信頼アカウントが開設されていません`,
    1068: '通信信託口座を開設し,安定した受動的収入を享受し,資産の評価を容易にします。 ',
    1069: 'アクティベーションを申請するということは,それを読んで同意したことを意味します',
    1070: '"プラットフォーム財務管理サービス契約"',
    1071: `アクティベーションを申請する`,
    1072: 'キャンセル',
    1073: '実行中...',
    1074: `償還`,
    1075: 'それを利用してもよろしいですか？ ',
    1076: '早期償還には、清算された損害賠償の支払いが必要です。これは、今回は財務管理の種類に応じて差し引かれます',
    1077: ' ,償還は確認されていますか？',
    1078: '送信済み',
    1079: '確認コードを入力してください',
    1080: `プロンプト`,
    1081: `利益`,
    1082: `時間`,
    1083: '法定通貨の支払い方法を追加',
    1084: '注文ステータス',
    1085: `通貨`,
    1086: `法定通貨の数`,
    1087: `銀行口座番号`,
    1088: "ファットマネー収集方法",
    1089: `収集メソッドの追加`,
    1090: `既存のお支払い方法`,
    1091: `法定通貨`,
    1092: `銀行コード`,
    1093: 'お支払い方法を削除してもよろしいですか？ ',
    1094: `銀行口座番号を入力してください`,
    1095: `銀行名を入力してください`,
    1096: `法定通貨を入力してください`,
    1097: `選択してください`,
    1098: `名前を入力してください`,
    1099: "ブランチ",
    1100: "無制限",
    1101: "あなたはまだあなたの本名を確認していません",
    1102: 'ホワイトペーパー',
    1103: '私たちの機能',
    1104: '私たちは安全です',
    1105: '私たちは強力です',
    1106: 'ベストサポート',
    1107: '私たちはグローバルです',
    1108: '仕組み',
    1109: '20を超える国と地域のユーザーが当社のプラットフォームを使用しており、広く認識されています',
    1110: '高度なデータ暗号化',
    1111: '豊富な種類の暗号通貨',
    1112: '90以上の暗号通貨がリストされています',
    1113: 'トランザクションデータはエンドツーエンドの暗号化によって保護され、あなただけがあなたの個人情報にアクセスできるようになります。',
    1114: '430億',
    1115: '24時間取引量',
    1116: '3000万',
    1117: '私たちを信頼する登録ユーザー',
    1118: '最低取引手数料',
    1119: 'ダウンロー',
    1120: '金融機関名',
    1121: '支店名',
    1122: '支店番号',
    1123: '口座番号',
    1124: '受取人名',
    1125: '金额',

    1148: '最も信頼できる暗号通貨プラットフォーム',
    1149: '当社が選ばれる理由はここにあります',
    1150: '私たちは安全です',
    1151: 'ユーザーのプライバシー、複数の署名、階層化されたアーキテクチャ、およびその他のセキュリティ設計を保護するための公平なゼロ知識証明プライバシーおよび機密保持テクノロジ',
    1152: '最高のサポート',
    1153: '24*7 時間全天候型、フルタイムの操作モード、カスタマー サービスは、関連するお問い合わせにできるだけ早く回答します',
    1154: '私たちはグローバルです',
    1155: '20を超える国と地域のユーザーが当社のプラットフォームを使用しており、広く認知されています ',
    1156: '私たちはパワフルです',
    1157: '最も人気のあるデジタル資産取引プラットフォーム、市場で最も強力で便利なテクニカル分析および取引ツールへのアクセス',
    1158: '豊富な種類の暗号通貨',
    1159: '90以上の暗号通貨がリストされています',
    1160: '高度なデータ暗号化',
    1161: 'あなたの取引データはエンドツーエンドの暗号化によって保護され、あなただけがあなたの個人情報にアクセスできるようになります. ',
    1162: 'さまざまな最も人気のあるデジタル通貨をサポートしています',
    1163: '多くの国がローカライズされた取引を確立しています',
    1164: '法定通貨を使用してデジタル資産を購入し、すぐにデジタル資産の世界に入る',
    1165: 'トレードオンでいつでもどこでも',
    1166: 'スキャンしてダウンロード',
    1167: '為替レート',
    1168: '最近アップロードされた為替レートで 250 以上の暗号通貨を売買',
    1169: 'アプリとデスクトップ クライアントで最新情報を入手',

    1170: '名前',
    1171: '価格',
    1172: '変化する',
    1173: 'チャート',
    1174: 'トレード',
    1175: '詳細',
    1176: 'そうだった',
    1177: '安全',
    1178: '我々は持っています',
    1179: 'グローバル',
    1180: 'パワフル',
    1181: '豊富な種類',
    1182: '暗号通貨',
    1183: '高度なデータ」',
    1184: '暗号化',
    1185: '始める準備ができました',
    1186: '私たちは世界をリードするデジタル資産取引プラットフォームです。 ',
    1187: '私たちは世界をリードするデジタル資産取引プラットフォームです。 当社は、お客様のデジタル資産のセキュリティを確保するために、セキュリティ機能の改善に常に努めています。 私たちのモデルは、お客様のプロジェクトに持続可能な価値をもたらすことです。',

    1188: '総供給量',
    1189: '24時間365日の全天候型、フルタイムの操作モード、カスタマーサービスは、関連するお問い合わせにできるだけ早く回答します',
    1190: '私たちはグローバルです',
    1191: '私たちは強い',
    1192: '最も人気のあるデジタル資産取引プラットフォームであり、市場で最も強力で便利なテクニカル分析と取引ツールを手に入れましょう。',
    1193: '多種多様な暗号通貨',
    1194: 'あなたの取引データはエンドツーエンドの暗号化で保護されており、あなただけがあなたの個人情報にアクセスできるようになっています。',
    1195: '最低取引手数料',
    1196: '登録ユーザーを信頼する',
    1197: '24時間取引量',
    1198: '+420億',
    1199: '+3,000万',
    1200: 'いくつかの国では、複数のビジネス形態を統合するブロックチェーン エコシステムを作成するために、ローカライズされたトランザクション サービス センターを設置しています。',
    1201: '法定通貨を使用してデジタル資産を購入し、すぐにデジタル資産の世界に入る',
    1202: 'アプリのダウンロード',
    1203: '私たちの利点',
    1204: '豊富な通貨および通貨情報、通貨、契約、法定通貨取引およびその他の取引方法、専門的な市場と深さを提供します。'
}