import frLocale from 'element-ui/lib/locale/lang/fr'
export default {
  ...frLocale,
  1127: 'télécharger',
  1128: 'Connexion',
  1130: 'Monnaie',
  1131: 'prix',
  1132: 'Modifier',
  1133: 'Graphique',
  1134: 'Détails',
  1135: 'Créez votre portefeuille de cryptomonnaies maintenant',
  1136: '{appName} possède plusieurs fonctionnalités qui en font le meilleur endroit pour commencer à trader',
  1137: 'Gérer votre portefeuille',
  1138: "Achetez et vendez des devises numériques populaires et suivez-les toutes au même endroit",
  1139: 'Achats récurrents',
  1140: 'Investissez lentement dans les crypto-monnaies en organisant des achats quotidiens, hebdomadaires ou mensuels',
  1141: 'Protection du coffre-fort',
  1142: 'Pour plus de sécurité, conservez vos fonds dans un coffre-fort et retardez les retraits',
  1143: 'Application mobile',
  1144: 'Restez au top du marché avec l\'application {appName} pour Android ou iOS',
  1145: "Bien que le trading à effet de levier sur des paires exotiques soit formidable, il serait encore mieux d'avoir un serveur Discord plein de mèmes et d'alpha",
  1146: 'Contactez-nous',

    11081: 'Elite Trading Platform: Keep Trading fast, stable and efficient',
    11031: 'Prend en charge plusieurs systèmes d\'exploitation PC et mobiles',
    1126: 'Plateforme de négociation',
    11261: 'Plateforme de trading Forex la plus populaire',
    11191: 'Ouvrir des transactions à tout moment, n\'importe où',
    nav0: 'Page d\'accueil',
    nav1: 'citations',
    nav2: 'transaction de pièces',
    nav3: 'Transaction de contrat',
    nav4: 'gestion financière',
    nav5: 'Description des termes',
    nav6: 'Contrat d\'utilisation',
    nav7: 'Politique de confidentialité',
    nav8: 'Déclaration légale',
    nav9: 'Spécifications commerciales',
    nav10: 'Aide novice',
    nav11: 'FAQ',
    nav12: 'Taux des frais de transaction',
    nav13: 'À propos de nous',
    nav14: 'Service',
    operate: 'Opération',
    homeTitle_1_1: 'La première plateforme de négociation d\'actifs numériques au monde',
    homeTitle_1_2: 'La plate-forme de négociation d\'actifs numériques la plus populaire, l\'accès aux outils d\'analyse technique et de négociation les plus puissants et les plus pratiques du marché. ',
    register: 'Inscrivez-vous maintenant',
    nowtrans: 'Transaction immédiate',
    logIn: 'Connexion',
    whats: 'WhatsApp',
    service: 'Service client',
    home_1_2: 'Carrod Securities est votre change de confiance',
    home_1_3: 'Métaux précieux, plateforme CFD',
    home_2_1: 'Les fonds des clients sont stockés en toute sécurité dans le fonds fiduciaire mondial ségrégué des banques de niveau AA',
    home_2_2: 'Des spreads plus bas et un serveur plus stable',
    footer1: 'Avertissement sur les risques:les produits financiers fournis par la société comprennent des contrats sur différence (\'CFD \') et d\'autres produits financiers complexes. Le trading de CFD comporte un niveau de risque élevé, car l\'effet de levier peut être à la fois bon et mauvais pour vous. Par conséquent, les CFD peuvent ne pas convenir à tous les investisseurs, car ils peuvent perdre tout votre capital d\'investissement. Vous ne devriez jamais investir de l\'argent que vous ne pouvez pas vous permettre de perdre. Avant de négocier les produits financiers complexes fournis, assurez-vous de comprendre les risques encourus. Vous bénéficiez d\'un droit limité, non exclusif et non transférable d\'utiliser la propriété intellectuelle fournie sur ce site Web uniquement à des fins personnelles et non commerciales liées aux services fournis sur le site Web. ',
    footer2: 'Veuillez noter que les informations sur ce site Web ne sont pas destinées aux résidents d\'un pays ou d\'une juridiction qui diffère des lois et réglementations décrites sur le site Web. Carrod Securities Co Ltd n\'est pas un conseiller financier et seuls des conseils généraux vous sont fournis . N\'émettra aucun avis ou suggestion sur l\'acquisition, la détention ou la vente de transactions sur marge.',
    1: 'Nom du produit',
    2: 'Taux de rendement quotidien attendu',
    3: 'Date limite du produit',
    4: 'Montant de l\'investissement de départ',
    5: 'Opération',
    6: 'Achat',
    7: 'Gestion des devises restantes',
    8: 'Gestion financière flexible, gagnez chaque jour',
    9: 'À propos de nous',
    10: 'Contrat d\'utilisation',
    11: 'Politique de confidentialité',
    12: 'Déclaration légale',
    13: 'Règles commerciales',
    14: 'Description des termes',
    15: 'Service',
    16: 'Aide aux novices',
    17: 'FAQ',
    18: 'Taux des frais de transaction',
    19: 'Système de négociation de deuxième contrat en monnaie numérique',
    20: 'Rapide, pratique, sans répartition, risque contrôlable',
    21: 'Présentation du système',
    22: 'Avec le développement rapide des contrats à terme sur devises numériques, la majorité des utilisateurs détiennent de plus en plus d\'actifs et les méthodes de négociation de la monnaie virtuelle se sont également diversifiées. Les utilisateurs recherchent de plus en plus des méthodes de règlement des transactions rapides et efficaces. Pour cette raison, le deuxième contrat a également été très apprécié. Le deuxième contrat lancé par Mutual Cloud est un système de trading pour le règlement de contrats de devises virtuelles en unités de centimes. Le système prend en charge le trading au prix du marché et le trading au prix limite ; les utilisateurs peuvent acheter des options d\'achat et de vente, avec un petit investissement en capital, un seuil bas et un cycle de règlement court, divisé en 1 minute, 2 minutes, 3 minutes, etc., et le système peut être configuré indépendamment. Le système dispose d\'un système de contrôle des risques rigoureux, avec les fluctuations les plus élevées et les plus faibles définies, si la limite est dépassée, les cotes seront automatiquement remises à zéro. ',
    23: 'source ligne K',
    24: 'Le commerce dépend principalement des cotations du marché des bourses externes pour le commerce',
    25: 'devise de soutien',
    251: 'Méthode de dépôt',
    252: 'pièces de dépôt',
    26: 'Recharger via l\'adresse du portefeuille',
    27: 'Achat via une transaction C2C',
    28: 'Répondre aux besoins de la plateforme pour émettre des pièces',
    281: 'Achat via une transaction OTC',
    282: 'La plateforme n\'a pas à supporter le risque de flottement des devises',
    29: 'Conception du modèle de contrat',
    30: 'Méthode de négociation',
    31: 'Réglage de l\'heure de règlement',
    32: 'Direction commerciale',
    33: 'Contrôle des transactions',
    34: 'Contrôle des risques',
    35: 'Limite d\'achat',
    36: 'Prévenir les pertes à grande échelle des clients',
    38: 'Fixer la date limite de passation des commandes',
    39: 'Assurez-vous suffisamment de temps de liquidation pour le tirage',
    40: 'lignes de montage et de descente',
    41: 'Déclenchez les probabilités à zéro pour empêcher les transactions unilatérales',
    42: 'Évitez les pertes à grande échelle',
    43: 'bouton de fermeture en un clic',
    44: 'Les clients peuvent cliquer sur le bouton pour fermer une position dans des situations extrêmes',
    46: 'Surveillance des services',
    47: 'Surveillance complète des services pour garantir une réponse rapide aux pannes de service et lancer des notifications par e-mail d\'échec à temps',
    48: 'Assistance technique',
    49: 'Nous approchons les clients avec cœur, nous sommes enthousiastes, attentifs, patients et prudents. Nous avons une équipe de R&D logicielle professionnelle, une surveillance et une gestion rigoureuses de la plate-forme et une équipe de service professionnelle à guichet unique. ',
    50: 'Un, sélectionnez la paire de trading',
    51: 'Deux, vérifiez le prix',
    52: 'Regardez le prix de la transaction sur le marché et choisissez le bon prix pour passer une commande. ',
    53: 'Trois, achetez après avoir rempli la commande',
    54: 'Choisissez la paire de transactions qui doit être achetée (la transaction de devise à devise fait référence à l\'utilisation d\'un actif numérique pour l\'échanger contre un autre actif numérique)',
    55: 'Renseignez le prix d\'achat et le volume d\'achat, et attendez la transaction du marché. ',
    57: 'Comprendre les transactions en devises en quelques secondes',
    58: 'Transaction sûre et fiable, rapide',
    59: 'Cotations commerciales',
    60: 'En stock',
    601: 'Fournir des actifs numériques avancés, des CFD et des plateformes de négociation de matières premières aux investisseurs mondiaux',
    61: 'Quel est l\'endroit ? ',
    62: 'Spot est pour les contrats à terme. Spot et futures sont similaires, la principale différence réside dans la différence d\'effet de levier et de marge, le délai de livraison de la transaction, etc.',
    63: 'Avenirs',
    64: 'Que sont les futurs ?',
    65: 'Les contrats à terme, appelés contrats à terme, sont une méthode de négociation qui s\'étend sur le temps. En signant un contrat, l\'acheteur et le vendeur s\'engagent à livrer une quantité spécifiée de spot selon l\'heure, le prix et d\'autres conditions commerciales spécifiés. ',
    66: 'Gestion financière de la confiance',
    67: 'Qu\'est-ce que le financement fiduciaire ?',
    68: 'La finance décentralisée est une sorte de finance basée sur la blockchain. Elle ne s\'appuie pas sur des institutions financières telles que des courtiers, des bourses ou des banques pour fournir des outils financiers, mais utilise des contrats intelligents sur la blockchain pour mener des activités financières',
    69: 'C2C',
    70: 'Qu\'est-ce que le C2C',
    71: 'C2C, également connu sous le nom de \'privé-à-privé \' est une forme de transaction, il fait référence à la forme de transaction d\'individu à individu',
    72: 'service de négociation à guichet unique',
    73: 'Nom',
    74: 'Prix',
    75: '24H des hauts et des bas',
    76: 'volume 24H',
    77: 'Citations',
    78: 'Agrégation stable et sûre',
    79: 'Protection de sécurité de qualité financière',
    80: 'Une technologie de confidentialité et de confidentialité impartiale et sans connaissance pour protéger la confidentialité des utilisateurs, les signatures multiples, l\'architecture en couches et d\'autres conceptions de sécurité. ',
    81: 'Moteur de correspondance haute performance',
    82: 'Adopter une architecture de cluster distribué avancée et des méthodes de développement de microservices. Prend en charge plus de 3 millions de transactions par seconde simultanément. ',
    83: 'service ininterrompu 24 heures sur 24',
    84: 'Le service client est en ligne 24 heures sur 24, 7 jours sur 7, pour répondre à diverses questions à votre place. ',
    85: 'Ouvrir des transactions à tout moment, n\'importe où',
    86: 'Nouvelle révision pour répondre aux besoins commerciaux de divers scénarios à tout moment',
    87: '24 * 7 heures en mode de fonctionnement à temps plein et par tous les temps, le service client répondra à vos demandes connexes dans les plus brefs délais. ',
    88: 'Nom ',
    89: 'Dernier prix',
    90: 'Monte et chute',
    91: 'Prix le plus élevé',
    92: 'Prix le plus bas',
    93: 'Volume',
    94: 'Connexion',
    95: 'Enregistrer',
    fee: {
        1: 'Taux des frais de transaction',
        2: 'Frais de transaction de devise',
        3: 'Frais de transaction du contrat'
    },
    law: {
        1: 'Déclaration légale',
        2: 'Article premier',
        3: 'Cette plate-forme s\'engage à créer un environnement d\'échange d\'actifs numériques conforme, sûr et fiable pour les utilisateurs, et interdit aux utilisateurs d\'utiliser ce site Web pour se livrer au blanchiment d\'argent, à la contrebande, à la corruption commerciale, à la manipulation malveillante du marché et à d\'autres activités commerciales illégales ou illégales. Activités. À cette fin, nous faisons de notre mieux pour formuler des programmes stricts de lutte contre le blanchiment d\'argent et le terrorisme et prendre des mesures efficaces de contrôle des risques de sécurité afin de maximiser la protection des droits et intérêts légitimes des utilisateurs, et de maintenir une bonne réputation de marque sur la plate-forme. lui-même',
        4: 'Deuxième article',
        5: 'Lorsque les autorités compétentes produisent les documents d\'enquête correspondants et demandent à ce site Web de coopérer à l\'enquête sur les utilisateurs désignés, ou lorsque des mesures telles que le scellement, le gel ou le transfert de comptes d\'utilisateurs sont prises, ce site Web vérifiera la validité et l\'authenticité des documents judiciaires Après les rapports sexuels, conformément aux exigences de l\'autorité compétente déterminées par la plate-forme pour aider à fournir les données utilisateur correspondantes ou à effectuer les opérations correspondantes, la fuite de la vie privée de l\'utilisateur, l\'inopérabilité du compte et d\'autres pertes causées aux utilisateurs, etc., sont causées par cela Nous n\'assumons aucune responsabilité. ',
        6: 'article 3',
        7: 'Tous les utilisateurs qui utilisent les services de ce site Web doivent le savoir avant de négocier : les actifs numériques ne sont soutenus par aucun gouvernement ou banque centrale, et le prix des actifs numériques fluctue considérablement, la détention ou l\'utilisation d\'actifs numériques présente un risque plus élevé. Avant de prendre une décision commerciale, vous devez être rationnel et prudent avec votre propre situation financière pour déterminer si elle vous convient',
        8: 'article 4',
        9: 'Quiconque se connecte à ce site Web de quelque manière que ce soit ou utilise directement ou indirectement les services de ce site Web est réputé accepter volontairement les contraintes de cette déclaration de site Web. '
    },
    rules: {
        1: 'Règles commerciales',
        2: 'Après avoir créé une commande, l\'acheteur doit :',
        3: '1, effectuez le paiement dans le délai de paiement spécifié dans la commande, puis cliquez sur "J\'ai payé" ;',
        4: '2, utiliser mon mode de paiement en nom réel, commander le paiement en fonction du montant de la commande et du compte de paiement désigné par la commande pour effectuer le paiement de la commande en temps réel ;',
        5: '3. Si vous choisissez un mode de paiement en différé pour régler la commande, vous devez obtenir au préalable l\'accord du vendeur',
        6: '4. L\'ordre de paiement ne doit pas mentionner \'monnaie numérique, BTC, Bitcoin \' et d\'autres informations ;',
        7: 'Principes que le vendeur doit suivre',
        8: 'Une fois la commande générée, le vendeur doit',
        9: '1. Fournir un compte de réception certifié par la plateforme ;',
        10: '2, après réception du paiement intégral de l\'acheteur, terminer la mainlevée dans le délai indiqué dans la commande ;',
        11: '3. Sans le consentement de l\'acheteur, l\'acheteur/le service client ne sera pas tenu d\'annuler la commande ;',
        12: '4. Sans le consentement de l\'acheteur, aucune objection au prix de la commande qui a été créé n\'est autorisée ;'
    },
    help: {
        1: 'Aide novice',
        2: 'Limit Order',
        3: 'Un ordre d\'achat/vente à un prix spécifié ou mieux. ',
        4: 'Ordre du marché',

        5: 'Un ordre d\'achat ou de vente immédiat au prix actuel du marché. ',
        6: 'Ordre stop-loss limite',
        7: 'Un ordre de vente ou de clôture d\'une position à un prix déterminé après que le marché a atteint un certain prix pour contrôler les pertes. ',
        8: 'Ordre de prise de profit à prix limité',
        9: 'Semblable à un ordre stop-limit, il s\'agit également d\'un ordre de vente ou de clôture d\'une position à un prix déterminé après que le marché a atteint un certain prix, qui est utilisé pour contrôler le profit. ',
        10: 'Ordre stop loss au marché',
        11: 'Un ordre de vente ou de clôture d\'une position au prix du marché après que le marché a atteint un certain prix pour contrôler les pertes. ',
        12: 'Ordre de prise de profit au prix du marché',
        13: 'Semblable à un ordre stop loss sur le marché, il s\'agit également d\'un ordre de vente ou de clôture d\'une position au prix du marché après que le marché a atteint un certain prix, qui est utilisé pour contrôler le profit. ',
        14: 'Quelle est la marge pour ouvrir une position ? ',
        15: 'Le trading sur marge signifie que vous n\'avez besoin de payer qu\'un certain pourcentage de la marge pour acheter et vendre le contrat. Selon l\'effet de levier sélectionné, le ratio de marge est un multiple inférieur de la valeur du contrat. Cela peut augmenter vos profits, mais cela peut aussi vous exposer à des risques supplémentaires. ',
        16: 'Qu\'est-ce qu\'une liquidation et qu\'est-ce qu\'une crevaison ? ',
        17: 'Lorsque les fonds restants sur votre compte sont inférieurs aux profits et pertes flottants requis par le contrat de trading, votre position sera fermée de force par la bourse, également appelée liquidation. Étant donné que le prix lorsque la position est fermée est déterminé en fonction du prix du marché, lorsque le prix de la transaction est extrêmement défavorable, vous pouvez ne pas avoir suffisamment de fonds pour couvrir la perte de la transaction, ce qui entraîne une valeur négative sur votre compte. appelé une position shorting. '
    },
    96: 'Sélectionner une paire de trading',
    97: 'Vérifier le prix',
    98: 'Acheter après avoir rempli la commande',
    99: 'Connexion au compte',
    100: 'Bienvenue ! Connectez-vous avec votre email ou numéro de téléphone',
    101: 'boîte aux lettres',
    102: 'mobile',
    103: 'Vous avez oublié votre mot de passe ? ',
    104: 'Inscrivez-vous maintenant',
    105: 'Mot de passe',
    106: 'Veuillez entrer votre numéro de téléphone',
    107: 'Erreur de format de numéro de téléphone mobile',
    108: 'Veuillez entrer l\'e-mail',
    109: 'Erreur de format de boîte aux lettres',
    110: 'Veuillez saisir un mot de passe',
    111: 'Le mot de passe comporte au moins 6 caractères',
    112: 'Le mot de passe est jusqu\'à 32 bits',
    113: 'Langue et région',
    114: 'Créer un compte {appName}',
    115: 'Utilisez votre e-mail ou votre numéro de téléphone portable pour vous inscrire',
    116: 'Code de recommandation',
    117: 'd\'accord',
    118: 'et',
    119: 'Contrat d\'utilisation',
    120: 'Politique de confidentialité',
    121: ' Enregistré ? ',
    122: 'Code de vérification',
    123: 'Envoyer',
    124: 'Veuillez saisir le code de vérification',
    125: 'Le code de vérification doit comporter 6 chiffres',
    126: 'Réinitialiser le mot de passe',
    127: 'D\'accord',
    128: 'Utilisez votre e-mail ou votre téléphone portable pour réinitialiser le mot de passe de connexion',
    129: 'Authentification par nom réel',
    130: 'L\'authentification par nom réel permet de protéger la sécurité du compte, d\'augmenter les limites de retrait et les autorisations de transaction',
    131: 'Aller à l\'authentification',
    132: 'Authentification',
    133: 'Utilisé pour se connecter, retirer des pièces, récupérer des mots de passe, modifier les paramètres de sécurité',
    134: 'Vérifié',
    135: 'Gestion des mots de passe',
    136: 'Mot de passe de connexion',
    137: 'Mot de passe du fonds',
    138: 'Utilisé pour protéger la sécurité du compte',
    139: 'utilisé pour protéger la sécurité des fonds',
    140: 'Paramètres personnels',
    141: 'Préférence de couleur',
    142: 'Paramètres',
    143: 'Modifier',
    144: 'Définissez le graphique en chandeliers, achetez vers le haut et vers le bas des couleurs',
    145: 'Méthode de tarification',
    146: 'Unité de valorisation des actifs du compte',
    147: 'par défaut',
    148: 'Le vert monte et le rouge descend',
    149: 'Rouge vers le haut et vert vers le bas',
    150: 'Modifier le mot de passe de connexion',
    151: 'Modifier le mot de passe du fonds',
    152: 'Ancien mot de passe',
    153: 'Nouveau mot de passe',
    154: 'Veuillez saisir l\'ancien mot de passe',
    155: 'Nouveau numéro de téléphone',
    156: 'Nouvelle boîte aux lettres',
    157: 'Lier le numéro de téléphone mobile',
    158: 'lier la boîte aux lettres',
    159: 'Modifier le numéro de téléphone mobile',
    160: 'Modifier la boîte aux lettres',
    161: 'Authentifié',
    162: 'Définir un surnom',
    163: 's\'il vous plait, entrez ton surnom',
    164: 'Annuler',
    165: 'Pays',
    166: 'Prénom',
    167: 'Nom',
    168: 'Type de certificat',
    169: 'Numéro de certificat',
    170: 'Veuillez sélectionner un pays',
    171: 'Veuillez saisir le prénom',
    172: 'Veuillez entrer le nom de famille',
    173: 'Veuillez saisir le numéro d\'identification',
    174: 'Veuillez télécharger la photo avant de la pièce d\'identité',
    175: 'Veuillez télécharger une photo du verso de votre pièce d\'identité',
    176: 'Veuillez télécharger une photo de votre pièce d\'identité portable',
    177: 'Soumettre',
    178: 'Certification',
    179: 'ID',
    180: 'Passeport',
    181: 'Suivant',
    182: 'Télécharger les photos d\'identité et terminer l\'authentification',
    183: 'Échec du téléchargement',
    184: 'Erreur de format d\'image',
    185: 'L\'image est trop grande',
    186: 'Chargement',
    187: 'Aperçu des actifs',
    188: 'Transfert',
    189: 'Retrait',
    190: 'Pièces de dépôt',
    191: 'Relevés financiers',
    192: 'Geler',
    193: 'Disponible',
    194: 'Total',
    195: 'Masquer la petite monnaie',
    196: 'Monnaie',
    197: 'évaluation USDT',
    198: 'Rechercher',
    199: 'Réseau de recharge',
    200: 'Adresse de dépôt',
    201: 'adresse de retrait',
    202: 'Montant de la recharge',
    203: 'Capture d\'écran du dépôt de pièces',
    204: 'Remarques',
    205: 'Copier l\'adresse',
    206: 'Copie réussie',
    207: 'Retour',
    208: 'facultatif',
    209: 'Veuillez saisir le nombre de pièces à recharger',
    210: 'Veuillez un numéro valide',
    211: 'Veuillez saisir l\'adresse de la devise',
    212: 'Veuillez télécharger une capture d\'écran du dépôt de pièces',
    213: 'Adresse de retrait',
    214: 'Montant du retrait',
    215: 'Solde disponible',
    216: 'Tous',
    217: 'Frais de traitement',
    218: 'Veuillez saisir le montant des pièces retirées',
    219: 'Veuillez saisir le mot de passe du fonds',
    220: 'le mot de passe du fonds doit comporter 6 chiffres',
    221: 'Mot de passe par défaut',
    222: 'Vous n\'avez pas encore lié le portefeuille correspondant ! ',
    223: 'Aller à l\'ajout',
    224: 'Soumission réussie',
    225: 'Ajouter un portefeuille de collection',
    226: 'Adresse',
    227: 'Veuillez entrer dans le réseau',
    228: 'Veuillez saisir l\'adresse',
    229: 'Réseau',
    230: 'réseau de retrait',
    231: 'La longueur de l\'adresse correspond aux 32 bits les plus bas',
    232: 'Transfert de fonds',
    233: 'Montant du transfert',
    234: 'Veuillez saisir le montant du virement',
    235: 'Équilibre insuffisant',
    236: 'Peut être transféré',
    237: 'Direction du balayage',
    238: 'Transfert',
    239: 'De',
    240: 'à',
    241: 'Relevés financiers',
    242: 'enregistrement de dépôt de pièces',
    243: 'Enregistrement de retrait',
    244: 'Enregistrements de transfert',
    245: 'Quantité',
    246: 'Statut',
    247: 'Temps créé',
    248: 'Confirmer l\'heure',
    249: 'Pas plus',
    250: 'Prix',
    253: 'Nom',
    254: 'Variation',
    255: 'Pas encore de données',
    256: 'Somme',
    257: 'Élevé',
    258: 'Faible',
    259: 'Volume',
    260: 'Ouvert',
    261: 'Recevoir',
    262: 'Montant',
    263: 'Trading à terme',
    264: 'haussier',
    265: 'baissier',
    266: 'Nom du produit',
    267: 'Voir le contrat',
    268: 'Montant d\'achat',
    269: 'Veuillez saisir le montant de l\'achat',
    270: 'Frais de transaction',
    271: 'achat minimum',
    272: 'Statut',
    273: 'En attente de livraison',
    274: 'Livré',
    275: 'Commande passée avec succès',
    276: 'Nom du produit',
    277: 'Profits et pertes',
    278: 'Prix d\'achat',
    279: 'Prix de livraison',
    280: 'Continuer le trading',
    283: 'Bénéfice',
    284: 'Confirmer la commande',
    285: 'Ordres d\'histoire',
    286: 'Commandes en attente',
    287: 'Temps créé',
    288: 'Acheter',
    289: 'Vendre',
    290: 'Nombre d\'opérations',
    291: 'Montant de la transaction',
    292: 'Contrat',
    293: 'Délai de livraison',
    294: 'Prix de livraison',
    295: 'secondes',
    296: 'Procès-verbal',
    297: 'Heure',
    298: 'jour',
    299: 'temps restant',
    300: 'Voir plus',
    301: 'Opération de change',
    302: 'Montant de la vente',
    303: 'Veuillez saisir le montant de la vente',
    304: 'transaction en monnaie fiduciaire',
    305: 'Actifs',
    306: 'Déconnexion',
    307: 'Enregistré avec succès',
    308: 'Veuillez saisir une recommandation',
    309: 'Succès',
    310: 'Echec',
    311: 'Confirmation',
    312: 'Mode de paiement',
    313: 'Adresse de retrait',
    314: 'Supprimer',
    315: 'Opération',
    316: 'Votre portefeuille',
    317: 'Êtes-vous sûr de vouloir supprimer ? ',
    318: 'Suppression réussie',
    319: 'Échec de l\'authentification',
    320: 'Recertification',
    330: 'Portefeuille de retrait d\'actifs numériques',
    331: 'Lier une carte bancaire, retirer de l\'argent en monnaie fiduciaire',
    332:'Veuillez entrer une adresse valide',
    333: 'Virtuelle',
    334: 'Forex',
    335 : 'Métaux',
    1000: 'jour',
    1001: 'En cours',
    1002: `Fermé`,
    1003: `Règles`,
    1004: `Avantage du produit`,
    1005: `Sauvegarder au fur et à mesure que vous prenez`,
    1006: `Dividende expiré`,
    1007: `Bonus quotidien`,
    1008: `Intérêts de dépôt en direct`,
    1009: `100% garantie du capital`,
    1010: `Revenu normal pendant les vacances`,
    1011: `Reposez-vous le lendemain`,
    1012: `Avantage du produit`,
    1013: `Les membres déposent 200 000 USDT sur la plateforme, choisissent un produit de gestion de patrimoine avec une période de 14 jours et un revenu journalier de 0,5%, et le revenu journalier est le suivant : 200 000*0,5%=1 000USDT C'est-à-dire qu'après 14 jours, vous peut obtenir un revenu de 14 000 USDT, et le revenu est émis quotidiennement. Déposez le capital et il sera automatiquement restitué sur votre compte à l'échéance.`,
    1014: `Ordre de garde`,
    1015: `hébergement`,
    1016: 'Terminé',
    1017: `Rachetée`,
    1018: `Pas encore de données`,
    1019: `Montant de l'achat`,
    1020: `Taux de rendement journalier`,
    1021: 'CONFIANCE.6',
    1022: `Délai de paiement`,
    1023: `Date de remboursement`,
    1024: 'Expiré',
    1025: 'Récemment (revenu quotidien)',
    1026: `temps de dividende`,
    1027: `Tous les jours`,
    1028: `Montant de garde`,
    1029: `Retour à l'expiration`,
    1030: `Solde disponible`,
    1031: `Revenu total estimé`,
    1032: `Transfert de fonds`,
    1033: `Tous`,
    1034: `limite unique`,
    1035: `Frais de traitement`,
    1036: `J'ai lu et accepté`,
    1037: `Contrat de service de gestion financière de la plateforme`,
    1038: `D'accord`,
    1039: `Abonnement`,
    1040: `Acheter`,
    1041: `Vendre`,
    1042: 'virement bancaire',
    1043: `Veuillez saisir le montant`,
    1044: `Catégorie de monnaie fiduciaire`,
    1045: `Quantité vendue`,
    1046: `Maximum disponible à la vente`,
    1047: `Tous`,
    1048: `Prix unitaire de référence`,
    1049: `Votre mode de paiement`,
    1050: `Nom de la banque`,
    1051: `Numéro de compte bancaire`,
    1052: `Nom`,
    1053: `Vous n'avez pas lié le mode de paiement correspondant et ne pouvez pas être vendu. `,
    1054: `Enregistrement de la commande`,
    1055: `Filtre`,
    1056: `Tapez`,
    1057: `Statut`,
    1058: `Traitement`,
    1059: 'Passé',
    1060: `Echec`,
    1061: `Quantité`,
    1062: `Succès`,
    1063: `Tâche en cours`,
    1064: `Calculez les résultats du jour`,
    1065: `Revenu cumulé`,
    1066: `Ordonnance de garde à vue`,
    1067: `Le compte de confiance n'est pas ouvert`,
    1068: `Ouvrez un compte en fiducie de communication, bénéficiez d'un revenu passif stable et facilitez l'appréciation des actifs. `,
    1069: `Demander l'activation signifie que vous l'avez lu et accepté`,
    1070: '`Accord de service de gestion financière de la plate-forme`',
    1071: `Demander l'activation`,
    1072: `Annuler`,
    1073: 'Exécution...',
    1074: `Rédemption`,
    1075: `Êtes-vous sûr de vouloir l'échanger? `,
    1076: `Le remboursement anticipé nécessite le paiement de dommages et intérêts, qui seront déduits cette fois en fonction de votre type de gestion financière`,
    1077: ` ,La rédemption est-elle confirmée?`,
    1078: `Envoyé`,
    1079: `Veuillez saisir le code de vérification`,
    1080: `Invite`,
    1081: `Bénéfice`,
    1082: `temps`,
    1083: `Ajouter un mode de paiement en devise légale`,
    1084: `État de la commande`,
    1085: `Monnaie`,
    1086: 'Nombre de monnaie légale',
    1087: `Numéro de compte bancaire`,
    1088: `Méthode de collecte d'argent gras`,
    1089: `Ajouter une méthode de collecte`,
    1090: `Mode de paiement existant`,
    1091: `Monnaie fiduciaire`,
    1092: `Code bancaire`,
    1093: `Êtes-vous sûr de vouloir supprimer le mode de paiement?`,
    1094: `Veuillez saisir le numéro de compte bancaire`,
    1095: 'Veuillez entrer le nom de la banque',
    1096: 'Veuillez entrer la devise légale',
    1097: `Veuillez sélectionner`,
    1098: 'Veuillez entrer votre nom',
    1099: 'Branche',
    1100: `Illimité`,
    1101: `Vous n'avez pas encore vérifié votre vrai nom`,
    1102: 'Livre blanc',
    1103: 'Nos fonctionnalités',
    1104: 'Nous sommes en sécurité',
    1105: 'Nous sommes puissants',
    1106: 'Meilleure assistance',
    1107: 'Nous sommes mondiaux',
    1108: 'Comment ça marche',
    1109: "Des utilisateurs dans plus de 20 pays et régions utilisent notre plateforme et sont largement reconnus",
    1110: 'Cryptage avancé des données',
    1111: 'Riche variété de crypto-monnaies',
    1112: '90+ crypto-monnaies répertoriées',
    1113: 'Vos données de transaction sont sécurisées via un cryptage de bout en bout, garantissant que vous seul avez accès à vos informations personnelles.',
    1114 : '43 milliards',
    1115: 'Volume de trading 24h',
    1116: '30 millions',
    1117: 'Utilisateurs enregistrés qui nous font confiance',
    1118: 'Frais de transaction les plus bas',
    1119: 'Télécharger',
    1120:'Nom de l\'institution financière',
    1121:'nom de la succursale',
    1122:'numéro de la succursale',
    1123:'Numéro de compte',
    1124:'Nom du destinataire',
    1125:'Kinjo',

    1148:'La plateforme de crypto-monnaie la plus fiable',
    1149:'Voici les raisons pour lesquelles vous devriez nous choisir',
    1150:'Nous sommes en sécurité',
    1151:'Technologie de confidentialité et de confidentialité impartiale et sans connaissance pour protéger la confidentialité des utilisateurs, les signatures multiples, l`architecture en couches et d`autres conceptions de sécurité ',
    1152:'Meilleur soutien',
    1153:'24*7 heures par tous les temps, mode de fonctionnement à temps plein, le service client répondra à vos demandes connexes dès que possible',
    1154:'Nous sommes mondiaux',
    1155:'Des utilisateurs dans plus de 20 pays et régions utilisent notre plateforme et sont largement reconnus',
    1156:'Nous sommes puissants',
    1157:'La plateforme de trading d`actifs numériques la plus populaire, accès aux outils d`analyse technique et de trading les plus puissants et les plus pratiques du marché',
    1158:'Riche variété de crypto-monnaie',
    1159:'Plus de 90 crypto-monnaies répertoriées',
    1160:'Cryptage avancé des données',
    1161:'Vos données de transaction sont sécurisées via un cryptage de bout en bout, garantissant que vous seul avez accès à vos informations personnelles. ',
    1162:'Nous prenons en charge une variété des devises numériques les plus populaires',
    1163:'De nombreux pays ont établi des transactions localisées',
    1164:'Utilisez la monnaie légale pour acheter des actifs numériques et entrez rapidement dans le monde des actifs numériques',
    1165:'Échangez pour aller n`importe où n`importe quand',
    1166:'Scannez-moi pour télécharger',
    1167:'Taux d échange',
    1168:'Achetez et vendez plus de 250 crypto-monnaies avec des taux de change récemment téléchargés',
    1169:'Restez au courant avec notre application et notre client de bureau',
    1170:'Nom',
    1171:'Prix',
    1172:'Changer',
    1173:'Graphique',
    1174:'Échanger',
    1175:'Détail',
    1176:'Étaient',
    1177:'Sécurisé',
    1178:'Nous avons',
    1179:'Mondial',
    1180:'Puissant',
    1181:'Riche variété de',
    1182:'Crypto-monnaie',
    1183:'Données avancées',
    1184:'Chiffrement',
    1185:'Prêt à commencer',
    1186:'Nous sommes la première plateforme mondiale de négociation d actifs numériques. ',
    1187:'Nous sommes la première plateforme de trading d actifs numériques au monde. Nous nous efforçons constamment d améliorer les fonctions de sécurité pour assurer la sécurité des actifs numériques de nos clients. Notre modèle est d apporter aux clients des projets à valeur durable.',

    1188: 'Téléchargement de l application',
    1189: '24 * 7 tous temps, mode de fonctionnement à temps plein, le service client répondra aux demandes pertinentes pour vous dès que possible',
    1190: 'Nous sommes mondiaux',
    1191: 'Nous sommes forts',
    1192: 'La plateforme de trading d actifs numériques la plus populaire, bénéficiez des outils d analyse technique et de trading les plus puissants et les plus pratiques du marché.',
    1193: 'Grande variété de crypto-monnaies',
    1194: 'Vos données de transaction sont protégées par un cryptage de bout en bout, garantissant que vous seul pouvez accéder à vos informations personnelles.',
    1195: 'Frais de transaction minimaux',
    1196: 'Faites confiance à nos utilisateurs enregistrés',
    1197: 'Volume de transactions sur 24 heures',
    1198: '+42 milliards',
    1199: '+30 millions',
    1200: 'Plusieurs pays ont mis en place des centres de services de transaction localisés pour créer un écosystème de blockchain qui intègre plusieurs formes commerciales',
    1201: 'Utilisez la monnaie fiduciaire pour acheter des actifs numériques et entrez rapidement dans le monde des actifs numériques',
    1202: 'Téléchargement de l`application',
    1203: 'Notre avantage',
    1204: 'Des informations riches sur les devises et les devises, fournissant des devises, des contrats, des transactions en devises légales et d`autres méthodes de transaction, un marché professionnel et une profondeur.'
}