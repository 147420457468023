<template>
  <div class="wraper financial">
    <div
      class="Box row active animated "
      v-animated="{ className: 'fadeInRight' }"
    >
      <div class="col-12">
        <div class="wraper-box" :class="{ 'wraper-box-2': modle === '2' }">
          <div class="title-box">
            <div class="title-box-text">
              <h1 class="title">{{ $t("7") }}</h1>
              <h2>{{ $t("8") }}</h2>
            </div>
          </div>

          <!-- 內文 -->
          <div class="content-box">
            <div class="content-box-text">
              <!-- 類別選單 -->
              <template v-if="step === 1">
                <div class="category">
                  <div
                    v-for="(item, ci) in categoryItems"
                    :key="ci"
                    @click="handleCategory(item)"
                    class="category-box"
                  >
                    <div class="category-row">
                      <div class="flex">  
                        <span class="triangle" />
                        <p class="title">{{ item.title }}</p>
                      </div>
                      <p v-if="item.status" class="status">{{ $t("1001") }}</p>
                      <p v-if="!item.status" class="status un-status">
                        {{ $t("1002") }}
                      </p>
                    </div>
                    <div class="category-center">
                      {{ item.summary }}
                    </div>
                    <div class="category-row number">
                      <p class="l">{{ item.ratio }}%</p>
                      <p class="r">{{ item.profitDesc }}</p>
                    </div>
                  </div>
                </div>
              </template>

              <!-- 產品內容 -->  
              <template v-if="step === 2">
                <div class="back">
                  <div class="backBtn pointer">
                    <img src="../assets/img/back.png" @click="backToStep1" />
                  </div>
                </div>

                <div v-loading="isLoading" class="content-nobg">
                  <div class="flex-nw mt10">
                    <h2 class="titleHasLeftLine">
                      {{ curCate.title }}
                    </h2>
                    <span>{{ curCate.summary }}</span>
                  </div>

                  <div class="userInfo">
                    <div class="userInfo-row">
                      <div class="userInfo-item">
                        <p>{{$t(1063)}}(USDT)：{{ financialDetailData.pledgeAmount }}</p>
                        <br />
                        <p>{{$t(1064)}}(USDT)：{{ financialDetailData.todayAmount }}</p>
                        <br />
                        <p>{{$t(1065)}}：{{ financialDetailData.incomeAmount }}</p>
                        <br />
                        <p>{{$t(1066)}}：{{ financialDetailData.orderCount }}</p>
                      </div>
                      <div class="userInfo-item btns">
                        <el-button  @click="showOrderDialog">{{ $t(1014) }}</el-button>
                        <el-button  @click="showRulesDialog" v-if="showRule">{{ $t(1003) }}</el-button>
                      </div>
                    </div>
                  </div>

                  <SimpleTable :tableDatas="selectedTabDatas" @showDialog="showDialog" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FinancialBuyDialog
      v-if="FinancialBuyDialog"
      :dialogData="buyDialogData"
      @onClosed="onClosed"
      @save="save"
    />
    <FinancialOrderDialog
      v-if="FinancialOrderDialog"
      :dialogData="curCate"
      @onClosed="onClosed"
    />
    <FinancialRulesDialog
      v-if="FinancialRulesDialog"
      :dialogData="rulesDialogData"
      @onClosed="onClosed"
    />
  </div>
</template>

<script>
import FinancialBuyDialog from "./FinancialBuyDialog";
import FinancialOrderDialog from "./FinancialOrderDialog";
import FinancialRulesDialog from "./FinancialRulesDialog";
import SimpleTable from "../components/SimpleTable";
import { getWealthCate, getProductsByCate, financialDetail } from "../api";
export default {
  name: "Financial",
  props: ["lang"],
  components: {
    SimpleTable,
    FinancialBuyDialog,
    FinancialOrderDialog,
    FinancialRulesDialog,
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
        if (from.name === 'Transfer' && Object.keys(vm.$store.state.financialBuy).length > 0) {
            vm.financialDetailData = vm.$store.state.financialBuy.financialDetailData,
            vm.selectedTabDatas = vm.$store.state.financialBuy.selectedTabDatas,
            vm.step = 2
            vm.showDialog(vm.$store.state.financialBuy.buyDialogdata.dialogData)
        }
    })
  },
  data() {
    return {
      isLoading: false,  
      step: 1,
      backTofinancialBuy: 0,
      FinancialBuyDialog: false,
      FinancialOrderDialog: false,
      FinancialRulesDialog: false,
      orderDialogData: {},
      rulesDialogData: {},
      buyDialogData: {},
      dialogData: {},
      cateList: [],
      products: [],
      curCate: {},
      financialDetailData: {},
      selectedTabDatas:{
          tableHead:[],
          tableData:[]
      }
    };
  },
  computed: { 
    modle() {
      return window.configObj.officialModel;
    },
    showRule() {
      return window.configObj.isShowPledgeRule === '1';
    },
    tabs() {
      const tableHead = [];
      this.cateList.map((item) => {
        tableHead.push(item.title);
      });
      return tableHead;
    },
    categoryItems() {
      return this.cateList;
    }
  },
  mounted() {
    this.getCate()
    if (this.step === 2 ) this.scrollDown()
  },
  methods: {
     // 因資金劃轉，暫存使用狀態 
     save(buyDialogdata){
      const payload = {
          buyDialogdata,
          selectedTabDatas: this.selectedTabDatas,
          financialDetailData: this.financialDetailData
      }  
      this.$store.commit('SET_FINANCiAL_BUY', payload)
    },
    scrollDown(){
      window.scroll({
        top: 600,
        behavior: 'smooth'
        });
    },
    showDialog(data) {
      this.FinancialBuyDialog = true;
      this.buyDialogData = data;
      document.body.style.overflow = "hidden";
    },
    showOrderDialog(data) {
      this.FinancialOrderDialog = true;
      this.orderDialogData = data;
      document.body.style.overflow = "hidden";
    },
    showRulesDialog(data) {
      this.FinancialRulesDialog = true;
      this.rulesDialogData = data;
      document.body.style.overflow = "hidden";
    },
    onClosed(backFromOrderBack) {
      this.FinancialBuyDialog = false,
      this.FinancialRulesDialog = false,
      this.FinancialOrderDialog = false,
      document.body.style.overflow = "auto";
      this.handleCategory(this.curCate)
    },
    handleCategory(data) {
      this.getProducts(data.id);
      this.getFinancialDetail(data.id);
      this.curCate = data;
      this.step = 2;
    },
    backToStep1() {
      this.step = 1;
    },
    getCate() {
      getWealthCate().then((res) => {
        this.cateList = res.list ? res.list.filter(item => item.status) :[] ;
      });
    },
    getProducts(id) {
      this.isLoading = true
      getProductsByCate(id).then((res) => {
        const arr = [];
        if (res.list.length > 0) {
            res.list.map((item) => {
                arr.push({
                id: item.id,
                coin: item.coin,
                name: item.title,
                logo: item.logoUrl || item.logoUri,
                fire: true,
                ratio: item.ratio,
                price: `${item.ratio / 10}%`,
                circleDays: item.circleDays,
                time: `${item.circleDays} ${this.$t("1000")}`,
                timeOk: `${item.minAmount}~${item.maxAmount}(${item.coin})`,
                btnText: this.$t(1039),
                btnType: "financialBuy",
                maxAmount: item.maxAmount,
                minAmount: item.minAmount,
                });
            })
        }
       this.selectedTabDatas = {
            tableHead: [
                this.$t("1"),
                this.$t("2"),
                this.$t("3"),
                this.$t("4"),
                this.$t("5"),
            ],
            tableHeadTitle: { t: this.curCate.title, des: this.curCate.summary },
            tableData: arr,
       }
       this.isLoading = false
      })
    },
    getFinancialDetail(id) {
      financialDetail(id).then((res) => {
        const obj = {
          incomeAmount: 0,
          pledgeAmount:  0,
          todayAmount:  0,
          orderCount:  0,
        };
        if (res.coins) {
          for (const item of res.coins) {
            // 换算成USDT的价格进行计算
            let ThePrice = 1
            if (item.coin !== 'USDT'){
                const type = item.coin.concat('USDT')
                ThePrice = this.$store.state.spotList[type].price  // 匯率
            } 
            obj.incomeAmount += +(item.incomeAmount*ThePrice);
            obj.pledgeAmount += +(item.pledgeAmount*ThePrice);
            obj.todayAmount += +(item.todayAmount*ThePrice);
          }
          obj.orderCount = res.count;
        }

        // 檢查是否有小數
         for (let item in obj) {
             if (String(obj[item]).indexOf(".") + 1 > 0){
                obj[item] = obj[item].toFixed(6)
             }
         }    
        this.financialDetailData = obj;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wraper {
  margin: 0 0 0;
  .row {
    justify-content: space-around;
  }
  .wraper-box {
    display: flex;
    align-items: center;
    text-align: left;
    background: #fff;
    flex-wrap: wrap;
    padding: 0;
    .title-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      .title-box-text {
        width: 85%;
        max-width: 1200px;
        .title {
          font-size: 48px;
          font-weight: 500;
          margin-bottom: 40px;
        }
        p {
          font-size: 15px;
          margin: 15px 0;
        }
      }
    }
    .content-box {
      width: 100%;
      display: flex;
      justify-content: center;
      min-height: 80vh;
      //   padding-top: 119px;
      .content-box-text {
        width: 85%;
        max-width: 1200px;
        padding: 0 15px;
        color: #999;
        .switch {
          .switchBtn {
            margin: 0 60px 60px 0;
            &.active {
              color: $primaryColor;
              border-bottom: 5px solid $primaryColor;
            }
          }
        }
        .back {
          display: flex;
          width: 100%;
          margin-bottom: 20px;
          .backBtn {
            width: 50px;
          }
        }
        .titleHasLeftLine {
          color: $primaryColor;
          padding: 0 20px 0 20px;
          border-left: 5px solid $primaryColor;
        }
      }
    }
    .category {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    //   min-height: 80vh;
      padding: 20px 0 100px;
      font-size: 18px;
      .category-box {
        cursor: pointer;
        background: #fff;
        box-shadow: 0px 0px 9px #e9e9e9;;
        color: #333;
        border-radius: 10px;
        padding: 10px;
        margin: 20px 8px;
        width: 100%;
        // flex: 0 1 25%;
        height: fit-content;
        .category-row {
          display: inline-flex;
          width: 100%;
          justify-content: space-between;
          .triangle{ 
            &::before {
            position: relative;
            top: -14px;
            content: "";
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            margin-right: 9px;
            border-bottom: 10px solid $primaryColor;
            }
          }  
          .title {
            font-weight: 800;
          }
          .status {
            font-size: 14px;  
            white-space: nowrap;  
            background: $primaryColor;
            padding: 4px 10px;
            border-radius: 17px;
            color: #fff;
            // line-height: 12px;
          }
          .un-status {
            background: #fff;
            color: #222;
            border: 1px solid #333;
          }
        }
        .category-center {
          padding: 15px;
          text-align: center;
          font-size: 15px;
        }
        .number {
          padding: 16px 0;
          background: #f6f6f6;
          color: #888;
          .l {
            width: 10%;
            margin-left: 15px;
            font-size: 40px;
            font-weight: 800;
            color: $primaryColor;
            display: flex;
            align-items: center;
          }
          .r {
            width: 90%;
            text-align: left;
            padding: 10px 10px;
          }
        }
      }
    }
  }
}
.forloop-box {
  display: flex;
  justify-content: space-around;
  margin: 80px auto;
  .forloop-items {
    display: flex;
    flex-direction: column;
    width: 20vw;
    max-width: 300px;
    .text {
      background-color: #00d8b4;
      padding: 15px;
    }
  }
}
.financial {
  .wraper-box {
    .title-box {
      height: 78vh;
      background-image: url(../assets/img/financial-banner.png);
    }
  }
  .wraper-box-2 {
    background: #1d1d37;
    color: #fff;
    .title-box {
      background-image: url(../assets/img/banner_3.png);
      background-position-x: right;
      margin-bottom: 5vh;
    }
    .content-box .content-box-text {
      color: #fff;
      .titleHasLeftLine {
        color: #fff;
      }
    }
  }
  .userInfo {
    display: flex;
    flex-wrap: wrap;
    background-color: $primaryColor;
    margin: 15px 0;
    color: #fff;
    border-radius: 6px;
    font-size: 14px;
    .userInfo-row {
      flex: 0 0 100%;
      display: inline-flex;
    //   flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px 0;
      .userInfo-item {
        min-width: 150px;
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        p {
          width: 100%;
          margin: 3px 0;
          font-size: 14px;
        }
      }
      .btns {
         justify-content: flex-start;
         align-items: center;
         max-width: 400px;
        .el-button {
            width: 100%;
            margin: 0;
        }
      }
    }
  }
}
.buy-area {
  padding-top: 20px;
  font-size: 18px;
  .buy-product {
    margin: 0 20px;
    border: 1px solid #888;
    border-radius: 20px;
    .buy-row {
      border-radius: 0 0 20px 20px;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      &.green {
        background: $primaryColor;
      }
    }
  }
  .user-info {
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
    .item {
      text-align: center;
    }
  }
  .user-control {
    background: #f6f6f6;
    display: flex;
    flex-wrap: wrap;
    .rowText,
    .count {
      padding: 10px 20px;
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
    }
    .count {
      background: #fff;
      .num {
        font-size: 25px;
        font-weight: 800;
        color: #999;
      }
    }
    .sentBtn-area {
      padding: 10px 20px;
      width: 100%;
      display: inline-flex;
      justify-content: center;
      .sentBtn {
        max-width: 400px;
        text-align: center;
        color: #fff;
        border-radius: 10px;
        padding: 10px 0;
        background: $primaryColor;
        width: 100%;
        margin: 20px 0;
      }
    }
  }
}
@media (max-width: 1200px) {
  .financial {
    margin: 0 0 10px;
    .wraper-box {
      .title-box {
        background-position-x: 77%;
        height: 50vh;
        .title-box-text {
          text-align: left;
          padding: 0 8px;
          h1 {
            font-size: 18px;
            color: rgb(215, 215, 215);
          }
          .title {
            font-size: 40px;
            margin-bottom: 0;
            color: #fff;
          }
        }
      }
      .content-box .content-box-text {
        width: 90%;
        padding: 0 5px;
        .switch {
          width: 100%;
          justify-content: space-between;
          flex-wrap: wrap;
          .switchBtn {
            margin: 10px 7px;
            h1 {
              font-size: 20px;
              font-weight: 400;
            }
          }
        }
      }
      .category {
        flex-wrap: wrap;
        min-height: 65vh;
        .category-box {
          width: 100%;
        }
      }
    }
    .userInfo {
      padding: 10px;
      .userInfo-row {
        justify-content: left;
        padding: 2px 0;
        .userInfo-item {
          min-width: 150px;
          display: flex;
          padding: 0 10px;
          width: 100%;
          .btn {
            background-color: #77edd3;
            box-shadow: 2px 3px 3px #727272;
            border-radius: 10px;
            padding: 10px;
            margin: 0 25px 0 0;
            white-space: nowrap;
            color: #222;
            cursor: pointer;
          }
        }
         .btns {
         justify-content: flex-start;
         align-items: center;
         max-width: 180px;
         }
      }
    }
  }
}
</style>:{},