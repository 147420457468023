export const ACCESS_TOKEN = 'ACCESS_TOKEN'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const LANG = 'LANG'
export const UUID = 'UUID'
export const PLATFORM = 'PLATFORM'
export const AGENT_ID = 'agentIdentify'
export const API_ADDR = 'API_ADDR'
export const DEFAULT_COIN = 'DEFAULT_COIN'
export const COLORS = 'COLORS'
export const CODE = 'CODE'
export const DEFAULT_TAB = 'DEFAULT_TAB'
export const COUNT_TIME = 'COUNT_TIME'
export const RECOM_LIST = 'RECOM_LIST'
export const TOP_LIST = 'TOP_LIST'
export const USER_ID = 'USER_ID'
export const USE_REMOTE_LANG = 'USE_REMOTE_LANG'
export const IMAGE_DOMAIN = 'IMAGE_DOMAIN'
export const DMS = 'DMS'
export const FUTURE_LIST = 'FUTURE_LIST'
export const SPOT_LIST = 'SPOT_LIST'
export const TABBAR_LIST = 'TABBAR_LIST'
export const THEME = 'THEME'
export const RATE_LIST = 'RATE_LIST'
export const COLOR_TYPE = 'COLOR_TYPE'
export const COINS = 'COINS'