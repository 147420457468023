<template>
  <section class="tp-banner-container">
      <div class="tp-banner"  :class="{'timex': isTimeX}">
        <div class="v-container">
          <div class="container-left">
            <div class="platform-title">
              <h1>{{ $t(!isHft?"homeTitle_1_1": "hftTitle") }}</h1>
            </div>
            <div class="platform-text">
              <h3 class="text-grey">{{ $t(!isHft?"homeTitle_1_2": "hftTitle2") }}</h3>
            </div>
          </div>
          <div class="container-right">
            <img src="~@/assets/img/home7/bannericon.png" />
          </div>
          <div class="but-box">
              <div class="but but-bg" @click="goToH5">H5 {{$t('1128')}}</div>
              <div class="but" @click="goToDownload">IOS {{$t('1127')}}</div>
              <div class="but" @click="goToDownload">Android {{$t('1127')}}</div>
            </div>
        </div>
      </div>
  </section>
  
</template>

<script>
import utils from "@/utils/misc"

export default {
  props: ['modle', 'isTimeX'],
  computed: {
      isHft() {
        return utils.getLs('agentIdentify') === 'd42fc5f8-92c9-472f-91a0-bf5fe7c38b61'
      },
    },
  methods: {
      goToDownload() {
          window.location.href = window.configObj.downloadUrl;
      },
      goToH5() {
          window.location.href = location.origin + "/h5/";
      }
  }
}   
</script>

<style lang="scss" scoped>
.tp-banner-container {
  width: 1720px;
  margin: 0 auto;
  background: url("~@/assets/img/home7/background.png") no-repeat ;
  background-size: 100% 100%;
  height: 600px;
  padding: 80px 0;
  margin-top: 100px;
  .tp-banner{
    .v-container{
      display: flex;
      position: relative;
      height: 420px;
      .container-left{
        // position: relative;
        flex: 2;
        .platform-title{
          h1{
            font-family: 'Arial';
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 50px;
            text-transform: uppercase;
            color: #fff;
            position: relative;
            z-index: 10;
          }
        }
        .platform-text{
          margin-top: 60px;
          h3{
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            color: #fff;
            position: relative;
            z-index: 10;
          }
        }
      }
      .container-right{
        flex: 1;
        position: relative;
        img{
          height: 640px;
          position: absolute;
          left: -100px;
          top:-100px
        }
      }
      .but-box{
          display: flex;
          position: absolute;
          left: 0;
          bottom: 0;
          .but{
            width: 200px;
            height: 50px;
            font-size: 18px;
            font-weight: bold;
            border:2px solid #D09AD1;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
            cursor: pointer;
            color: #fff;
            &:hover{
              background: #D09AD1;
            }
          }
          .but-bg{
            background: #D09AD1;
          }
        }
    }
  }
}
@media screen and (max-width: 1000px) {
  .tp-banner-container {
    width: 100% !important;
    margin-top: 20px !important;
    background: url("~@/assets/img/home7/background.png") no-repeat  center;
    height: 750px !important;
    .v-container{
      display: block !important;
      .container-left{
      }
      .container-right{
        display: none;
      }
    }
    .v-container{
      height: 670px !important;
    }
    .but-box{
          display: block !important;
          left: 10px !important;
          bottom: 20px !important;
          .but{
            width: 100%;
            margin-top: 15px;
          }
        }
  }
}
</style>
