<template>
  <div class="header-container">
    <div class="header-top">
      <div class="v-container">
        <div class="flex align-center">
          <div class="service flex flex1">
            <a :href="serviceUrl" class="item flex align-center" target="_blank">
              <img src="@/assets/img/whats.png" alt="">
              <span>{{$t('service')}}</span>
            </a>
            <a v-if="showWhatsapp" :href="whatsAppUrl.link" class="item flex align-center" target="_blank">
              <img src="@/assets/img/service.png" alt="">
              <span>WhatsApp</span>
            </a>
            <a v-if="showLine" :href="lineAppUrl.link" class="item flex align-center" target="_blank">
              <img src="@/assets/img/service-line.png" alt="">
              <span>LINE</span>
            </a>
            <a v-if="showTg" :href="tgUrl" class="item flex align-center" target="_blank">
              <img src="@/assets/img/tg.png" alt="">
              <span>Telegram</span>
            </a>
            <a v-if="!!emailUrl&&emailUrl!=='0'" :href="`mailto:${emailUrl}`" class="item flex align-center" target="_blank">
              <img src="@/assets/img/email.png" alt="">
              <span>Email</span>
            </a>
          </div>
          <div class="lang flex align-center">
            <template v-if="userLogin">
              <div class="login-prop curso mr20" style="font-size: 16px;" @click="$router.push({name: 'Me'})"><span class="el-icon-user" style="vertical-align:middle;"></span></div>
              <span class="login-prop cursor fs12 mr20" @click="$router.push({name: 'Assets'})">{{ $t(305) }}</span>
            </template>
            <template v-else>
              <span class="login-prop cursor mr20" @click="$router.push({name: 'Login'})">{{ $t(94) }}</span>
              <span class="login-prop cursor mr20" @click="$router.push({name: 'Register'})">{{ $t(95) }}</span>
            </template>
            <el-dropdown trigger="click" class="lang-switcher" @command="handleCommand">
                <span class="lang-link flex">
                  {{ curLangName }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="item in langOptions" :key="item.lang" :command="item.lang" class="flex align-center">
                    <img
                      :src="item.flag"
                      alt=""
                      style="width: 24px;height:24px; margin-right: 6px;"
                    >
                    {{ item.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="v-container vc">
      <header class="header">
        <nav v-if="modle === '1'" class="navbar flex align-center navbar-default" :class="{ scrolled: homeHeader }">
          <div class="container p0 flex1" :class="{ 'collapse-pc-scroll': homeHeader }">
            <div class="modle-1">
                <router-link to="/" exact class="navbar-brand">
                <h1 class="logo">
                    <img :src="logoUrl" :alt="appName">
                </h1>
                </router-link>
                <div @click="flag = !flag" class="menu-wrapper visible-xs">
                <div class="hamburger-menu" :class="{ animate: flag }" />
                </div>
              </div>
            <!-- mobile -->
            <div class="collapse navbar-collapse" v-show="flag">
              <ul class="nav" :class="{ 'navbar-nav': homeHeader }">
                <router-link @click.native="flag = !flag" to="/" exact ><li>{{ $t("nav0") }}</li></router-link>
                <router-link @click.native="flag = !flag" to="/spot"><li>{{ $t("nav2") }}</li></router-link>
                <router-link v-if="cofigObj.moduleIsOpenFuture==='1'" @click.native="flag = !flag" to="/futures" ><li>{{ $t("nav3") }}</li></router-link>
                <router-link v-if="cofigObj.moduleIsOpenC2c==='1'" @click.native="flag = !flag" to="/c2c-sale"><li>{{ $t("304") }}</li></router-link>
                <router-link v-if="cofigObj.moduleIsOpenPledge==='1'" @click.native="flag = !flag" to="/financial"><li>{{ $t("nav4") }}</li></router-link>
                <a v-if="isBitpie" style="text-align: center;width: 100%; display: block;    color: #fff;" href="https://drive.google.com/file/d/1dFOzOSCDSuZh6UxYGOLcXMJv_yisVjLu/view?usp=sharing" target="_blank">{{ $t(1102) }}</a>
              </ul>
            </div>
        
          </div>
          <!-- pc -->
            <div class="collapse collapse-pc" :class="{ 'collapse-pc-scroll': homeHeader }">
              <div class="nav items">
                <router-link to="/" exact><div><p>{{ $t("nav0") }}</p></div></router-link>
                <router-link to="/spot"><div><p>{{ $t("nav2") }}</p></div></router-link>
                <router-link v-if="cofigObj.moduleIsOpenFuture==='1'" to="/futures"><div><p>{{ $t("nav3") }}</p></div></router-link>
                <router-link v-if="cofigObj.moduleIsOpenC2c==='1'" to="/c2c-sale"><div><p>{{ $t("304") }}</p></div></router-link>
                <router-link v-if="cofigObj.moduleIsOpenPledge==='1'" to="/financial"><div><p>{{ $t("nav4") }}</p></div></router-link>
                <a v-if="isBitpie" style="padding: 0 10px;" href="https://drive.google.com/file/d/1dFOzOSCDSuZh6UxYGOLcXMJv_yisVjLu/view?usp=sharing" target="_blank">{{ $t(1102) }}</a>
              </div>
            </div>
        </nav>
      </header>
    </div>
  </div>
</template>

<script>
import userControl from "@/components/UserControl.vue";
import utils from "@/utils/misc"
import {LANG_OPTIONS} from'@/constants/const'
import i18n from "@/lang";

export default {
  name: "Header",
  props: {
    msg: String,
  },
  data() {
    return {
      flag: false,
      homeHeader: false,
      navloto: false,
      product: false,
      langOptions: LANG_OPTIONS,
      curLang: localStorage.official_locale||window.configObj.defaultLang||'en-US',

    };
  },
  // components: { userControl },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll);
  },
  computed:{
    userInfo() {
      return this.$store.state.userInfo
    },
    userLogin() {
      return !!utils.getLs('token')
    },
    curLangName() {
      let result = ''
      for (const item of this.langOptions) {
        if (item.lang === this.curLang) {
          result = item.name
        }
      }
      return result
    },
    showWhatsapp() {
      return window.configObj.isShowWhatsapp==='1'
    },
    showLine() {
      return window.configObj.isShowLine==='1'
    },
    showTg() {
      return window.configObj.telegram && window.configObj.telegram.trim() !== '0'
    },
    whatsAppUrl() {
      return window.configObj.whatsapp.split('\n').map(item => ({
        name: item,
        link: `https://wa.me/${item}`
      }))[0]
    },
    lineAppUrl() {
      return window.configObj.line.split('\n').map(item => ({
        name: item,
        link: item
      }))[0]
    },
    serviceUrl() {
      let arr = []
      try{
        arr = JSON.parse(window.configObj.customerUrl)
      } catch (e) {
        console.log(e)
      }
      let _arr =  arr.map(item => {
        if (this.userInfo.uuid) {
          let params = item.params || ''
          params = params.replace(/\{USER_ID\}/g, this.userInfo.uuid)
          params = params.replace(/\{ACCOUNT\}/g, this.userInfo.mobile||this.userInfo.email)
          params = params.replace(/\{NICKNAME\}/g, this.userInfo.nickname)
          item.url += `&${params}`
        }
        return item
      })
      return _arr.length ? _arr[0].url : ''
    },
    tgUrl() {
      const arr =  window.configObj.telegram.split('\n').map(item => ({
        name: item,
        link: item
      }))
      return arr.length ? arr[0].link : ''
    },
    emailUrl() {
      return window.configObj.customerEmail
    },
    isTimeX() {
      return utils.getLs('agentIdentify') === 'e4ee504c-670f-4c82-a610-5b8aa2351ad7'
    },
    isBitpie() {
      return utils.getLs('agentIdentify') === '747f7c0f-9549-4538-8c1d-78b06eb15f6d'
    },
    logoUrl() {
      return window.configObj.logoUri
    },
    appName() {
      return window.configObj.appName
    },
    modle(){
        return window.configObj.officialModel
    },
    cofigObj() {
      return window.configObj
    }
  },
  methods: {
    handleCommand(lang) {
      i18n.locale = lang
      this.curLang = lang
      utils.setLs("locale",lang)
      this.$store.dispatch('getAccountNames')
    },
    showHideNav() {
      this.product = true;
    },
    closeHideNav() {
      this.product = false;
    },

    handleScroll() {
      if (
        !!document.documentElement.scrollTop &&
        document.documentElement.scrollTop > 50
      ) {
        this.homeHeader = true;
      } else {
        this.homeHeader = false;
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/menuBtn.scss"; 
.service{
  a{
    color: #fff;
  }
}
.header-container{
  background: #fff;
}
.header-top{
  padding: 15px 0;
  background: #000050;
  color: #fff;
  .item{
    margin-right: 20px;
    font-size: 12px;
    img{
      margin-right: 6px;
      height: 20px;
    }
  }
}
.header {
  &.timex{
    .collapse-pc {
        // background: #4c007d;
        background: #fff !important;
    }
    .container {
      background: #fff !important;
      // background: #4c007d;
    }
    .navbar /deep/ .login{
      color: #333;
    }
    .navbar /deep/ .nav a{
      color: #333;
    }
  }
  .navbar {
    .modle-1 {
        display: flex;
        justify-content: space-between;
        .logo img{
          height: 45px; 
        }
    }  
    .navbar-brand {
      color: #fff;
      display: inline-flex;
      align-items: center;
      margin-right: 20px;
      h1 {
        width: 130px;
        height: 100%;
        margin: auto;
        // line-height: 50px;
        display: contents;
        font-size: 40px;
      }
    }
    .collapse-pc {
        background: transparent;
        width: 100%;
        display: inline-flex;
        justify-content: right;
    }
    .collapse-pc-scroll{
       transition: all 0.4s ease-in-out; 
       background: #4c007d; 
    }
  }
  .navbar2 {
     .collapse-pc-scroll{
       background: #222; 
    } 
  }
  .nav {
    height: 100%;
    display: inline-flex;
    font-weight: 500;
    font-size: 16px;
    a {
      color: #000050;
      background: none;
      display: flex;
      align-items: center;
      padding: 40px 0;
      margin-left: 35px;
      &:hover {
        transition: all 0.25s ease-in-out;
        color: #0088ff;
      }
      div {
        text-align: center;
        margin: auto;
        position: relative;
        word-break: break-word;
      }
      .drop-icon {
        color: #999;
        position: relative;
        right: 28px;
        top: -8px;
        transform: translate(50%, 50%);
      }
    }
  }
  .navbar2 {
    a div {
     width: 100px; 
    }
    .items {
     .router-link-exact-active::before, .router-link-active::before {
         content: unset;
     }
    }
    .logo img{
       height: 45px; 
    }
  }
  .items {
     .router-link-exact-active ,
    .router-link-active {
        color: #0088ff;
        font-size: 16px;
        font-weight: 800;
    }
    .router-link-exact-active.no-act {
      background: none;
    }
  }
  .hide-nav-box {
    width: 123px;
    .hide-nav {
      background: #0f0f0f96;
      position: absolute;
      width: 123px;
      a {
        &:hover {
          background: #111;
          color: #ffb738;
        }
        .router-link-exact-active {
          background: #222;
          color: #ffb738;
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
.scroll {
  height: auto;  
  overflow-y: scroll;
  overflow-x: hidden; 
}
.navbar-default.scrolled .navbar-header {
  button {
    display: none;
  }
  a {
    display: inline-block;
    padding: 15px 20px;
    img {
      width: auto;
      max-height: 40px;
    }
  }
}
.container {
  display: flex;
  padding: 0;
  justify-content: space-between;
  background: transparent;
  width: 100%;
  padding: 0;
}
.navbar.navbar-default {
//   background: #0f0f0f;
  border: none;
  padding: 0;
  // padding-bottom: 20px;
  transition: all 0.25s ease-in-out;
  .logo {
    color: #00b5ac;
    max-width: 180px;
  }
}
.header .navbar-default.scrolled {
  // background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  padding: 0;
  .nav a {
    // color: #333;
    div {
      &:hover {
        &:after {
          background: #333;
        }
      }
    }
  }
}
.lang-link{
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  i{
    transform: translateY(3px);
  }
}

@media screen and (max-width: 1200px) {
 
  .login-prop{
    display: none;
  }
  .vc{
    background: #000050;
  }
  .navbar.navbar-default,
  .navbar-default.scrolled {
    .logo {
      width: auto;
      max-height: 40px;
    }
    .container {
      padding: 5px 5px 5px 10px;  
      display: flex;
      flex-direction: column;
    }
    .collapse {
      display: none;
    }
    .navbar-collapse {
      display: block;
      border-top: 1px solid #e7e7e7;
      .nav {
        display: flex;
        flex-direction: column;
        .router-link-exact-active {
          li {
              color: $primaryColor ;
          }
        }
        .router-link-exact-active.no-act {
          background: none;
        }
        a {
           height: 50px; 
          margin-left: 0;
        div {
          font-size: 14px;
        //   line-height: 54px;
          &:hover {
            &:after {
              content: "";
              width: 32px;
              background: none;
              position: absolute;
              bottom: -5px;
              z-index: 99999;
              left: 10px;
              border-radius: 5em;
              color: #333;
              transition: all 0.25s ease-in-out;
            }
          }
        }
          li {
             text-align: center;
             color: #fff;
          }
        }
      }
    }
    .navbar-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        padding: 15px 20px;
      }
    }
  }
  .nav a li {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .navbar-header {
    height: 60px;
  }
  .header .navbar-default.scrolled .nav a li:hover,
  .header .navbar-default .navbar-collapse .nav li:hover {
    &:after {
      background: none;
    }
  }
  .navbar.navbar-default {
    .collapse {
      border: none;
      ul {
        // background: rgba(255, 255, 255, 0.8);
        margin-top: 10px;
      }
      .nav li {
        color: #222;
        margin: auto;
      }
    }
  }
  .adv {
    font-size: 11px;
    .logo {
      width: 143px;
      top: 6px !important;
    }
    span {
      margin: 0 !important;
      padding-right: 12px;
    }
  }
  .header .navbar-default.scrolled {
    // background: #fff;
  }
  .navbar-collapse {
    text-align: center;
  }
  .header .navbar-default.scrolled .navloto {
    background-color: #fff;
  }
}
@media (max-width: 1200px) {
 .header {
  .container {
    padding: 0 15px;
    .hide-nav-box {
        width: 100%;
       .hide-nav{
            width: 100%;
            position: relative;
            background: #272626;
       }
       .drop-icon{
           right: 35%;
       }
    }
  }
  .modle-1{
      width: 100%;
  } 
 }
}
</style>
